import React, { useContext, useEffect, useState } from 'react';
import { getNotificationCount, getNotifications, updateNotificationById } from '../../../../services/Notifications';
import { toast } from 'react-toastify';
import { Context } from '../../../../App';
import { useNavigate } from 'react-router-dom';
import useGenerics from '../../../../hooks/useGenerics';
import { getExpenseByExpenseId } from '../../../../services/EmployeeExpense';
import LaravelEcho from '../../../../services/LaravelEcho';
import NotificationItem from './NotificationItem';
import CustomMessageData from '../../CustomMessageData';
import notificationSound from '../../../../resources/sounds/notification.mp3';
import logo from '../../../../resources/imgs/logo/logo_star_maminfo.png';

export default function NotificationsHeader() {
    const userData = useContext(Context);
    const [pendingNotificationCount, setPendingNotificationCount] = useState(0);
    const [readNotifications, setReadNotifications] = useState([]);
    const [unreadNotifications, setUnreadNotifications] = useState([]);
    const {detectDeviceTypeByUserAgent, convertWeekToDate, getWeek} = useGenerics();
    const [isTablet, setIsTablet] = useState(window.innerWidth >= 768);
    const navigate = useNavigate();

    async function getNotificationCountByUser() {
        let dataReturn = await getNotificationCount();

        if (dataReturn?.data?.response === 'success') {
            setPendingNotificationCount(dataReturn?.data?.data?.pending_count || 0);
        } 
    }

    async function getNotificationsByUser() {
        let dataReturn = await getNotifications();
        if (dataReturn?.data?.response === 'success') {
            let readNotification = dataReturn?.data?.data?.filter((item) => item.status === 1) || [];
            let unreadNotification = dataReturn?.data?.data?.filter((item) => item.status === 0) || [];

            setReadNotifications(readNotification);
            setUnreadNotifications(unreadNotification);

            for(let n of unreadNotification){
                if(n?.type === 'danger'){
                    toast.error(n?.content || '', {
                        autoClose: false, 
                        closeButton: false,
                        position: 'bottom-left',
                        onClick: ()=>{
                            notificationAction(n) 
                        }
                    })
                }
            }
        } 
    }

    function renderReadNotification() {
        if (readNotifications.length > 0) {
            return readNotifications.map((item, idx) => {
                return (<div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        notificationAction(item);
                    }}
                    key={`read_${idx}`}>
                    <NotificationItem content={item} key={`read_${idx}`}></NotificationItem>
                </div>);
            });
        } else {
            return <CustomMessageData message="Sem Notificações" typography="h6"></CustomMessageData>
        }
    }

    async function notificationAction(notificationData) {
        if(notificationData?.type === 'danger'){
            toast.dismiss();
        }

        if (notificationData?.module === 'ticket') {
            navigate(`/chamados/detalhes-chamado/${notificationData?.module_id}`);
        }

        if(notificationData?.module === 'expense'){
            if(notificationData?.event === 'reject_refund'){

                let dataReturn = await getExpenseByExpenseId(notificationData?.module_id);

                if(dataReturn?.data?.response === 'success'){
                    let expenseData = dataReturn?.data?.data;
                    let date = convertWeekToDate(getWeek(expenseData?.data_solicitacao));
                    navigate(`/faturamento/reembolso/detalhes/${expenseData?.employee?.id}?data_inicial=${date.startOfWeek}&data_final=${date.endOfWeek}`);
                }

            }
        }

        if(notificationData?.module === 'contract'){
            navigate(`/cadastros/contratos/detalhes/${notificationData.module_id}`);
        }

        if(notificationData?.module === 'partner'){
            navigate(`/cadastros/representantes/detalhes/${notificationData.module_id}`);
        }

        if(notificationData?.status === 0){
            updateNotificationStatus(notificationData?._id);
        }
    }

    function renderUnreadNotifications() {
        if (unreadNotifications.length > 0) {
            return unreadNotifications.map((item, idx) => {
                return (<div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        notificationAction(item);
                    }}
                    key={`unread_${idx}`}>
                    <NotificationItem content={item}></NotificationItem>
                </div>);
            });
        } else {
            return <CustomMessageData message="Sem Notificações" typography="h6"></CustomMessageData>
        }
    }

    async function updateNotificationStatus(id){
        let formData = {
            status: 1
        }
        let dataReturn = await updateNotificationById(id, formData);

        if(dataReturn?.data?.response === 'success'){
            getNotificationCountByUser();
            getNotificationsByUser();
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setIsTablet(window.innerWidth >= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (userData?.userDataState?.id_usuario) {
            getNotificationCountByUser();
            getNotificationsByUser();
            const channel = LaravelEcho.channel(`${process.env.REACT_APP_WEBSOCKET_PREFIX}user.${userData?.userDataState?.id_usuario}`);

            channel.listen('NewNotification', (e) => {
                getNotificationCountByUser();
                setUnreadNotifications(prevState => ([e.notification, ...prevState]))
                if(e?.notification?.type === 'danger'){
                    let audio = new Audio(notificationSound);
                    audio.play().catch((e)=>{
                        console.log(e);
                    });
                    toast.error(e.notification.content || '', {
                        autoClose: false, 
                        closeButton: false,
                        position: 'bottom-left',
                        onClick: ()=>{
                            notificationAction(e.notification) 
                        }
                    });
                    if (Notification.permission === "granted") {
                        let device = detectDeviceTypeByUserAgent();

                        if(device === 'mobile'){
                            navigator.serviceWorker.getRegistration().then(function(registration){
                                if(registration){
                                    registration.showNotification('MAMERP - ' + e.notification.content, {icon: logo});
                                }else{
                                    console.log('Service Worker não registrado');
                                }
                            }).catch((error)=>{
                                console.log(error);
                            })
                        }else{
                            new Notification('MAMERP - ' + e.notification.content, {icon: logo});
                        }
                    }
                }
            });

            return () => {
                LaravelEcho.leave(`${process.env.REACT_APP_WEBSOCKET_PREFIX}user.${userData?.userDataState?.id_usuario}`);
            }
        }
    }, [userData]);

    return (
        <li className="nav-item dropdown px-1">
            <button className={`btn btn-link nav-link ${pendingNotificationCount > 0 ? "text-danger" : ""}`} data-toggle="dropdown">
                <i className="fas fa-bell"></i>{pendingNotificationCount > 0 && pendingNotificationCount}
            </button>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ overflow: 'auto', maxHeight: '75vh', width: `${isTablet ? '25vw' : '90vw'}`, maxWidth: `${isTablet ? '25vw' : '90vw'}`, minWidth: `${isTablet ? '25vw' : '90vw'}` }}>
                <h3 className="dropdown-header">Notificações</h3>
                <div className="mt-2">
                    <h5 className="dropdown-header">Não Lidas</h5>
                    <ul className="list-group">
                        {
                            renderUnreadNotifications()
                        }
                    </ul>
                </div>
                <div className="m-2">
                    <h5 className="dropdown-header mt-2">Lidas</h5>
                    <ul className="list-group">
                        {
                            renderReadNotification()
                        }
                    </ul>
                </div>
            </div>
        </li>)
}