import React from 'react';
import If from '../../../common/Layout/If';
import RepresentanteSelect from '../../../common/Selects/RepresentanteSelect';
import TecnicosSelect from '../../../common/Selects/TecnicosSelect';
import Cep from '../../../common/Cep/Cep';
import { getTechnicianDisponibility } from '../../../services/Ticket';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Card from '../../../common/Layout/Card';
import ValoresRepresentanteSelect from '../../../common/Selects/ValoresRepresentantesSelect';

export default function DadosTecnicoCard({
    detailsData = {},
    setDetailsData = null,
    showAlterarTecnicoBtn = false,
}) {
    const mySwal = withReactContent(Swal);

    function handlePartner(value, name) {
        setDetailsData(prevState => ({
            ...prevState,
            [name]: value,
            id_tecnico: ''
        }))
    }

    async function handleTechnician(value, name) {
        if (value === '') {
            return;
        }

        if ([null, undefined, ''].includes(detailsData.hora_agendamento) || [null, undefined, ''].includes(detailsData.data_agendamento)) {
            toast.warning("Por favor, preencha os campos data e hora agendamento!");
            return;
        }

        let data = "";
        let hora = detailsData.hora_agendamento.split(":");

        if (detailsData.data_agendamento.includes("/")) {
            let [day, month, year] = detailsData.data_agendamento.split("/");
            data = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        } else {
            data = detailsData.data_agendamento;
        }

        let params = {
            id_tecnico: parseInt(value?.value),
            data_agendamento: data,
            hora_agendamento: hora[0] + ":" + hora[1]
        }

        let dataReturn = await getTechnicianDisponibility(params);

        if (dataReturn?.data?.response === 'Nenhum atendimento conflitante encontrado para o técnico.') {
            setDetailsData(prevState => ({
                ...prevState,
                id_tecnico: value,
                tem_conflito_chamado: false
            }));
        } else if (dataReturn?.data?.response === 'Técnico já possui atendimentos para esse dia e período.') {
            let tickets = dataReturn.data.data.tickets;

            let listComponent = tickets.map((item, idx) => {
                return (
                    <div key={idx}>
                        <p><b>Número do Chamado:</b> {item.num_chamado}</p>
                        <p><b>Data/Hora Agendamento:</b> {item.data_agendamento} {item.hora_agendamento}</p>
                        <hr></hr>
                    </div>)
            });

            toast.warning(dataReturn?.data?.response);

            let response = await mySwal.fire({
                titleText: `Atenção, este técnico possui chamados!`,

                html: (
                    <>
                        <p>Deseja alocar este técnico e alterar o status do chamado para Aprovação do Responsável?</p>
                        <h6 style={{ textTransform: 'capitalize' }}>Técnico: {value.label}</h6>
                        <div style={{ textAlign: 'left' }}>{listComponent}</div>
                    </>),
                showConfirmButton: true,
                showCancelButton: true,
                cancelButtonColor: 'red',
                confirmButtonText: `Sim`,
                cancelButtonText: `Não`,
            });

            if (response.isConfirmed) {
                setDetailsData(prevState => ({ ...prevState, [name]: value, tem_conflito_chamado: true }));
            }

        } else {
            toast.warning("Ocorreu um erro ao buscar a disponibilidade do técnico!");
        }
    }

    return (<Card title={'Novo Representante/Técnico'} icon={'fa-user'}>
        <div className='card-header'><h6><i className='fas fa-user'></i> Técnico</h6></div>
        <div className="row">
            <RepresentanteSelect
                formData={detailsData}
                setFormData={setDetailsData}
                advancedSetFormData={handlePartner}
                cols={[12, 4, 4, 4]}
                fieldName='id_representante'
                required={true}
                isDisabled={detailsData?.special_partner_value !== null}
            ></RepresentanteSelect>
            <TecnicosSelect
                formData={detailsData}
                setFormData={setDetailsData}
                cols={[12, 4, 4, 4]}
                fieldName='id_tecnico'
                id_representante={detailsData?.id_representante?.value}
                required={true}
                advancedSetFormData={handleTechnician}
            ></TecnicosSelect>
            {/* <If condition={detailsData?.special_partner_value !== null}>
                <div className="col-sm-12 col-md-4">
                    <label htmlFor="escopo_valores_especiais">Escopo Especial</label>
                    <input type="text" name="escopo_valores_especiais" id="escopo_valores_especiais" className="form-control" value={detailsData?.escopo_valores_especiais || ''} readOnly />
                </div>
            </If>
            <If condition={detailsData?.special_partner_value === null}>
                <ValoresRepresentanteSelect
                    formData={detailsData}
                    setFormData={setDetailsData}
                    cols={[12, 4, 4, 4]}
                    fieldName='id_representante_valor_comercial'
                    id_representante={detailsData?.id_representante?.value}
                    required={true}
                    title='Escopo'
                ></ValoresRepresentanteSelect>
            </If> */}
        </div>
        <div className='card-header'><h6><i className='fas fa-map-marker'></i> Ponto de Partida</h6></div>
        <Cep
            formData={detailsData}
            setFormData={setDetailsData}
            formDataNames={{
                'cep': 'cep_endereco_partida',
                'estado': 'estado_endereco_partida',
                'cidade': 'cidade_endereco_partida',
                'bairro': 'bairro_endereco_partida',
                'logradouro': 'logradouro_endereco_partida',
                'complemento': 'compl_ref_obs_endereco_partida'
            }}
            hasComplemento={true}
        ></Cep>
    </Card>);
}