import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../common/Layout/Card';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { deletePartnerUserById, getAllPartnerUsers } from '../../../services/PartnerGroup';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';

export default function ShowPartnerGroup(){

    const columns = [
        {
            id: 'action_grupos_parceria',
            name: 'Ação',
            body: (row) => (
                <div>
                    <button type="button" className='btn btn-warning m-1 d-inline' onClick={() => showDeleteGroupSwal(row.id_responsavel_grupo_parceria)}><i className='fas fa-trash'></i></button>
                    <Link to={`/chamados/grupos-parceria/detalhes/${row.id_responsavel_grupo_parceria}`} className='btn btn-primary d-inline'><i className='fas fa-eye'></i></Link>
                </div>
            ),
        },
        {
            id: 'responsavel_grupos_parceria',
            name: 'Responsável da Parceria',
        },
        {
            id: 'contrato_grupos_parceria',
            name: 'Contrato de Parceria',
            body: (row) => {
                const links = row.contrato_grupos_parceria?.map((item, idx) => (
                    <Link to={'/cadastros/contratos/detalhes/' + item.id} target='_blank' key={idx}>
                        {item.contrato}
                    </Link>
                ));
                const content = links.length > 0 ? links.reduce((prev, curr) => [prev, ', ', curr]) : null;

                return (
                    <div>
                        {content}
                    </div>
                );
            },
        },
        {
            id: 'descricao_grupos_parceria',
            name: 'Descrição',
        },
    ];

    const [usersData, setUsersData] = useState([]);

    async function getUsersData(){
        const toastUsers = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getAllPartnerUsers();

        if(dataReturn?.data?.response === 'success'){
            toast.update(toastUsers, { render: 'Dados carregados!', type: "success", isLoading: false, autoClose: 1500 });
            let usuarios = dataReturn?.data?.data.map((item, idx)=>{
                let contratos = item?.contracts?.map((item, idx)=>{
                    return ({id: item?.id, contrato: item?.contrato});
                }) || [];

                return ({
                    id_responsavel_grupo_parceria: item?.id || '',
                    responsavel_grupos_parceria: item?.nome_grupo || '',
                    contrato_grupos_parceria: contratos,
                    descricao_grupos_parceria: item?.descricao || ''
                });
            });

            setUsersData(usuarios);
        }else{
            toast.update(toastUsers, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setUsersData([]);
        }
    }

    async function showDeleteGroupSwal(id){
        let response = await Swal.fire({
            titleText: 'Deseja realmente excluir este grupo de parceria?',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não'
        })

        if(response.isConfirmed){
            deleteGroup(id);
        }
    }

    async function deleteGroup(id){
        const toastUsers = toast.loading("Excluindo grupo de parceria, aguarde...");

        let dataReturn = await deletePartnerUserById(id);

        if(dataReturn?.data?.response === 'success'){
            toast.update(toastUsers, { render: 'Grupo de parceria excluido com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getUsersData();
        }else{
            toast.update(toastUsers, { render: 'Ocorreu um erro ao excluir o grupo de parceria!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(()=>{
        getUsersData();
    },[]);

    return (<Content headerTitle='Grupos de Parcerias'>
        <SubHeaderSpecific subHeaderConfig='grupos_parcerias'></SubHeaderSpecific>
        <Card hideCardHeader={true}>
            <PrimeTable
                columns={columns}
                rows={usersData}
                id='grupos_parceria_table'
            ></PrimeTable>
        </Card>
    </Content>)
}