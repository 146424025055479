import React, {useEffect, useState } from 'react';
import Modal from '../../../../common/Layout/Modal';
import { toast } from 'react-toastify';
import { createManufacturer } from '../../../../services/Manufacturer';

export default function CreateManufacturer({ isOpen = false, setIsOpen = null }) {
    const INITIAL_STATE = {
        nome_fabricante: ''
    };

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function submitForm(e) {
        e.preventDefault();

        const toastProducts = toast.loading('Cadastrando fabricante, aguarde...');

        let dataReturn = await createManufacturer(formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Fabricante cadastrado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setFormData(INITIAL_STATE);
        }else if(dataReturn?.data?.message === 'The nome fabricante has already been taken.'){
            toast.update(toastProducts, { render: 'Este fabricante já está cadastrado!', type: "warning", isLoading: false, autoClose: 1500 });
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao cadastrar o fabricante!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(() => {
        if (isOpen) {
            setFormData(INITIAL_STATE);
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={setIsOpen}
            title='Cadastrar Fabricante'
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-12">
                        <label htmlFor="nome_fabricante">Fabricante</label>
                        <input type="text" name="nome_fabricante" id="nome_fabricante" placeholder='Fabricante' className="form-control" value={formData?.nome_fabricante || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1">
                        <button type="submit" className="btn btn-primary">Cadastrar</button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}