import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { setProductStatusByProductId } from '../../../../services/Product';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';

import CreateProduct from './CreateProduct';

export default function ProductsTable({ createTicketForm = {}, setCreateTicketForm = null, productData = [], setProductData = null }) {

    const [isOpen, setIsOpen] = useState(false);

    const columns = [
        {
            id: 'action',
            name: 'Ação',
            body: (row) => (
                <button type='button' className='btn btn-link' onClick={() => removeProduct(row)}><i className='fas fa-trash'></i></button>
            )
        },
        {
            id: 'id_temp_table',
            name: 'ID'
        },
        {
            id: 'fabricante',
            name: 'Fabricante'
        },
        {
            id: 'modelo',
            name: 'Nome do Produto'
        }
    ];

    async function removeProduct(row) {
        let data = productData.filter((item, idx) => item.id_temp_table !== row.id_temp_table);
        setProductData(data);
        toast.success("Produto removido!");
    }

    async function addProduct(row) {
        let id = 0;

        if (productData.length > 0) {
            id = productData[productData.length - 1]['id_temp_table'] + 1;
        }

        row['id_temp_table'] = id;
        setProductData([...productData, { ...row, fabricante: row?.id_fabricante?.label, modelo: row?.id_modelo?.label }]);
    }

    return (<>
        <CreateProduct isOpen={isOpen} setIsOpen={() => setIsOpen(!isOpen)} updateParent={addProduct}></CreateProduct>
        <div className='card-header'><h6><i className='fas fa-dot-circle'></i> Produtos Envolvidos</h6></div>
        <div className="row">
            <div className="col-sm-12 col-md-6">
                <div className='form-group'>
                    <label htmlFor="numero_rma">Número RMA</label>
                    <input type="text" className="form-control" name="numero_rma" id="numero_rma" value={createTicketForm?.numero_rma} onChange={(e) => setCreateTicketForm(prev => ({ ...prev, [e.target.name]: e.target.value }))} placeholder='...' required={true} />
                </div>
            </div>
            <div className="col-sm-12 col-md-6 mt-3 d-flex align-items-center">
                <button type="button" className="btn btn-primary" onClick={() => setIsOpen(!isOpen)}>Cadastrar Produtos</button>
            </div>
            <div className="col-md-12">
                <PrimeTable
                    columns={columns}
                    rows={productData}
                    needExport={false}
                    id='product_data_table'
                ></PrimeTable>
            </div>
        </div>
    </>);
}