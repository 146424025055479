//Aqui vão os itens da sidenav
export const MenuItemsConfigs = [
    {
        name: 'MAM Talks',
        icon: 'fas fa-comments',
        path: '/chat',
        permission_group: [],
        children: []
    },
    {
        name: 'Dashboard',
        icon: 'fas fa-tachometer-alt',
        path: '#', //quando tem caminho
        permission_group: [],
        children: [
            {
                name: 'Técnicos',
                path: '/dashboard/tecnicos/absenteismo/dados', //quando tem caminho
                permission_group: []
            },
        ]
    },
    {
        name: 'MAM Chamados',
        icon: 'fas fa-book',
        path: '#', //quando nao tem caminho
        permission_group: ["acionamento_gestao", "acionamento_supervisao", "acionamento_operacao", "logistica_operacao", "logistica_gestao"],
        children: [
            {
                name: 'Chamados',
                path: '/chamados/dados/all', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao", "acionamento_operacao", "logistica_operacao", "logistica_gestao"]
            },
            {
                name: 'Suporte N1',
                path: '/chamados/dados/suporte-n1?status_chamado=Aberto&status_chamado=Técnico%20chegou%20ao%20cliente&status_chamado=Agendado&status_chamado=Técnico%20Ciente&status_chamado=Técnico%20em%20deslocamento&status_chamado=Técnico%20aguardando%20liberação%20de%20acesso&status_chamado=Técnico%20em%20atendimento&status_chamado=Suporte%20interno%20(Fila)&status_chamado=Suporte%20interno%20(Atendimento)&status_chamado=Suporte%20externo%20/%20cliente&status_chamado=Suporte%20projetos', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Suporte N2',
                path: '/chamados/suporte-n2',
                permission_group: ["acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Parcerias',
                path: '/chamados/dados/parcerias?status_chamado=Aguardando%Técnico&data_inicial=2023-01-01', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Relatórios',
                path: '/relatorios', //quando tem caminho
                permission_group: []
            },
            {
                name: 'Abertura de Chamados',
                path: '/chamados/abertura-chamados', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao", "acionamento_operacao", "logistica_operacao", "logistica_gestao"]
            },
            {
                name: 'Abrir Chamado XLS',
                path: '/chamados/abertura-chamado-xls', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Grupos de Atendimento',
                path: '/chamados/grupos-atendimento/dados', //quando tem caminho
                permission_group: []
            },
            {
                name: 'Grupos de Parcerias',
                path: '/chamados/grupos-parceria/dados', //quando tem caminho
                permission_group: []
            },
            {
                name: 'Resp. Atendimento',
                path: '/chamados/responsavel-atendimento', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao"]
            },
            {
                name: 'Resp. Parceira',
                path: '/chamados/responsavel-parceria', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao"]
            },
            {
                name: 'Resp. Suporte',
                path: '/chamados/responsavel-suporte', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao"]
            },
            {
                name: 'Monitoramento de Técnicos',
                path: '/chamados/monitoramento-tecnicos', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Capilaridade de Técnicos',
                path: '/chamados/capilaridade-tecnicos', //quando tem caminho
                permission_group: []
            },
        ]
    },
    {
        name: 'MAM Logística',
        icon: 'fas fa-box',
        path: '#',
        permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao"],
        children: [
            {
                name: 'Produtos',
                path: '/logistica/produtos/dados', //quando tem caminho
                permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Chamados Pendentes',
                path: '/logistica/produtos/chamados-pendentes',
                permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Peças a Enviar',
                path: '/logistica/produtos/pecas-enviar', //quando tem caminho
                permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Emissão de NFE',
                path: '/logistica/produtos/emissao-nfe', //quando tem caminho
                permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Em Trânsito',
                path: '/logistica/produtos/em-transito', //quando tem caminho
                permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Pendente DACT',
                path: '/logistica/produtos/pendente-dact',
                permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Aguardando Retirada',
                path: '/logistica/produtos/aguardando-retirada',
                permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Produtos Recebidos',
                path: '/logistica/produtos/produtos-recebidos',
                permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            }
        ]
    },
    {
        name: 'MAM Cadastros',
        icon: 'fas fa-list',
        path: '#',
        permission_group: ["acionamento_gestao", "acionamento_supervisao"],
        children: [
            {
                name: 'Integradoras',
                path: '/cadastros/integradoras/dados', //quando tem caminho
                subheader: true,
                sidenav: true,
                permission_group: ["acionamento_gestao", "acionamento_supervisao"]
            },
            {
                name: 'Contratos',
                path: '/cadastros/contratos/dados', //quando tem caminho
                subheader: true,
                sidenav: true,
                permission_group: ["acionamento_gestao", "acionamento_supervisao"]
            },
            {
                name: 'Representantes',
                path: '/cadastros/representantes/dados', //quando tem caminho
                subheader: true,
                sidenav: true,
                permission_group: ["acionamento_gestao", "acionamento_supervisao"]
            },
            {
                name: 'Técnicos',
                path: '/cadastros/tecnicos/dados', //quando tem caminho
                subheader: true,
                sidenav: true,
                permission_group: ["acionamento_gestao", "acionamento_supervisao"]
            },
            // {
            //     name: 'Colaboradores',
            //     path: '/cadastros/colaboradoras/dados', //quando tem caminho
            //     subheader: true,
            //     sidenav: true,
            //     permission_group: []
            // },
        ]
    },
    {
        name: 'MAM Soluções',
        icon: 'fas fa-list',
        path: '#',
        permission_group: [],
        children: [
            {
                name: 'T EDC - Acionamentos',
                path: '/solucoes/acionamentos/dados', //quando tem caminho
                permission_group: []
            },
            {
                name: 'T EDC - Retirada',
                path: '/solucoes/retirada/dados', //quando tem caminho
                permission_group: []
            },
        ]
    },
    {
        name: 'MAM Faturamento',
        icon: 'fas fa-credit-card',
        path: '#',
        permission_group: ["faturamento"],
        children: [
            {
                name: 'Reembolso',
                path: '/faturamento/reembolso/dados',
                permission_group: ["faturamento"]
            },
        ]
    },
    {
        name: 'MAM Usuários',
        icon: 'fas fa-user',
        path: '#',
        permission_group: [],
        children: [
            {
                name: 'Gerenciar Usuários',
                path: '/usuarios/dados', //quando tem caminho
                permission_group: []
            },
            {
                name: 'Adicionar Usuários',
                path: '/usuarios/cadastrar', //quando tem caminho
                permission_group: []
            },
        ]
    },
];

//Aqui são os subheaders
export const SubHeaders = [
    {
        id: 'acionamento',
        subheaders: [{
            name: 'Gerenciar Acionamentos',
            path: '/solucoes/acionamentos/dados', //quando tem caminho
            permission_group: []
        },
        {
            name: 'Incluir Acionamentos',
            path: '/solucoes/acionamentos/cadastrar', //quando tem caminho
            permission_group: []
        }]
    },
    {
        id: 'retirada',
        subheaders: [{
            name: 'Gerenciar Acionamentos de Retirada',
            path: '/solucoes/retirada/dados', //quando tem caminho
            permission_group: []
        },
        {
            name: 'Incluir Acionamentos de Retirada',
            path: '/solucoes/retirada/cadastrar', //quando tem caminho
            permission_group: []
        },]
    },
    {
        id: 'grupos_atendimento',
        subheaders: [
            {
                name: 'Grupos de Atendimento',
                path: '/chamados/grupos-atendimento/dados', //quando tem caminho
                permission_group: []
            },
            {
                name: 'Criar Grupo de Atendimento',
                path: '/chamados/grupos-atendimento/cadastrar', //quando tem caminho
                permission_group: []
            },
        ]
    },
    {
        id: 'grupos_parcerias',
        subheaders: [
            {
                name: 'Grupos de Parceria',
                path: '/chamados/grupos-parceria/dados', //quando tem caminho
                permission_group: []
            },
            {
                name: 'Criar Grupo de Parceria',
                path: '/chamados/grupos-parceria/cadastrar', //quando tem caminho
                permission_group: []
            },
        ]
    },
    {
        id: 'dashboard_tecnicos',
        subheaders: [
            {
                name: 'Técnicos',
                path: '/dashboard/tecnicos/absenteismo/dados', //quando tem caminho
                permission_group: []
            },
        ]
    },
    {
        id: 'mam_chamados',
        subheaders: [
            {
                name: 'Chamados',
                path: '/chamados/dados/all', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao", "acionamento_operacao", "logistica_operacao", "logistica_gestao"]
            },
            {
                name: 'Suporte N1',
                path: '/chamados/dados/suporte-n1?status_chamado=Aberto&status_chamado=Técnico%20chegou%20ao%20cliente&status_chamado=Agendado&status_chamado=Técnico%20Ciente&status_chamado=Técnico%20em%20deslocamento&status_chamado=Técnico%20aguardando%20liberação%20de%20acesso&status_chamado=Técnico%20em%20atendimento&status_chamado=Suporte%20interno%20(Fila)&status_chamado=Suporte%20interno%20(Atendimento)&status_chamado=Suporte%20externo%20/%20cliente&status_chamado=Suporte%20projetos', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Suporte N2',
                path: '/chamados/suporte-n2?status_chamado=Suporte%20interno%20(Atendimento)&status_chamado=Suporte%20interno%20(Fila)&status_chamado=Suporte%20interno%20(Residentes%20OI)&status_chamado=Suporte%20interno%20(Testes)', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Parcerias',
                path: '/chamados/dados/parcerias?status_chamado=Aguardando%Técnico&data_inicial=2023-01-01', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Relatórios',
                path: '/relatorios', //quando tem caminho
                permission_group: []
            },
            {
                name: 'Abertura de Chamados',
                path: '/chamados/abertura-chamados', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao", "acionamento_operacao", "logistica_operacao", "logistica_gestao"]
            },
            {
                name: 'Abrir Chamado XLS',
                path: '/chamados/abertura-chamado-xls', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Resp. Atendimento',
                path: '/chamados/responsavel-atendimento', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao"]
            },
            {
                name: 'Resp. Parceira',
                path: '/chamados/responsavel-parceria', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao"]
            },
            {
                name: 'Resp. Suporte',
                path: '/chamados/responsavel-suporte', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao"]
            },
            {
                name: 'Monitoramento de Técnicos',
                path: '/chamados/monitoramento-tecnicos', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Capilaridade de Técnicos',
                path: '/chamados/capilaridade-tecnicos', //quando tem caminho
                permission_group: []
            },
        ]
    }, 
    {
        id: 'mam_cadastros',
        subheaders: [
            {
                name: 'Integradoras',
                path: '/cadastros/integradoras/dados', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao"]
            },
            {
                name: 'Contratos',
                path: '/cadastros/contratos/dados', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao"]
            },
            {
                name: 'Representantes',
                path: '/cadastros/representantes/dados', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao"]
            },
            {
                name: 'Técnicos',
                path: '/cadastros/tecnicos/dados', //quando tem caminho
                permission_group: ["acionamento_gestao", "acionamento_supervisao"]
            },
            // {
            //     name: 'Colaboradores',
            //     path: '/cadastros/colaboradoras/dados', //quando tem caminho
            //     permission_group: []
            // },
        ]
    },
    {
        id: 'mam_logistica',
        subheaders: [
            {
                name: 'Produtos',
                path: '/logistica/produtos/dados', //quando tem caminho
                permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Chamados Pendentes',
                path: '/logistica/produtos/chamados-pendentes',
                permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            },
            {
                name: 'Peças a Enviar',
                path: '/logistica/produtos/pecas-enviar', //quando tem caminho
                permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"],
                count_name: "aguardando_envio"
            },
            {
                name: 'Emissão de NFE',
                path: '/logistica/produtos/emissao-nfe', //quando tem caminho
                permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"],
                count_name: "aguardando_nfe_saida"
            },
            {
                name: 'Em Trânsito',
                path: '/logistica/produtos/em-transito', //quando tem caminho
                permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"],
                count_name: "em_transito"
            },
            {
                name: 'Pendente DACT',
                path: '/logistica/produtos/pendente-dact',
                permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"],
                count_name: "pendente_dact"
            },
            {
                name: 'Aguardando Retirada',
                path: '/logistica/produtos/aguardando-retirada',
                permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"],
                count_name: "aguardando_retirada_em_maos"
            },
            {
                name: 'Produtos Recebidos',
                path: '/logistica/produtos/produtos-recebidos',
                permission_group: ["logistica_operacao", "logistica_gestao", "acionamento_gestao", "acionamento_supervisao", "acionamento_operacao"]
            }
        ]
    },
    {
        id: 'mam_usuarios',
        subheaders: [
            {
                name: 'Gerenciar Usuários',
                path: '/usuarios/dados', //quando tem caminho
                permission_group: []
            },
            {
                name: 'Adicionar Usuário',
                path: '/usuarios/cadastrar', //quando tem caminho
                permission_group: []
            },
        ]
    },
    {
        id: 'mam_tecnicos',
        subheaders: [
            {
                name: 'Reembolso',
                path: '/faturamento/reembolso/dados',
                permission_group: []
            }
        ]
    }
]