import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import Card from '../../../common/Layout/Card';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { changeIntegratorStatus, getIntegratorsByStatus } from '../../../services/Integrators';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';

export default function ShowIntegrators(props) {

    const { status } = useParams();
    const [integratorsData, setIntegratorsData] = useState([]);
    const columns = [
        {
            id: 'action_integradora',
            name: 'Ver',
            body: (row) => (
                <Link to={'/cadastros/integradoras/detalhes/' + row.id_integradora}><i className='fas fa-eye'></i></Link>
            ),
        },
        {
            id: 'nome_integradora',
            name: 'Integradora',
        },
        {
            id: 'cnpj_integradora',
            name: 'CNPJ',
        },
        {
            id: 'inscricao_estadual_integradora',
            name: 'Insc. Estadual',
        },
        {
            id: 'endereco_integradora',
            name: 'Endereço Integradora',
        },
        {
            id: 'bairro_integradora',
            name: 'Bairro',
        },
        {
            id: 'cidade_integradora',
            name: 'Cidade Integradora',
        },
        {
            id: 'estado_integradora',
            name: 'Estado',
        },
        {
            id: 'status_integradora',
            name: 'Status',
        },
        {
            id: 'action_activate_deactivate_integradora',
            name: 'Ação',
            body: (row) => (
                <div className='align-items-center'>
                    <button type="button" className='btn btn-primary' onClick={() => { showChangeIntegratorStatusSwal(row.id_integradora, row.status_integradora) }}>{row.status_integradora === 'Ativo' ? 'Inativar' : 'Ativar'}</button>
                </div>
            ),
        },
    ];

    async function getIntegrators(status = 1) {
        const toastIntegratorData = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getIntegratorsByStatus(status);

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            toast.update(toastIntegratorData, { render: 'Dados Carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let data = dataReturn?.data?.data.map((item, idx) => {
                return ({
                    id_integradora: item?.id || '',
                    nome_integradora: item?.nome || '',
                    cnpj_integradora: item?.cnpj || '',
                    inscricao_estadual_integradora: item?.inscricao_estadual || '',
                    endereco_integradora: item?.endereco_principal || '',
                    bairro_integradora: item?.bairro_principal || '',
                    cidade_integradora: item?.cidade_principal || '',
                    estado_integradora: item?.estado_principal || '',
                    status_integradora: item?.ativo === 1 ? 'Ativo' : 'Inativo'
                })
            })
            setIntegratorsData(data);
        } else {
            toast.update(toastIntegratorData, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setIntegratorsData([]);
        }
    }

    async function showChangeIntegratorStatusSwal(id, status) {
        let response = await Swal.fire({
            titleText: 'Deseja realmente alterar o status desta integradora?',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não'
        })

        if (response.isConfirmed) {
            changeIntegratorByStatus(id, status);
        }
    }

    async function changeIntegratorByStatus(id, status) {

        const toastChangeStatus = toast.loading("Alterando status, aguarde...");

        let changeStatusData = status === 'Ativo' ? 0 : 1;

        let dataReturn = await changeIntegratorStatus(id, changeStatusData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastChangeStatus, { render: 'Status alterado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let statusData = status === 'Ativo' ? 1 : 0;
            getIntegrators(statusData);
        } else {
            toast.update(toastChangeStatus, { render: 'Ocorreu um erro ao alterar o status!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(() => {
        let statusData = 1;

        if (status === 'inativo') {
            statusData = 0
        }

        getIntegrators(statusData);
    }, [status])

    return (
        <Content headerTitle="Integradoras">
            <SubHeaderSpecific subHeaderConfig='mam_cadastros'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className='row'>
                    <div className='col-12'>
                        <Link to="/cadastros/integradoras/cadastrar" role='button' className='btn btn-primary m-1'>Cadastrar Integradora</Link>
                        <Link to="/cadastros/integradoras/dados" role='button' className='btn btn-success m-1'>Integradoras Ativas</Link>
                        <Link to="/cadastros/integradoras/dados/inativo" role='button' className='btn btn-warning m-1'>Integradoras Inativas</Link>
                    </div>
                </div>
                <PrimeTable
                    columns={columns}
                    rows={integratorsData}
                ></PrimeTable>
            </Card>
        </Content>
    )
}