import React, { useContext } from 'react';
import Card from '../../../common/Layout/Card';
import { Context } from '../../../App';
import usePermissions from '../../../hooks/usePermissions';
import If from '../../../common/Layout/If';

export default function AtendimentoCard({
    originalData = {},
    showUpdateBtn = false,
    detailsData = {},
    setShowAlterarKmBtn = null,
    setShowAlterarTecnicoBtn = null,
    setShowNovaDataAgendamentoBtn = null
}) {
    const { checkPermissionGroup } = usePermissions();
    const userData = useContext(Context);

    return (<Card title={`Atendimento`} icon={'fa-phone'}>
        <div className="row">
            <div className="col-12">
                <p><b>Representante: </b>{originalData?.partner?.nome_representante !== "" ? originalData?.partner?.nome_representante : originalData?.tecnico_proj_edc}</p>
                <p><b>Telefone Representante: </b>{originalData?.partner?.telefone_fixo_responsavel || ''} / {originalData?.partner?.telefone_comercial || ''} / {originalData?.partner?.celular_responsavel || ''}</p>
                <p><b style={{ color: `${(originalData?.km_tecnico < originalData?.contract?.km_maximo) && originalData?.status_chamado === "Aprovação do Responsável" ? "red" : "black"}` }}>Técnico: </b>{originalData?.technician?.nome_tec !== "" ? originalData?.technician?.nome_tec : originalData?.tecnico_proj_edc}</p>
                <p><b>Telefone do Técnico: </b>{originalData?.technician?.celular_tec} / {originalData?.technician?.telefone_fixo_tec} / {originalData?.partner?.telefone_comercial}</p>
                <p><b>Ponto de Partida: </b>{`${originalData?.start_address?.logradouro_endereco_partida || ''} - ${originalData?.start_address?.compl_ref_obs_endereco_partida || ''}, ${originalData?.start_address?.bairro_endereco_partida || ''}, ${originalData?.start_address?.cidade_endereco_partida || ''} - ${originalData?.start_address?.estado_endereco_partida || ''}`}</p>
                <p><b>End. Logística: </b>{originalData?.logistics_shipping !== null && (`${originalData?.logistics_shipping?.logradouro_endereco_logistica} - ${originalData?.logistics_shipping?.compl_ref_obs_endereco_logistica}, ${originalData?.logistics_shipping?.bairro_endereco_logistica}, ${originalData?.logistics_shipping?.cidade_endereco_logistica} - ${originalData?.logistics_shipping?.estado_endereco_logistica}`)}</p>
                <p><b>Data e Hora Agendamento: </b>{`${originalData?.data_agendamento} ${originalData?.hora_agendamento}`}</p>
                <p><b>Km Máximo Contrato: </b>{originalData?.contract?.km_maximo}</p>
                <p><b>Escopo de Valores: </b>{originalData?.escopo_valores || ''}</p>
                <p><b style={{ color: `${(originalData?.km_tecnico > originalData?.contract?.km_maximo) && originalData?.status_chamado === "Aprovação do Responsável" ? "red" : "black"}` }}>Km do Técnico para o Contrato: </b>{originalData?.km_tecnico}</p>
            </div>
            <If condition={showUpdateBtn}>
                <If condition={(checkPermissionGroup(['acionamento_operacao', 'analista_suporte', 'gerente_operacoes', 'acionamento_gestao', 'acionamento_supervisao']) && userData?.userDataState?.cliente === 0)}>
                    <div className="col-6">
                        <button className="btn btn-secondary w-100 mb-1" onClick={() => setShowAlterarTecnicoBtn(prevState => !prevState)}>Alt. Técnico</button>
                    </div>
                </If>
                <If condition={(checkPermissionGroup(['acionamento_operacao', 'analista_suporte', 'gerente_operacoes', 'acionamento_gestao', 'acionamento_supervisao']) && userData?.userDataState?.cliente === 0)}>
                    <div className="col-6">
                        <button className="btn btn-secondary w-100 mb-1" onClick={() => setShowAlterarKmBtn(prevState => !prevState)}>Alt. KM Téc.</button>
                    </div>
                </If>
                <If condition={(checkPermissionGroup(['acionamento_operacao', 'analista_suporte', 'gerente_operacoes', 'acionamento_gestao', 'acionamento_supervisao']) && userData?.userDataState?.cliente === 0 && ["Fechado", "fechado"].includes(detailsData?.status_chamado) === false)}>
                    <div className="col-6">
                        <button className="btn btn-secondary w-100" onClick={() => setShowNovaDataAgendamentoBtn(prevState => !prevState)}>Alt. Agend.</button>
                    </div>
                </If>
            </If>
        </div>
    </Card>);
}