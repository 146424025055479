import interceptorInstance from "./Interceptor";

export async function createModel(params){
    try{
        let data = await interceptorInstance.post(`product-partnumber/create`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function searchModels(params){
    try{
        let data = await interceptorInstance.get(`product-partnumber?modelo=${params}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getModelsByManufacturers(manufacturer_id, search = ''){
    try{
        let searchData = '';

        if(search !== ''){
            searchData = '?search=' + search;
        }

        let data = await interceptorInstance.get(`product-partnumber/${manufacturer_id}/manufacturer${searchData}`);
        return data;
    }catch(e){
        return e.response;
    }
}