import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getAllTicketStatus } from '../../services/Ticket';

export default function StatusChamadoSelect({ formData, setFormData, fieldName = 'status_chamado', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false, showTodos=true }) {
    const [statusChamadoOptions, setStatusChamadoOptions] = useState([]);

    async function getAllStatus(){
        let dataReturn = await getAllTicketStatus();

        if(dataReturn?.data?.response === 'success'){
            let status = dataReturn?.data?.data?.map((item, idx)=>{
                return { value: item.nome_status, label: item.nome_status }
            });
            
            let statusTodos = showTodos ? [{value: '', label: 'TODOS'}] : [];
            setStatusChamadoOptions([...statusTodos, ...status]);
        }else{
            setStatusChamadoOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        getAllStatus();
    }, [])

    return (
        <div className={cols.length > 0 ? `col-sm-${cols[0]} col-md-${cols[1]} col-lg-${cols[2]} col-xl-${cols[3]}` : 'col'}>
            <label htmlFor={fieldName}>Status Chamado</label>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={statusChamadoOptions}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </div>
    );
}