import defaultUserImage from '../resources/imgs/profile/default.jpg';
import defaultErrorImage from '../resources/imgs/error/no-image.jpg';
import dayjs from 'dayjs';

export default function useGenerics() {

    function isJsonValid(json) {
        try {
            JSON.parse(json);
            return true;
        } catch (e) {
            return false;
        }
    }

    function openNewTab(url) {
        window.open(window.location.origin + url, '_blank')
    }

    function onImageError(e, type = 'profile') {
        if (type === 'profile') {
            e.target.src = defaultUserImage
        } else {
            e.target.src = defaultErrorImage
        }
    }

    function detectDeviceTypeByUserAgent() {
        const userAgent = navigator.userAgent.toLowerCase();

        if (userAgent.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i)) {
            return "mobile";
        } else {
            return "desktop";
        }
    }

    function getCurrentDate(daysToAdd = 0, monthsToAdd = 1, yearsToAdd = 0){
        const date = new Date();

        date.setDate(date.getDate() + daysToAdd);
        date.setMonth(date.getMonth() + monthsToAdd);
        date.setFullYear(date.getFullYear() + yearsToAdd);

        const currentMonth = (date.getMonth()).toString().padStart(2, '0');
        const currentDay = (date.getDate()).toString().padStart(2, '0');
        const currentDate = `${date.getFullYear()}-${currentMonth}-${currentDay}`;
        return currentDate;
    }

    function getCurrentTime(){
        const date = new Date();
        const currentHour = date.getHours().toString().padStart(2, '0');
        const currentMinutes = date.getMinutes().toString().padStart(2, '0');
        const currentTime = `${currentHour}:${currentMinutes}`;
        return currentTime;
    }

    function checkDateBetween(dataInicial, dataFinal, dataCorrente){
        let firstDate = new Date(dataInicial);
        let lastDate = new Date(dataFinal);
        let currentDate = new Date(dataCorrente);
        return ((firstDate <= currentDate) && (currentDate <= lastDate));
    }

    function getWeek(date){
        const newDate = new Date(date);
        const startOfYear = new Date(newDate.getFullYear(), 0, 1);
        const pastDayOfYear = (newDate - startOfYear) / 86400000;
        const weekNumber = Math.ceil((pastDayOfYear + startOfYear.getDay() + 1) / 7);

        return `${newDate.getFullYear()}-W${weekNumber}`;
    }

    function convertWeekToDate(week){
        const splittedData = week.split("-W");
        const startOfYear = dayjs(`${splittedData[0]}-01-01`);
        let firstMonday = startOfYear.day(1);
        
        if(firstMonday.isBefore(startOfYear)){
            firstMonday = firstMonday.add(1, 'week');
        }

        const mondayOfWeek = firstMonday.add(parseInt(splittedData[1]) - 1, 'week');
        const sundayOfWeek = mondayOfWeek.add(6, 'day');

        return {
            startOfWeek: mondayOfWeek.format('YYYY-MM-DD'),
            endOfWeek: sundayOfWeek.format('YYYY-MM-DD')
        }
    }

    function base64toBlob(base64, contentType = 'image/jpeg', sliceSize = 512){
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for(let offset = 0; offset < byteCharacters.length; offset += sliceSize){
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);

            for(let i = 0; i < slice.length; i++){
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    }

    function copyToClipboard(text){
        if (navigator.clipboard && navigator.clipboard.writeText) {

            navigator.clipboard.writeText(text).then(() => {
                console.log('Texto copiado com sucesso!');
            }).catch((e) => {
                const textarea = document.createElement('textarea');
                textarea.value = text;
                document.body.appendChild(textarea);

                textarea.select();
                document.execCommand('copy');

                document.body.removeChild(textarea);
            });

        } else {

            console.warn('Clipboard API indisponível, usando método legado');
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);

            textarea.select();
            document.execCommand('copy');

            document.body.removeChild(textarea);
        }

    }

    return {
        isJsonValid,
        openNewTab,
        onImageError,
        detectDeviceTypeByUserAgent,
        getCurrentDate,
        getCurrentTime,
        checkDateBetween,
        getWeek,
        convertWeekToDate,
        base64toBlob,
        copyToClipboard
    }

}