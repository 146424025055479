import React, { useEffect, useRef, useState } from 'react';
import TiposDespesasSelect from '../../../common/Selects/TiposDespesasSelect';
import CustomMessageData from '../../../common/Layout/CustomMessageData';
import { createExpense } from '../../../services/EmployeeExpense';
import { toast } from 'react-toastify';
import Modal from '../../../common/Layout/Modal';
import useGenerics from '../../../hooks/useGenerics';
import ChamadosSelect from '../../../common/Selects/ChamadosSelect';
import If from '../../../common/Layout/If';

export default function CreateRefund({ isOpen = false, setIsOpen = null, params = {}, setUpdateNewData }) {
    const INITIAL_STATE = {
        id_chamado: params?.type === 'specific_ticket' ? params?.ticketId : '',
        anexo_foto: '',
        valor: 0.0,
        valor_combustivel: '',
        tipo_despesa: '',
        id_tecnico: params?.technicianId,
        nome_tecnico: params?.technicianName,
        chamado: params?.ticketNumber
    }

    const [formData, setFormData] = useState({});
    const [previewImg, setPreviewImg] = useState(null);
    const [isBtnDisabled, setIsBtnDisabled] = useState(false);

    const anexoRef = useRef();
    const { onImageError } = useGenerics();

    function handleImg(e) {
        if (e.target.files && e.target.files[0]) {
            setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.files[0] }));
            setPreviewImg(URL.createObjectURL(e.target.files[0]));
        } else {
            setFormData(prevState => ({ ...prevState, [e.target.name]: '' }));
            anexoRef.current.value = "";
            setPreviewImg(null);
        }
    }

    async function submitForm(e) {
        e.preventDefault();
        setIsBtnDisabled(prevState => (!prevState));
        let idChamado = params?.type === 'specific_ticket' ? formData?.id_chamado : formData?.id_chamado?.value;

        let paramsData = new FormData();
        paramsData.append('id_chamado', idChamado || '');
        paramsData.append('tipo_despesa', formData?.tipo_despesa?.value || '');
        paramsData.append('id_tecnico', formData?.id_tecnico || '');
        paramsData.append('valor', formData?.valor || 0);
        paramsData.append('valor_combustivel', formData?.valor_combustivel || '');
        paramsData.append('anexo_foto', formData?.anexo_foto || '');

        const toastForm = toast.loading("Cadastrando Reembolso, aguarde...");
        let dataReturn = await createExpense(paramsData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastForm, { render: 'Reembolso cadastrado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setFormData(INITIAL_STATE);
            anexoRef.current.value = '';
            setPreviewImg(null);
            setUpdateNewData();
        } else {
            toast.update(toastForm, { render: 'Ocorreu um erro ao cadastrar o reembolso!', type: "warning", isLoading: false, autoClose: 1500 });
        }
        setIsBtnDisabled(prevState => (!prevState));
    }

    useEffect(() => {
        if (isOpen) {
            setFormData(INITIAL_STATE);
            setIsBtnDisabled(false);
        }
    }, [isOpen])

    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={setIsOpen}
            title='Cadastrar Novo Reembolso'
            customStyle={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90vw',
                height: '95vh'
            }}
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                        <h6>Pré-Visualizar Nota</h6>
                        <div className='d-flex justify-content-center align-items-center'>
                            <If condition={previewImg === null}>
                                <CustomMessageData message='Sem Imagem'></CustomMessageData>
                            </If>
                            <If condition={previewImg !== null}>
                                <img alt='refund_img' src={previewImg} style={{ width: '100%', maxWidth: '100%', height: '100%', maxHeight: '100%', borderRadius: '15px' }} onError={(e) => onImageError(e, 'error')}></img>
                            </If>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-8">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="">Nome</label>
                                <input type="text" className="form-control" disabled={true} value={formData?.nome_tecnico || ''} />
                            </div>
                            <If condition={params?.type === 'all_tickets'}>
                                <ChamadosSelect formData={formData} setFormData={setFormData} fieldName='id_chamado' required={true} cols={[12, 12, 6, 6]}></ChamadosSelect>
                            </If>
                            <If condition={params?.type !== 'all_tickets'}>
                                <div className="col-sm-12 col-md-6">
                                    <label htmlFor="">Chamado</label>
                                    <input type="text" className="form-control" disabled={true} value={formData?.chamado || ''} />
                                </div>
                            </If>
                            <TiposDespesasSelect formData={formData} setFormData={setFormData} cols={[12, 6, 6, 6]} required={true}></TiposDespesasSelect>
                            <If condition={formData?.tipo_despesa?.value === 'combustivel'}>
                                <div className="col-sm-12 col-md-6">
                                    <label htmlFor="valor_combustivel">Valor Combustível</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className='input-group-text'>R$</span>
                                        </div>
                                        <input type="number" name='valor_combustivel' id='valor_combustivel' className='form-control' value={formData?.valor_combustivel || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} min={0} step={0.01} required />
                                    </div>
                                </div>
                            </If>
                            <div className="col-sm-12 col-md-6">
                                <label htmlFor="valor">Valor</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className='input-group-text'>R$</span>
                                    </div>
                                    <input type="number" name="valor" id="valor" className='form-control' value={formData?.valor || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} min={0} step={0.01} required />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <label htmlFor="anexo_foto">Anexo</label>
                                <input type="file" name="anexo_foto" id="anexo_foto" className='form-control' accept='image/*' onChange={(e) => { handleImg(e) }} ref={anexoRef} required />
                            </div>
                        </div>
                        <div className="col-12 mt-1">
                            <button type="submit" className="btn btn-primary float-right" disabled={isBtnDisabled}>Cadastrar</button>
                        </div>
                    </div>
                </div >
            </form >
        </Modal >)
}