import './ShowTickets.css';
import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import FloatSearchButton from '../../../common/Layout/FloatSearchButton';
import { getTickets } from '../../../services/Ticket';
import { toast } from 'react-toastify';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import StatusChamadoSelect from '../../../common/Selects/StatusChamadoSelect';
import UsuariosAtivosSelect from '../../../common/Selects/UsuariosAtivosSelect';
import IntegradoraSelect from '../../../common/Selects/IntegradoraSelect';
import ContratosMultiSelect from '../../../common/Selects/ContratosMultiSelect';
import RepresentanteSelect from '../../../common/Selects/RepresentanteSelect';
import TecnicosSelect from '../../../common/Selects/TecnicosSelect';
import useGenerics from '../../../hooks/useGenerics';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';

export default function ShowTickets() {
    //Variables
    let interval;
    const arrayFields = ['status_chamado', 'contrato', 'remove_contrato', 'integradora'];
    const initialState = { data_inicial: '', data_final: '', status_chamado: [], id_usuario_responsavel_chamado: '', integradora: [], contrato: [], id_representante: '', id_tecnico: '' }
    const [filterForm, setFilterForm] = useState(initialState);
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { copyToClipboard } = useGenerics();

    const [data, setData] = useState([]);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [loading, setIsLoading] = useState(true);
    const milliseconds = 300000; //5 minutos
    const columns = [
        {
            id: 'action',
            name: 'Ação',
            body: (row) => (<Link to={'/chamados/detalhes-chamado/' + row.id}><i className='fas fa-eye'></i></Link>)
        },
        {
            id: 'num_chamado',
            name: 'ID Ticket Cli. / Nº Chamado',
            body: (row) => (<span onClick={() => copyToClipboard(row.num_chamado)}>{row.num_chamado}</span>)
        },
        {
            id: 'data_agendamento',
            name: 'Data Agendamento',
        },
        {
            id: 'hora_agendamento',
            name: 'Hora Agendamento',
        },
        {
            id: 'responsavel_atendimento',
            name: 'Responsavel Atendimento',
        },
        {
            id: 'urgencia',
            name: 'Urgencia',
            body: (row) => (<b style={{color: 'red'}}>{row.urgencia}</b>)
        },
        {
            id: 'status_chamado',
            name: 'Status Chamado',
        },
        {
            id: 'cliente',
            name: 'Cliente',
        },
        {
            id: 'integradora',
            name: 'Integradora',
        },
        {
            id: 'contrato',
            name: 'Contrato',
        },
        {
            id: 'tecnico',
            name: 'Técnico',
            body: (row) => (<Link to={'/cadastros/tecnicos/detalhes/' + row.id_tecnico}>{row.tecnico}</Link>)
        },
        {
            id: 'representante',
            name: 'Representante',
        },
        {
            id: 'doc_entregue_validacao',
            name: 'Doc Entregue / Validação',
            body: (row) => (<b style={{color: 'red'}}>{row.doc_entregue_validacao}</b>)
        },
        {
            id: 'contato_tecnico',
            name: 'Contato Tecnico',
        },
        {
            id: 'status_tecnico',
            name: 'Status Técnico',
        },
        {
            id: 'descricao',
            name: 'Descrição',
        },
        {
            id: 'endereco_cliente',
            name: 'End. Cliente',
        },
        {
            id: 'bairro_cliente',
            name: 'Bairro Cliente',
        },
        {
            id: 'cidade_cliente',
            name: 'Cidade Cliente',
        },
        {
            id: 'estado_cliente',
            name: 'Estado Cliente',
        },
        {
            id: 'cep_cliente',
            name: 'CEP Cliente',
        },
        {
            id: 'compl_end_cliente',
            name: 'Comp. End. Cliente',
        },
        {
            id: 'contato_no_cliente',
            name: 'Contato no Cliente',
        },
        {
            id: 'tel_contato_cliente',
            name: 'Tel. Contato Cliente',
        },
        {
            id: 'inf_adc_contrato',
            name: 'Inf. Adc. Contrato',
        },
        {
            id: 'endereco_partida',
            name: 'Logradouro da Partida',
        },
        {
            id: 'bairro_partida',
            name: 'Bairro da Partida',
        },
        {
            id: 'cidade_partida',
            name: 'Cidade da Partida',
        },
        {
            id: 'estado_partida',
            name: 'Estado da Partida',
        },
        {
            id: 'cep_partida',
            name: 'CEP da Partida',
        },
        {
            id: 'compl_ref_obs_endereco_partida',
            name: 'Comp. End. Partida',
        },
        {
            id: 'data_registro',
            name: 'Data de Registro',
        },
        {
            id: 'data_inicio_desloc',
            name: 'Data Início Desloc',
        },
        {
            id: 'hora_inicio_desloc',
            name: 'Hora Início Desloc',
        },
        {
            id: 'data_fim_desloc',
            name: 'Data Fim Desloc',
        },
        {
            id: 'hora_fim_desloc',
            name: 'Hora Fim Desloc',
        },
        {
            id: 'data_solicitacao',
            name: 'Data da Solicitação',
        },
        {
            id: 'hora_solicitacao',
            name: 'Hora da Solicitação',
        },
        {
            id: 'usuario_responsavel_parceria',
            name: 'Resp. Parceria',
        },
        {
            id: 'key_ticket_cliente',
            name: 'Key Ticket Cliente'
        },
        {
            id: 'fabricante',
            name: 'Fabricante',
        }
    ];

    //Functions 
    const conditionRow = (row) => {
        return {
            "danger-color custom-style": row.cor_row === 'vermelho',
            "info-color custom-style": row.cor_row === 'azul',
            "warning-color custom-style": row.cor_row === 'amarelo',
            "success-color custom-style": row.cor_row === 'verde',
        }
    }

    async function getTicketsByParam(page, perPage) {
        const toastTicket = toast.loading("Carregando, aguarde...");
        try {
            let urlParams = new URLSearchParams(location.search);
            let params = {
                page: page,
                per_page: perPage
            }
            let booleanFields = ['gts', 'key_ticket_cliente_null']

            urlParams.forEach((item, idx) => {

                if (item !== "") {
                    if (arrayFields.includes(idx)) {
                        if (!params[idx]) {
                            params[idx] = [];
                        }
                        params[idx].push(item);
                    } else if (booleanFields.includes(idx)) {
                        params[idx] = item === "true" ? true : false;
                    } else {
                        params[idx] = item;
                    }
                }
            });
            setIsLoading(true);
            setData([]);
            setPaginationTotalRows(0);
            
            let dataReturn = await getTickets(params);
            
            if (dataReturn?.data?.response === 'success') {
                let ticketData = dataReturn?.data?.data || [];

                if (ticketData?.tickets?.length > 0) {
                    ticketData.tickets = ticketData?.tickets?.map((item, idx) => {
                        for (let i in item) {
                            item[i] = [undefined, null].includes(item[i]) ? '' : item[i]
                        }
                        return item;
                    });
                }
                setData(prev => ticketData?.tickets);
                setPaginationTotalRows(prev => ticketData?.pagination?.total);
            } else {
                setPaginationTotalRows(0)
                setData([]);
            }
            setIsLoading(false);
        } catch (e) {
            setData([]);
            setIsLoading(false);
        }
        toast.update(toastTicket, { render: 'Pesquisa realizada com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
    }

    function handleFilterForm(e) {
        setFilterForm({ ...filterForm, [e.target.name]: e.target.value });
    }

    function handleClearForm() {
        setFilterForm(initialState);
    }

    async function getTicketsByFilter(e) {
        e.preventDefault();
        let params = {};

        params['data_inicial'] = filterForm.data_inicial;
        params['data_final'] = filterForm.data_final;
        params['status_chamado'] = filterForm.status_chamado.map((item) => (item?.value === undefined ? '' : item.value));
        params['integradora'] = filterForm.integradora.map((item) => (item?.value === undefined ? '' : item.value));
        params['contrato'] = filterForm.contrato.map((item) => (item?.value === undefined ? '' : item.value));
        params['id_representante'] = filterForm.id_representante?.value === undefined ? '' : filterForm.id_representante?.value;
        params['id_tecnico'] = filterForm.id_tecnico?.value === undefined ? '' : filterForm.id_tecnico?.value;
        params['id_usuario_responsavel_chamado'] = filterForm.id_usuario_responsavel_chamado?.value === undefined ? '' : filterForm.id_usuario_responsavel_chamado?.value;
        let url = `?`;

        for (let p in params) {
            if (arrayFields.includes(p)) {
                for (let c = 0; c < params[p].length; c++) {
                    url += (`${p}=${params[p][c]}&`);
                }
            } else {
                url += (`${p}=${params[p]}&`);
            }
        }

        url = url.slice(0, -1);

        navigate(url);
    }

    function handleSetPartner(value, name) {
        setFilterForm(prevState => ({ ...prevState, [name]: value, id_tecnico: '' }));
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
    }

    //Hooks
    useEffect(() => {
        getTicketsByParam(page + 1, perPage);

        if (interval) {
            clearInterval(interval);
        }

        interval = setInterval(() => {
            getTicketsByParam(page + 1, perPage);
        }, milliseconds);

        return () => {
            clearInterval(interval);
        };

    }, [page, perPage, location.search]);

    //Component's return 
    return (
        <div key={id}>
            <Content headerTitle="Chamados">
                <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
                <FloatSearchButton></FloatSearchButton>
                <div className='card'>
                    <div className='card-body'>
                        <form onSubmit={getTicketsByFilter}>
                            <div className='row'>
                                <div className='col'>
                                    <label htmlFor='dataInicio'>Data Início</label>
                                    <input type='date' className='form-control' name='data_inicial' id='dataInicio' value={filterForm.data_inicial} onChange={handleFilterForm}></input>
                                </div>
                                <div className='col'>
                                    <label htmlFor='dataFim'>Data Fim</label>
                                    <input type='date' className='form-control' name='data_final' id='dataFim' value={filterForm.data_final} onChange={handleFilterForm}></input>
                                </div>
                                <StatusChamadoSelect formData={filterForm} setFormData={setFilterForm} cols={[]} fieldName='status_chamado' isMulti={true}></StatusChamadoSelect>
                                <UsuariosAtivosSelect formData={filterForm} setFormData={setFilterForm} cols={[]} fieldName='id_usuario_responsavel_chamado' title='Resp. Atendimento' userType='support'></UsuariosAtivosSelect>
                                <IntegradoraSelect formData={filterForm} setFormData={setFilterForm} cols={[]} fieldName='integradora' isMulti={true}></IntegradoraSelect>
                                <ContratosMultiSelect
                                    formData={filterForm}
                                    setFormData={setFilterForm}
                                    cols={[]}
                                    dependsOnIntegradora={true}
                                    id_integradora={
                                        filterForm?.integradora?.length > 0
                                            ? filterForm.integradora.map(item => item?.value)
                                            : []
                                    }>
                                </ContratosMultiSelect>
                                <RepresentanteSelect formData={filterForm} setFormData={setFilterForm} cols={[]} fieldName='id_representante' advancedSetFormData={handleSetPartner}></RepresentanteSelect>
                                <TecnicosSelect formData={filterForm} setFormData={setFilterForm} cols={[]} fieldName='id_tecnico' id_representante={filterForm?.id_representante?.value}></TecnicosSelect>
                            </div>
                            <div className='row mt-1'>
                                <div className='col'>
                                    <button type='submit' className='btn btn-primary mr-1 d-inline'>Filtrar</button>
                                    <button type='button' className='btn btn-warning ml-1 d-inline' onClick={handleClearForm}>Limpar Filtros</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='card'>
                    <div className='card-body'>
                        <PrimeTable
                            columns={columns}
                            rows={data}
                            serverPagination={true}
                            handlePagination={handlePaginationPrime}
                            handleRowPerPage={handleRowPerPagePrime}
                            count={paginationTotalRows}
                            page={page}
                            rowsPerPage={perPage}
                            loading={loading}
                            customStyles={conditionRow}
                        ></PrimeTable>
                    </div>
                </div>
            </Content>
        </div>
    );
}