import interceptorInstance from "./Interceptor";

export async function createShipment(productId, params){
    try{
        let data = await interceptorInstance.post(`shipment/create/${productId}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getShipmentsByStatus(status, page = 1, perPage = 10){
    try{
        let data = await interceptorInstance.get(`shipment/search/status/${status}?page=${page}&per_page=${perPage}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getShipmentById(id){
    try{
        let data = await interceptorInstance.get(`shipment/get/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function sendForInvoiceIssuance(id, params){
    try{
        let data = await interceptorInstance.patch(`shipment/invoice-issuance/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function changeShipmentStatus(id, params = {status_remessa: 'CANCELADA'}){
    try{
        let data = await interceptorInstance.patch(`shipment/change-shipment/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function confirmInvoiceIssuance(id, params){
    try{
        let data = await interceptorInstance.patch(`shipment/confirm-invoice-issuance/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function confirmReception(id, params){
    try{
        let data = await interceptorInstance.patch(`shipment/shipment-received/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getAllPendingDactShipments(page = 1, perPage = 10){
    try{
        let data = await interceptorInstance.get(`shipment/search/pending-dact?page=${page}&per_page=${perPage}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateInPersonDelivery(id, params){
    try{
        let data = await interceptorInstance.post(`shipment/in-person-delivery/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updateDactValues(id, params){
    try{
        let data = await interceptorInstance.patch(`shipment/update-dact-values/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getSignDataById(id){
    try{
        let data = await interceptorInstance.get(`shipment/signatures/${id}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getShipmentsByDateAndStatus({data_inicial='', data_final='', page = 1, perPage = 10}){
    try{
        let data = await interceptorInstance.get(`shipment/search/received?data_inicial=${data_inicial}&data_final=${data_final}&page=${page}&per_page=${perPage}`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function countShipmentByStatus(){
    try{
        let data = await interceptorInstance.get(`shipment/count`);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function updatePendingProduct(id, params){
    try{
        let data = await interceptorInstance.patch(`shipment/to-send/${id}`, params);
        return data;
    }catch(e){
        return e.response;
    }
}