import React, { useState } from 'react';
import Card from '../../../common/Layout/Card';
import { toast } from 'react-toastify';
import { validateDocumentByTicketId } from '../../../services/Ticket';
import GenericSelect from '../../../common/Selects/GenericSelect';
import If from '../../../common/Layout/If';

export default function ValidarDocumentacaoCard({
    id = '',
    originalData = {},
    detailsData = {},
    setDetailsData = null,
    updateAllData = null
}) {
    const INITIAL_STATE = {
        documentacao_entregue: { value: originalData?.documentacao_entregue, label: originalData?.documentacao_entregue },
        documentacao_entregue_url: originalData?.documentacao_entregue_url,
        documentacao_entregue_url_tecnico: originalData?.documentacao_entregue_url_tecnico,
        liberar_pagamento: { value: originalData?.liberar_pagamento, label: originalData?.liberar_pagamento },
        motivo_liberar_pagamento: originalData?.motivo_liberar_pagamento || '',
        nota_validacao_documento: originalData?.nota_validacao_documento || ''
    };
    const [documentForm, setDocumentForm] = useState(INITIAL_STATE);

    async function submitDocumentForm(e) {
        e.preventDefault();

        let formData = {
            documentacao_entregue: documentForm?.documentacao_entregue?.value,
            documentacao_entregue_url: documentForm?.documentacao_entregue_url,
            documentacao_entregue_url_tecnico: documentForm?.documentacao_entregue_url_tecnico,
            liberar_pagamento: documentForm?.liberar_pagamento?.value || '',
            motivo_liberar_pagamento: documentForm?.motivo_liberar_pagamento || '',
            nota_validacao_documento: documentForm?.nota_validacao_documento
        }
        const toastDocument = toast.loading('Validando documentação, aguarde...');

        let dataReturn = await validateDocumentByTicketId(id, formData);

        if (dataReturn?.data?.message === 'Documentação validada com sucesso.') {
            toast.update(toastDocument, { render: dataReturn?.data?.message, type: "success", isLoading: false, autoClose: 1500 });
            setDetailsData(prevState => ({ ...prevState, nota_validacao_documento: '' }));
            updateAllData();
        } else {
            toast.update(toastDocument, { render: 'Ocorreu um erro ao validar a documentação.', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    return (
        <Card title={`Validar Documentação (Status Atual: ${originalData?.documentacao_entregue})`} icon={'fa-check'}>
            <form onSubmit={submitDocumentForm}>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="documentacao_entregue_url_tecnico">Url Dropbox Técnico via App Maminfo (<a href={documentForm?.documentacao_entregue_url_tecnico} target='_blank' rel="noreferrer">Link Externo</a>)</label>
                            <input type="text" name="documentacao_entregue_url_tecnico" className="form-control" placeholder='...' value={documentForm?.documentacao_entregue_url_tecnico || ''} onChange={(e) => setDocumentForm(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                        </div>
                    </div>
                    <GenericSelect formData={documentForm} setFormData={setDocumentForm} fieldName={'documentacao_entregue'} title={'Documentação Entregue / Validação'} cols={[12, 3, 3, 3]} genericOption='DocumentacaoEntregueOptions'></GenericSelect>
                    <div className="col-md-9 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="">URL da Documentação (<a href={documentForm?.documentacao_entregue_url} target='_blank' rel="noreferrer">Link Externo</a>)</label>
                            <input type="text" className="form-control mb-2" placeholder='...' name="documentacao_entregue_url" value={documentForm?.documentacao_entregue_url || ''} onChange={(e) => setDocumentForm(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                            {
                                detailsData?.documentacao_entregue_url !== "" &&
                                (<a href={documentForm?.documentacao_entregue_url} role='button' target='_blank' rel='noreferrer' className='btn btn-primary' download={true}>Download da Documentação</a>)
                            }
                        </div>
                    </div>
                    <GenericSelect formData={documentForm} setFormData={setDocumentForm} fieldName={'liberar_pagamento'} title={'Pagamento *'} cols={[12, 3, 3, 3]} genericOption='PagamentoOptions'></GenericSelect>
                    <If condition={documentForm?.liberar_pagamento?.value === "Não liberar o pagamento"}>
                        <div className="col-md-9 col-sm-12">
                            <div className="form-group">
                                <label htmlFor="motivo_liberar_pagamento">Motivo de Não Liberar Pagamento *</label>
                                <input type="text" name="motivo_liberar_pagamento" className="form-control" placeholder='...' id="motivo_liberar_pagamento" value={documentForm?.motivo_liberar_pagamento || ''} onChange={(e) => setDocumentForm(prevState => ({ ...prevState, motivo_liberar_pagamento: e.target.value }))} />
                            </div>
                        </div>
                    </If>
                    <div className="col-12 mb-2">
                        <label htmlFor="nota_validacao_documento">Comentário no histórico do chamado</label>
                        <textarea name="nota_validacao_documento" id="nota_validacao_documento" cols="30" rows="5" className="form-control" placeholder='...' value={documentForm?.nota_validacao_documento || ''} onChange={(e) => setDocumentForm(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}></textarea>
                    </div>
                    <div className="col-12">
                        <button type='submit' className="btn btn-primary">Validar documentação</button>
                    </div>
                </div>
            </form>
        </Card>);
}