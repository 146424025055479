import React, { useEffect, useState } from 'react';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../../common/Layout/Card';
import Swal from 'sweetalert2';
import Content from '../../../../common/Layout/Content';
import { toast } from 'react-toastify';
import { changeShipmentStatus, getShipmentsByStatus } from '../../../../services/Shipment';
import MarkAsReceived from './MarkAsReceived';
import InTransitDetails from './InTransitDetails';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import If from '../../../../common/Layout/If';
import usePermissions from '../../../../hooks/usePermissions';
import useGenerics from '../../../../hooks/useGenerics';

export default function InTransit() {
    let interval;
    const [data, setData] = useState([]);
    const [openInTransitDetails, setOpenInTransitDetails] = useState(false);
    const [inTransitId, setInTransitId] = useState('');
    const [openMarkAsReceived, setOpenMarkAsReceived] = useState(false);
    const [viewMode, setViewMode] = useState('view');
    const [updateLogistic, setUpdateLogistic] = useState(false);

    const [loading, setLoading] = useState(true);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const {checkPermissionGroup} = usePermissions();
    const { openNewTab } = useGenerics();

    const milliseconds = 300000; //5 minutos
    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <button type="button" className="dropdown-item" onClick={() => openInTransitDetailsModal(row.id_produto, 'view')}>Ver</button>
                        <button type='button' className="dropdown-item" onClick={() => openMarkAsReceivedModal(row.id_produto)}>Marcar como recebido</button>
                        <If condition={checkPermissionGroup(["logistica_gestao"])}>
                            <button type='button' className="dropdown-item" onClick={() => showReturnToInvoiceSwal(row.id_produto)}>Cancelar Em Trânsito</button>
                        </If>
                    </div>
                </div>
            ),
        },
        {
            id: 'nfe_saida_produto',
            name: 'NFE Saída',
        },
        {
            id: 'nome_produto',
            name: 'Nome do Produto',
        },
        {
            id: 'serial_produto',
            name: 'Serial',
        },
        {
            id: 'tipo_produto',
            name: 'Tipo de Produto',
        },
        {
            id: 'id_localidade_produto',
            name: 'ID da Localidade\\Num. Chamado',
            body: (row) => (<a onClick={() => openNewTab('/chamados/detalhes-chamado/' + row.id_chamado_produto)} href="javascript:void(0)">{row.id_localidade_produto}</a>)
        },
        {
            id: 'contrato_produto',
            name: 'Contrato\\Projeto'
        },
        {
            id: 'urgencia_produto',
            name: 'Urgência',
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
        },
        {
            id: 'codigo_rastreio_produto',
            name: 'Código de Rastreio',
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
        },
        {
            id: 'cep_produto',
            name: 'CEP',
        },
        {
            id: 'uf_produto',
            name: 'UF',
        },
        {
            id: 'cidade_produto',
            name: 'Cidade',
        },
        {
            id: 'bairro_produto',
            name: 'Bairro',
        },
        {
            id: 'rua_produto',
            name: 'Rua',
        },
        {
            id: 'numero_produto',
            name: 'Número',
        },
        {
            id: 'complemento_produto',
            name: 'Complemento',
        },
    ]

    function openInTransitDetailsModal(id, mode) {
        setInTransitId(id);
        setViewMode(mode);
        setOpenInTransitDetails(true);
    }

    async function showReturnToInvoiceSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja realmente retornar a remessa para emissão de NFE?',
            text: 'Todos os campos da NFE poderão ser alterados.',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            returnToInvoice(id);
        }
    }

    async function returnToInvoice(id) {
        const toastProducts = toast.loading("Cancelando emissão, aguarde...");

        let dataReturn = await changeShipmentStatus(id, { status_remessa: 'AGUARDANDO NFE SAÍDA' });

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Emissão cancelada com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getInTransitByStatus();
            setUpdateLogistic(true);
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao cancelar a emissão!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function getInTransitByStatus(page = 1, perPage = 10) {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getShipmentsByStatus('EM TRÂNSITO', page, perPage);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });

            let products = dataReturn?.data?.data?.data || [];

            let productData = products.map((item, idx) => ({
                id_produto: item?.id || '',
                tipo_produto: item?.product?.nome_produto || '',
                nome_produto: item?.product?.modelo || '',
                serial_produto: item?.product?.serial || '',
                urgencia_produto: item?.urgencia_remessa || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                nfe_saida_produto: item?.nfe_saida || '',
                cep_produto: item?.cep || '',
                uf_produto: item?.uf || '',
                cidade_produto: item?.cidade || '',
                bairro_produto: item?.bairro || '',
                rua_produto: item?.rua || '',
                numero_produto: item?.numero || '',
                complemento_produto: item?.complemento || '',
                codigo_rastreio_produto: item?.rastreamento || '',
                id_chamado_produto: item?.ticket?.id || '',
                id_localidade_produto: item?.ticket?.num_chamado || '',
                contrato_produto: item?.ticket?.contract?.contrato || ''
            }));
            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setData([]);
        }
        setLoading(false);
    }

    function openMarkAsReceivedModal(id) {
        setInTransitId(id);
        setOpenMarkAsReceived(true);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getInTransitByStatus(newPage + 1, perPage);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getInTransitByStatus(1, rows);
    }

    useEffect(() => {
        //Ver de quanto em quanto tempo será atualizado
        getInTransitByStatus();

        if (interval) {
            clearInterval(interval);
        }

        interval = setInterval(() => {
            getInTransitByStatus();
        }, milliseconds);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (<Content headerTitle='Peças em Trânsito'>
        <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
        <MarkAsReceived isOpen={openMarkAsReceived} setModalOpen={() => setOpenMarkAsReceived(!openMarkAsReceived)} inTransitId={inTransitId} setUpdateProducts={() => getInTransitByStatus(page + 1, perPage)} setUpdateLogistic={setUpdateLogistic}></MarkAsReceived>
        <InTransitDetails isOpen={openInTransitDetails} setModalOpen={() => setOpenInTransitDetails(!openInTransitDetails)} inTransitId={inTransitId} setUpdateProducts={() => getInTransitByStatus(page + 1, perPage)}></InTransitDetails>
        <Card title='Cadastro de Produtos - Peças em Trânsito'>
            <div className="row">
                <div className="col-12 d-flex justify-content-end flex-wrap">
                    <button type='button' className="btn btn-primary m-1" onClick={() => getInTransitByStatus()}>Atualizar Entregas</button>
                </div>
                <div className="col-12">
                    <PrimeTable
                        columns={columns}
                        rows={data}
                        serverPagination={true}
                        handlePagination={handlePaginationPrime}
                        handleRowPerPage={handleRowPerPagePrime}
                        count={paginationTotalRows}
                        page={page}
                        rowsPerPage={perPage}
                        loading={loading}
                    ></PrimeTable>
                </div>
            </div>
        </Card>
    </Content>)

}