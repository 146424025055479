import React, { useEffect, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../../common/Layout/Card';
import { changeShipmentStatus, getShipmentsByStatus } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import InvoiceDetails from './InvoiceDetails';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import If from '../../../../common/Layout/If';
import usePermissions from '../../../../hooks/usePermissions';
import useGenerics from '../../../../hooks/useGenerics';

export default function Invoice() {
    const [data, setData] = useState([]);
    const [openInvoiceDetails, setOpenInvoiceDetails] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [viewMode, setViewMode] = useState('view');
    const [updateLogistic, setUpdateLogistic] = useState(false);

    const [loading, setLoading] = useState(true);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const { checkPermissionGroup } = usePermissions();
    const { openNewTab } = useGenerics();
    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <button type="button" className="dropdown-item" onClick={() => openInvoiceDetailsModal(row.id_produto, 'view')}>Ver</button>
                        <If condition={checkPermissionGroup(["logistica_gestao", "logistica_operacao"])}>
                            <button type='button' className="dropdown-item" onClick={() => openInvoiceDetailsModal(row.id_produto, 'edit')}>Editar</button>
                            <If condition={checkPermissionGroup(["logistica_gestao"])}>
                                <button type='button' className="dropdown-item" onClick={() => showCancelShipmentSwal(row.id_produto)}>Cancelar Emissão de NFe</button>
                            </If>
                        </If>
                    </div>
                </div>
            )
        },
        {
            id: 'nome_produto',
            name: 'Nome Produto',
        },
        {
            id: 'serial_produto',
            name: 'Serial',
        },
        {
            id: 'tipo_produto',
            name: 'Tipo de Produto',
        },
        {
            id: 'id_localidade_produto',
            name: 'ID da Localidade\\Num. Chamado',
            body: (row) => (<a onClick={() => openNewTab('/chamados/detalhes-chamado/' + row.id_chamado_produto)} href="javascript:void(0)">{row.id_localidade_produto}</a>)
        },
        {
            id: 'contrato_produto',
            name: 'Contrato\\Projeto'
        },
        {
            id: 'urgencia_produto',
            name: 'Urgência',
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
        },
        {
            id: 'nfe_saida_produto',
            name: 'NFE Saída',
        },
        {
            id: 'cep_produto',
            name: 'CEP',
        },
        {
            id: 'uf_produto',
            name: 'UF',
        },
        {
            id: 'cidade_produto',
            name: 'Cidade',
        },
        {
            id: 'bairro_produto',
            name: 'Bairro',
        },
        {
            id: 'rua_produto',
            name: 'Rua',
        },
        {
            id: 'numero_produto',
            name: 'Número',
        },
        {
            id: 'complemento_produto',
            name: 'Complemento',
        },
    ];

    async function getInvoiceByStatus(page = 1, perPage = 10) {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getShipmentsByStatus('AGUARDANDO NFE SAÍDA', page, perPage);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });

            let products = dataReturn?.data?.data?.data || [];

            let productData = products.map((item, idx) => ({
                id_produto: item?.id || '',
                nome_produto:  item?.product?.modelo || '',
                tipo_produto: item?.product?.nome_produto || '',
                serial_produto: item?.product?.serial || '',
                urgencia_produto: item?.urgencia_remessa || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                nfe_saida_produto: item?.nfe_saida || '',
                cep_produto: item?.cep || '',
                uf_produto: item?.uf || '',
                cidade_produto: item?.cidade || '',
                bairro_produto: item?.bairro || '',
                rua_produto: item?.rua || '',
                numero_produto: item?.numero || '',
                complemento_produto: item?.complemento || '',
                id_chamado_produto: item?.ticket?.id || '',
                id_localidade_produto: item?.ticket?.num_chamado || '',
                contrato_produto: item?.ticket?.contract?.contrato || ''
            }));

            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setData([]);
        }
        setLoading(false);
    }

    async function showCancelShipmentSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja realmente cancelar a remessa?',
            text: 'O produto voltará para o status anterior ao estoque.',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            cancelShipment(id);
        }
    }

    async function cancelShipment(id) {
        const toastProducts = toast.loading("Cancelando emissão, aguarde...");

        let dataReturn = await changeShipmentStatus(id, { status_remessa: 'AGUARDANDO ENVIO' });

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Emissão cancelada com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getInvoiceByStatus();
            setUpdateLogistic(true);
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao cancelar a emissão!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    function openInvoiceDetailsModal(id, mode) {
        setInvoiceId(id);
        setViewMode(mode);
        setOpenInvoiceDetails(true);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getInvoiceByStatus(newPage + 1, perPage);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getInvoiceByStatus(1, rows);
    }

    useEffect(() => {
        getInvoiceByStatus();
    }, []);

    return (<Content headerTitle='Emissão de NFE'>
        <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
        <InvoiceDetails isOpen={openInvoiceDetails} setModalOpen={() => setOpenInvoiceDetails(!openInvoiceDetails)} invoiceId={invoiceId} setUpdateProducts={() => getInvoiceByStatus(page + 1, perPage)} viewMode={viewMode} setUpdateLogistic={setUpdateLogistic}></InvoiceDetails>
        <Card title='Cadastro de Produtos - Emissão de NFE'>
            <PrimeTable
                columns={columns}
                rows={data}
                serverPagination={true}
                handlePagination={handlePaginationPrime}
                handleRowPerPage={handleRowPerPagePrime}
                count={paginationTotalRows}
                page={page}
                rowsPerPage={perPage}
                loading={loading}
            ></PrimeTable>
        </Card>
    </Content>)
}