import React, { useContext, useEffect, useState } from 'react';
import Modal from '../Layout/Modal';
import { addExternalUser } from '../../services/Chat';
import { ChatContext } from './MainChatComponent';
import { toast } from 'react-toastify';
import { getTicketById } from '../../services/Ticket';
import useGenerics from '../../hooks/useGenerics';

export default function ExternalContacts({ title = '', isOpen = false, setModalOpen = null }) {
    const INITIAL_STATE = {
        name: '',
        email: ''
    }

    const { getCurrentDate } = useGenerics();
    const [formData, setFormData] = useState(INITIAL_STATE);
    const { chatState, setChatState } = useContext(ChatContext);

    async function addUser(e){
        e.preventDefault();

        let ticketData = false;
        let date = getCurrentDate();

        const toastAdd = toast.loading("Enviando email, aguarde...");

        if(!['', null].includes(chatState?.channelData?.ticket_id)){
            ticketData = await getTicketData();

            let ticketDateAgendamento = ticketData?.data_agendamento?.split("/");

            let temporaryDate = new Date(ticketDateAgendamento[2], (parseInt(ticketDateAgendamento[1]) - 1), (parseInt(ticketDateAgendamento[0]) + 1)).toLocaleDateString('pt-BR').split("/");
            date = temporaryDate[2] + "-" + temporaryDate[1].padStart(2, '0') + "-" + temporaryDate[0].padStart(2, '0');
        }

        let params = {
            ...formData,
            expire_in: date
        }

        let dataReturn = await addExternalUser(chatState?.channelId, params);
        if(dataReturn?.data?.response === 'success'){
            toast.update(toastAdd, { render: "Email enviado!", type: "success", isLoading: false, autoClose: 1500 });
            delete dataReturn?.data?.data?.users;
            setChatState((prevState) => ({
                ...prevState,
                channelData: {
                    ...prevState.channelData,
                    ...dataReturn?.data?.data
                },
                externalMembers: dataReturn?.data?.data?.external_users
            }))
        
        }else if(dataReturn?.data?.errors?.message === 'E-mail já existe no canal'){
            toast.update(toastAdd, { render: "Este email já está cadastrado!", type: "warning", isLoading: false, autoClose: 1500 });
        }else{
            toast.update(toastAdd, { render: "Ocorreu um erro ao enviar o email!", type: "warning", isLoading: false, autoClose: 1500 });
        }

        setFormData(INITIAL_STATE);
    
    }

    async function getTicketData(){
        let dataReturn = await getTicketById(chatState?.channelData?.ticket_id);

        if(dataReturn?.data?.response === 'success'){
            return dataReturn?.data?.data;
        }else{
            return false;
        }
    }

    useEffect(()=>{
        if(isOpen){
            setFormData(INITIAL_STATE);
        }
    },[isOpen]);

    return (
        <Modal
            title={title}
            isOpen={isOpen}
            setModalOpen={setModalOpen}>
            <form onSubmit={addUser}>
                <div className="row">
                    <div className="col-12">
                        <label htmlFor="name">Nome do Participante</label>
                        <input type="text" name="name" id="name" className="form-control" placeholder='...' value={formData?.name} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </div>
                    <div className="col-12">
                        <label htmlFor="email">Email do Participante</label>
                        <input type="email" name="email" id="email" className="form-control" placeholder='...' value={formData?.email} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </div>
                    <div className="col-12 mt-1">
                        <button type="submit" className="btn btn-success">Enviar Email</button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}