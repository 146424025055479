import React, { useState } from 'react';
import Content from '../../../common/Layout/Content';
import Card from '../../../common/Layout/Card';
import { Link } from 'react-router-dom';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import { getSummary } from '../../../services/EmployeeExpense';
import { toast } from 'react-toastify';
import RepresentanteSelect from '../../../common/Selects/RepresentanteSelect';
import useGenerics from '../../../hooks/useGenerics';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';

export default function ShowRefund() {
    const INITIAL_STATE = {
        semana: '',
        data_inicial: '',
        data_final: '',
        id_representante: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);
    const [reembolsoTableData, setReembolsoTableData] = useState([]);
    const { convertWeekToDate } = useGenerics();
    const columns = [
        {
            id: 'action_reembolso',
            name: 'Ver',
            body: (row) => (
                <Link to={`/faturamento/reembolso/detalhes/${row?.id_colaborador_reembolso}?data_inicial=${formData?.data_inicial}&data_final=${formData?.data_final}`}><i className='fas fa-eye'></i></Link>
            ),
        },
        {
            id: 'nome_reembolso',
            name: 'Nome'
        },
        {
            id: 'cpf_cnpj_reembolso',
            name: 'CPF/CNPJ',
        },
        {
            id: 'banco_reembolso',
            name: 'Banco',
        },
        {
            id: 'agencia_reembolso',
            name: 'Agência',
        },
        {
            id: 'tipo_conta_reembolso',
            name: 'Tipo de Conta',
        },
        {
            id: 'numero_titular_reembolso',
            name: 'Número Titular',
        },
        {
            id: 'nome_titular_reembolso',
            name: 'Nome Titular',
        },
        {
            id: 'cpf_titular_reembolso',
            name: 'CPF Titular',
        },
        {
            id: 'total_pendente_reembolso',
            name: 'Valor Total Pendente',
        },
        {
            id: 'total_aprovado_reembolso',
            name: 'Valor Total Aprovado',
        },
        {
            id: 'total_rejeitado_reembolso',
            name: 'Valor Total Rejeitado',
        },
        {
            id: 'total_cancelado_reembolso',
            name: 'Valor Total Cancelado',
        },
    ]

    async function getSummariesByDate(e) {
        e.preventDefault();

        const toastForm = toast.loading("Carregando dados, aguarde...");
        let dataReturn = await getSummary(formData?.data_inicial, formData?.data_final, formData?.id_representante?.value);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastForm, { render: 'Dados encontrados!', type: "success", isLoading: false, autoClose: 1500 });

            let data = dataReturn?.data?.data?.map((item, idx) => ({
                id_colaborador_reembolso: item?.id_colaborador || '',
                nome_reembolso: item?.colaborador?.nome_tec || '',
                cpf_cnpj_reembolso: item?.colaborador?.cpf_cnpj || '',
                banco_reembolso: item?.colaborador?.banco || '',
                agencia_reembolso: item?.colaborador?.agencia_banco || '',
                tipo_conta_reembolso: item?.colaborador?.tipo_conta_banco || '',
                numero_titular_reembolso: item?.colaborador?.num_conta_banco || '',
                cpf_titular_reembolso: item?.colaborador?.cpf_titular2 || '',
                nome_titular_reembolso: item?.colaborador?.titular_banco2 || '',
                total_pendente_reembolso: `R$ ${item?.total_pendente || 0.00}`,
                total_aprovado_reembolso: `R$ ${item?.total_aprovado || 0.00}`,
                total_cancelado_reembolso: `R$ ${item?.total_cancelado || 0.00}`,
                total_rejeitado_reembolso: `R$ ${item?.total_rejeitado || 0.00}`,
            }));
            setReembolsoTableData(data);
        } else {
            toast.update(toastForm, { render: 'Ocorreu um erro ao buscar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setReembolsoTableData([]);
        }
    }

    function convertWeek(week) {

        let weekDate = convertWeekToDate(week);

        setFormData(prevState => ({ ...prevState, semana: week, data_inicial: weekDate.startOfWeek, data_final: weekDate.endOfWeek }));
    }

    return (
        <Content headerTitle='Reembolso'>
            <SubHeaderSpecific subHeaderConfig='mam_tecnicos'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <form onSubmit={getSummariesByDate}>
                    <div className='row'>
                        <div className='col-12'>
                            <h4>Filtro</h4>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <label htmlFor='semana'>Semana</label>
                            <input type="week" className='form-control' name='semana' id='semana' value={formData?.semana} onChange={(e) => (convertWeek(e.target.value))} required></input>
                        </div>
                        <RepresentanteSelect formData={formData} setFormData={setFormData} cols={[12, 4, 4, 4]} required={true} fieldName='id_representante' params={{ mode: 'date-range', ...formData }}></RepresentanteSelect>
                        <div className="col-sm-12 col-md-4 d-flex">
                            <button type="submit" className='btn btn-primary align-self-end mt-1'>Filtrar</button>
                        </div>
                    </div>
                </form>
                <PrimeTable
                    columns={columns}
                    rows={reembolsoTableData}
                ></PrimeTable>
            </Card>
        </Content>
    );
}