import React, { useEffect, useState } from 'react';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import { getShipmentsByDateAndStatus } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import ProductsReceivedDetails from './ProductReceivedDetails';
import useGenerics from '../../../../hooks/useGenerics';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';

export default function ProductsReceived() {
    const [data, setData] = useState([]);
    const [productReceivedId, setProductReceivedId] = useState('');
    const [openProductsReceivedDetails, setOpenProductsReceivedDetails] = useState(false);
    const { getCurrentDate } = useGenerics();

    const [loading, setLoading] = useState(true);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <button type="button" className="dropdown-item" onClick={() => openProductsReceivedModal(row.id_produto)}>Ver</button>
                    </div>
                </div>
            )
        },
        {
            id: 'nome_produto',
            name: 'Nome do Produto',
        },
        {
            id: 'tipo_produto',
            name: 'Tipo de Produto',
        },
        {
            id: 'serial_produto',
            name: 'Serial',
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
        },
        {
            id: 'codigo_rastreio_produto',
            name: 'Código de Rastreio',
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
        },
        {
            id: 'nfe_saida_produto',
            name: 'NFE Saída',
        },
        {
            id: 'data_entrega_produto',
            name: 'Data de Entrega',
        }
    ];

    async function getAwaitingWithdraw(page = 1, perPage = 10) {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");
        const data_inicial = getCurrentDate(0, 0, 0);
        const data_final = getCurrentDate(1);
        let dataReturn = await getShipmentsByDateAndStatus({ data_inicial: data_inicial, data_final: data_final, page: page, perPage: perPage });

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });

            let products = dataReturn?.data?.data?.data || [];

            let productData = products.map((item, idx) => ({
                id_produto: item?.id || '',
                tipo_produto: item?.product?.nome_produto || '',
                nome_produto: item?.product?.modelo || '',
                serial_produto: item?.product?.serial || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                nfe_saida_produto: item?.nfe_saida || '',
                codigo_rastreio_produto: item?.rastreamento || '',
                data_entrega_produto: item?.data_recebido || ''
            }));

            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setData([]);
        }
        setLoading(false);
    }

    function openProductsReceivedModal(id) {
        setProductReceivedId(id);
        setOpenProductsReceivedDetails(true);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getAwaitingWithdraw(newPage + 1, perPage);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getAwaitingWithdraw(1, rows);
    }

    useEffect(() => {
        getAwaitingWithdraw();
    }, []);

    return (<Content headerTitle='Produtos Recebidos'>
        <SubHeaderSpecific subHeaderConfig='mam_logistica'></SubHeaderSpecific>
        <ProductsReceivedDetails isOpen={openProductsReceivedDetails} setModalOpen={() => setOpenProductsReceivedDetails(!openProductsReceivedDetails)} productReceivedId={productReceivedId}></ProductsReceivedDetails>
        <Card title='Cadastro de Produtos - Produtos Recebidos'>
            <PrimeTable
                columns={columns}
                rows={data}
                serverPagination={true}
                handlePagination={handlePaginationPrime}
                handleRowPerPage={handleRowPerPagePrime}
                count={paginationTotalRows}
                page={page}
                rowsPerPage={perPage}
                loading={loading}
            ></PrimeTable>
        </Card>
    </Content>);
}