import React, { useEffect, useState } from 'react';
import { createProduct } from '../../../../services/Product';
import { getTechnicianById } from '../../../../services/Technician';
import { toast } from 'react-toastify';
import Modal from '../../../../common/Layout/Modal';
import GenericSelect from '../../../../common/Selects/GenericSelect';
import IntegradoraSelect from '../../../../common/Selects/IntegradoraSelect';
import FabricanteSelect from '../../../../common/Selects/FabricanteSelect';
import ContratosSelect from '../../../../common/Selects/ContratosSelect';
import ModuleOptionsSelect from '../../../../common/Selects/ModuleOptionsSelect';
import RepresentanteSelect from '../../../../common/Selects/RepresentanteSelect';
import TecnicosSelect from '../../../../common/Selects/TecnicosSelect';
import BoxSelect from '../../../../common/Selects/BoxSelect';
import ModelosSelect from '../../../../common/Selects/ModelosSelect';

export default function CreateProduct({ isOpen = false, setModalOpen = null, setUpdateProducts=null }) {
    const initialState = {
        nome_produto: '',
        serial: '',
        id_modelo: '',
        id_integradora: '',
        id_contrato: '',
        codigo_sap: '',
        valorado: '',
        nfe_entrada: '',
        data_nfe_entrada: '',
        peso_equipamento: '',
        preco_equipamento: '',
        mac: '',
        imei: '',
        id_status_produto: '',
        kit_spare: '',
        local: '',
        id_tecnico: '',
        tipo_tecnico: '',
        email: '',
        patrimonio: '',
        box: '',
        id_fabricante: '',
        id_corredor: '',
        id_estante: '',
        id_prateleira: '',
        id_representante: ''
    }

    const [formCadastroProduto, setFormCadastroProduto] = useState(initialState);

    async function insertProduct(e) {
        e.preventDefault();

        let formData = {
            ...formCadastroProduto,
            id_modelo: formCadastroProduto?.id_modelo?.value || '',
            id_integradora: formCadastroProduto.id_integradora?.value || '',
            id_contrato: formCadastroProduto.id_contrato?.value || '',
            valorado: formCadastroProduto.valorado?.value || '',
            id_status_produto: formCadastroProduto.id_status_produto?.value || '',
            kit_spare: formCadastroProduto.kit_spare?.value || '',
            local: formCadastroProduto.local?.value || '',
            id_tecnico: formCadastroProduto.id_tecnico?.value || '',
            box: formCadastroProduto?.box?.value || '',
            id_fabricante: formCadastroProduto.id_fabricante?.value || '',
            id_corredor: formCadastroProduto.id_corredor?.value || '',
            id_estante: formCadastroProduto.id_estante?.value || '',
            id_prateleira: formCadastroProduto.id_prateleira?.value || '',
            id_representante: formCadastroProduto.id_representante?.value || ''
        }

        const toastCreateProduct = toast.loading("Cadastrando produto, aguarde...");

        let dataReturn = await createProduct(formData);

        if (dataReturn?.data?.data?.message === 'Produto criado com sucesso!') {
            toast.update(toastCreateProduct, { render: 'Produto cadastrado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setFormCadastroProduto(initialState);
            setUpdateProducts();
        } else if (dataReturn?.data?.message === 'Já existe um produto com esse número de série.') {
            toast.update(toastCreateProduct, { render: 'Este serial já existe!', type: "warning", isLoading: false, autoClose: 1500 });
        } else {
            toast.update(toastCreateProduct, { render: 'Ocorreu um erro ao criar o produto!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function handleTechnician(id) {

        const toastTechnician = toast.loading('Procurando dados do técnico, aguarde...');

        let dataReturn = await getTechnicianById(id);

        if (dataReturn?.data?.response === 'success') {
            let data = dataReturn.data.data;
            let tipoTecnico = "Não Definido";

            if (data.id_representante !== null) {
                tipoTecnico = data.id_representante === 1 ? 'CLT' : 'Parceiro'
            }

            toast.update(toastTechnician, { render: 'Dados encontrados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setFormCadastroProduto(prevState => ({ ...prevState, email: data?.email_tec || '', tipo_tecnico: tipoTecnico }));
        } else {
            toast.update(toastTechnician, { render: 'Ocorreu um erro ao buscar dados do técnico', type: "warning", isLoading: false, autoClose: 1500 });
            setFormCadastroProduto(prevState => ({ ...prevState, email: '', tipo_tecnico: '' }));
        }
    }

    function handleFabricante(value, name){
        setFormCadastroProduto(prev => ({...prev, [name]: value, id_modelo: ''}));
    }

    function handleRepresentante(value, name){
        setFormCadastroProduto(prev => ({...prev, [name]: value, id_tecnico: '', email: '', tipo_tecnico: ''}))
    }

    function handleIntegradora(value, name){
        setFormCadastroProduto(prev => ({...prev, [name]: value, id_contrato: ''}));
    }

    function handleTecnico(value, name){
        if(!['', undefined, null].includes(value?.value)){
            setFormCadastroProduto(prev => ({...prev, [name]: value}));
            handleTechnician(value?.value);
        }else{
            setFormCadastroProduto(prev => ({...prev, [name]: value, email: '', tipo_tecnico: ''}))
        }
    }

    function handleLocal(value, name){
        setFormCadastroProduto(prev => ({...prev, [name]: value, box: ''}))
    }

    useEffect(()=>{
        if(isOpen){
            setFormCadastroProduto(initialState); 
        }
    },[isOpen])

    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={setModalOpen}
            title='Adicionar Produto'
            customStyle={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90vw',
                height: '95vh',
            }}
        >
            <form onSubmit={insertProduct}>
                <div className='row'>
                    <div className="col-md-4 col-sm-12">
                        <h5>Informações Básicas</h5>
                        <div className="row">
                            <FabricanteSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName='id_fabricante' title='Fabricante' advancedSetFormData={handleFabricante} required={true}></FabricanteSelect>
                            <ModelosSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName='id_modelo' id_fabricante={formCadastroProduto?.id_fabricante?.value || ''} required={true}></ModelosSelect>
                            <div className="col-12">
                                <label htmlFor="nomeProduto">Tipo de Produto</label>
                                <input type="text" name="nome_produto" id="nomeProduto" className="form-control" placeholder="Tipo do Produto" value={formCadastroProduto?.nome_produto || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                            </div>
                            <div className="col-12">
                                <label htmlFor="serialProduto">Serial</label>
                                <input type="text" name="serial" id="serialProduto" className="form-control" placeholder="Serial" value={formCadastroProduto?.serial || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                            </div>
                            <div className="col-12">
                                <label htmlFor="macProduto">MAC</label>
                                <input type="text" name="mac" id="macProduto" className="form-control" placeholder="MAC" value={formCadastroProduto?.mac || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                            </div>
                            <div className="col-12">
                                <label htmlFor="imeiProduto">IMEI</label>
                                <input type="text" name="imei" id="imeiProduto" className="form-control" placeholder="IMEI" value={formCadastroProduto?.imei || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                            </div>
                            <div className="col-12">
                                <label htmlFor="patrimonioProduto">Patrimônio</label>
                                <input type="text" name="patrimonio" id="patrimonioProduto" className="form-control" placeholder="Patrimônio" value={formCadastroProduto?.patrimonio || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <h5>Informações de Entrada</h5>
                        <div className="row">
                            <div className="col-12">
                                <label htmlFor="codigoSapProduto">Código SAP</label>
                                <input type="text" name="codigo_sap" id="codigoSapProduto" className="form-control" placeholder="Código SAP" value={formCadastroProduto?.codigo_sap || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                            </div>
                            <div className="col-12">
                                <label htmlFor="nfeEntradaProduto">NFe de Entrada</label>
                                <input type="text" name="nfe_entrada" id="nfeEntradaProduto" className="form-control" placeholder="NFe de Entrada" value={formCadastroProduto?.nfe_entrada || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                            </div>
                            <div className="col-12">
                                <label htmlFor="dataNfeProduto">Data da NFe de Entrada</label>
                                <input type="date" name="data_nfe_entrada" id="dataNfeProduto" className="form-control" value={formCadastroProduto?.data_nfe_entrada || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                            </div>
                            <div className="col-12">
                                <label htmlFor="pesoEquipamentoProduto">Peso do Equipamento (KG)</label>
                                <input type="number" min="0" step="0.01" name="peso_equipamento" id="pesoEquipamentoProduto" className="form-control" placeholder="Peso do Equipamento" value={formCadastroProduto?.peso_equipamento || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                            </div>
                            <div className="col-12">
                                <label htmlFor="precoEquipamentoProduto">Preço do Equipamento</label>
                                <input type="number" min="0" step="0.01" name="preco_equipamento" id="precoEquipamentoProduto" className="form-control" placeholder="Preço do Equipamento" value={formCadastroProduto?.preco_equipamento || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                            </div>
                            <div className="col-12">
                                <label htmlFor="invoice">Invoice</label>
                                <input type="text" name="invoice" id="invoice" className="form-control" placeholder="Invoice" value={formCadastroProduto?.invoice || ''} onChange={(e) => setFormCadastroProduto(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <h5>Outras Informações</h5>
                        <GenericSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName={'kit_spare'} title={'Kit Spare'}></GenericSelect>
                        <IntegradoraSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName='id_integradora' advancedSetFormData={handleIntegradora}></IntegradoraSelect>
                        <ContratosSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName='id_contrato' dependsOnIntegradora={true} id_integradora_string={[formCadastroProduto?.id_integradora?.value].toString()}></ContratosSelect>
                        <GenericSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName={'valorado'} title={'Valorado/Não Valorado'}></GenericSelect>
                    </div>
                </div>
                <hr></hr>
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <h5>Localização</h5>
                        <GenericSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} genericOption={'UfOptions'} fieldName={'local'} title={'Local (UF)'} advancedSetFormData={handleLocal}></GenericSelect>
                        <BoxSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName={'box'} uf={formCadastroProduto?.local?.value || ''}></BoxSelect>
                        <ModuleOptionsSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} field={'id_corredor'} fieldName={'id_corredor'} module={'produtos'} title={'Corredor'}></ModuleOptionsSelect>
                        <ModuleOptionsSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} field={'id_estante'} fieldName={'id_estante'} module={'produtos'} title={'Estante'}></ModuleOptionsSelect>
                        <ModuleOptionsSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} field={'id_prateleira'} fieldName={'id_prateleira'} module={'produtos'} title={'Prateleiras'}></ModuleOptionsSelect>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <h5>Técnico e Contato</h5>
                        <div className="row">
                            <RepresentanteSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName='id_representante' advancedSetFormData={handleRepresentante}></RepresentanteSelect>
                            <TecnicosSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} fieldName='id_tecnico' dependsOnRepresentante={true} id_representante={formCadastroProduto?.id_representante?.value || ''} advancedSetFormData={handleTecnico}></TecnicosSelect>
                            <div className="col-12">
                                <label htmlFor="tipoTecnicoProduto">Tipo de Técnico</label>
                                <input type="text" name="tipo_tecnico" id="tipoTecnicoProduto" className="form-control" placeholder="Tipo de Técnico" value={formCadastroProduto?.tipo_tecnico || ''} readOnly />
                            </div>
                            <div className="col-12">
                                <label htmlFor="emailProduto">Email</label>
                                <input type="text" name="email" id="emailProduto" className="form-control" placeholder="Email" value={formCadastroProduto?.email || ''} readOnly />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <h5>Status do Produto</h5>
                        <div className="row">
                            <ModuleOptionsSelect formData={formCadastroProduto} setFormData={setFormCadastroProduto} field={'id_status_produto'} fieldName={'id_status_produto'} module={'produtos'} title={'Status do Produto'} required={true}></ModuleOptionsSelect>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1">
                        <button type="submit" className='btn btn-secondary float-right'>Salvar</button>
                    </div>
                </div>
            </form>
        </Modal>);
}