import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import Card from '../../../common/Layout/Card';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { changeEmployeeStatusById, getAllEmployee } from '../../../services/Employee';
import { changeTechnicianCredentialById } from '../../../services/Technician';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';

export default function ShowTechnicians() {

    const { status } = useParams();
    const [techniciansData, setTechniciansData] = useState([]);
    const columns = [
        {
            id: 'action_tecnico',
            name: 'Ver',
            body: (row) => (
                <Link to={'/cadastros/tecnicos/detalhes/' + row.id_tecnico}><i className='fas fa-eye'></i></Link>
            ),
        },
        {
            id: 'nome_tecnico',
            name: 'Nome',
        },
        {
            id: 'representante_tecnico',
            name: 'Representante',
        },
        {
            id: 'rg_tecnico',
            name: 'RG',
        },
        {
            id: 'cpf_cnpj_tecnico',
            name: 'CPF/CNPJ',
        },
        {
            id: 'cidade_tecnico',
            name: 'Cidade',
        },
        {
            id: 'estado_tecnico',
            name: 'Estado',
        },
        {
            id: 'contato_tecnico',
            name: 'Contato',
        },
        {
            id: 'action_activate_deactivate_tecnico',
            name: 'Ativar/Desativar',
            body: (row) => (
                <div className='align-items-center'>
                    <button type="button" className='btn btn-primary' style={{ fontSize: '0.9rem' }} onClick={() => { showChangeTechnicianStatusSwal(row.id_tecnico, row.status_tecnico) }}>{row.status_tecnico === 'Ativo' ? 'Inativar' : 'Ativar'}</button>
                </div>
            )
        },
        {
            id: 'action_accredit_deaccredit_tecnico',
            name: 'Credenciar/Descredenciar',
            body: (row) => (
                <div className='align-items-center'>
                    <button type="button" className='btn btn-primary' style={{ fontSize: '0.9rem' }} onClick={() => { showChangeTechnicianCredentialSwal(row.id_tecnico, row.status_tecnico, row.credenciado_tecnico) }}>{row.credenciado_tecnico === 'Credenciado' ? 'Descredenciar' : 'Credenciar'}</button>
                </div>
            )
        }
    ];

    async function getTechnicians(status = 1) {
        const toastTechnicianData = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getAllEmployee({
            'ativo': status,
            'categoria': ['Técnico'],
            'modal_contratacao': ['CLT', 'PJ']
        });

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastTechnicianData, { render: 'Dados Carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });

            let data = dataReturn?.data?.data.map((item, idx) => {

                let partners = item.partners.map((item, idx) => {
                    return item.nome_representante;
                });
                return ({
                    id_tecnico: item?.id || '',
                    nome_tecnico: item?.nome_tec || '',
                    representante_tecnico: partners.join(', ') || '',
                    rg_tecnico: `${item?.rg_tec || ''} ${item?.rg_tec_orgao_expeditor || ''}`,
                    cpf_cnpj_tecnico: item?.cpf_cnpj || '',
                    cidade_tecnico: item?.cidade_end || '',
                    estado_tecnico: item?.estado_end || '',
                    contato_tecnico: `${item?.nome_tec || ''}\n ${item?.email_tec} | ${item?.email_tec_pessoal}\n ${item?.celular_tec} | ${item?.celular_tec_pessoal}\n ${item?.telefone_recados} | ${item?.telefone_fixo_tec}`,
                    status_tecnico: item?.ativo === 1 ? 'Ativo' : 'Inativo',
                    credenciado_tecnico: item?.credenciado === 1 ? 'Credenciado' : 'Descredenciado'
                })
            });

            setTechniciansData(data);
        } else {
            toast.update(toastTechnicianData, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setTechniciansData([]);
        }
    }

    async function showChangeTechnicianCredentialSwal(id, status, credenciado) {
        let response = await Swal.fire({
            titleText: 'Deseja realmente credenciar/descredenciar este técnico?',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não'
        })

        if (response.isConfirmed) {
            changeTechnicianByCredential(id, status, credenciado);
        }
    }

    async function changeTechnicianByCredential(id, status, credenciado) {

        const toastChangeStatus = toast.loading("Alterando credencial, aguarde...");

        let changeStatusData = credenciado === 'Credenciado' ? 0 : 1;

        let dataReturn = await changeTechnicianCredentialById(id, changeStatusData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastChangeStatus, { render: 'Credencial alterado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let statusData = status === 'Ativo' ? 1 : 0;
            getTechnicians(statusData);
        } else {
            toast.update(toastChangeStatus, { render: 'Ocorreu um erro ao alterar credencial!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function showChangeTechnicianStatusSwal(id, status) {
        let response = await Swal.fire({
            titleText: 'Deseja realmente alterar o status deste representante?',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não'
        })

        if (response.isConfirmed) {
            changeTechnicianByStatus(id, status);
        }
    }

    async function changeTechnicianByStatus(id, status) {

        const toastChangeStatus = toast.loading("Alterando status, aguarde...");

        let changeStatusData = status === 'Ativo' ? 0 : 1;

        let dataReturn = await changeEmployeeStatusById(id, changeStatusData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastChangeStatus, { render: 'Status alterado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let statusData = status === 'Ativo' ? 1 : 0;
            getTechnicians(statusData);
        } else {
            toast.update(toastChangeStatus, { render: 'Ocorreu um erro ao alterar o status!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(() => {
        let statusData = 1;

        if (status === 'inativo') {
            statusData = 0
        }

        getTechnicians(statusData);
    }, [status])

    return (
        <Content headerTitle="Técnicos">
            <SubHeaderSpecific subHeaderConfig='mam_cadastros'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className='row'>
                    <div className='col-12'>
                        <Link to="/cadastros/tecnicos/cadastrar" role='button' className='btn btn-primary m-1'>Cadastrar Técnico</Link>
                        <Link to="/cadastros/tecnicos/dados" role='button' className='btn btn-success m-1'>Técnicos Ativos</Link>
                        <Link to="/cadastros/tecnicos/dados/inativo" role='button' className='btn btn-warning m-1'>Técnicos Inativos</Link>
                    </div>
                </div>
                <PrimeTable
                    columns={columns}
                    rows={techniciansData}
                ></PrimeTable>
            </Card>
        </Content>
    )
}