import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getLogisticsInvolvementValues } from '../../services/Ticket';

export default function EnvolveLogisticaSelect({ formData, setFormData, fieldName = 'envolve_logistica', cols = [12, 12, 12, 12], isMulti=false, isDisabled=false, required=false, showNoOption = true }) {
    const [envolveLogisticaOptions, setEnvolveLogisticaOptions] = useState([]);

    const getLogisticsInvolvement = async () => {
        let dataReturn = await getLogisticsInvolvementValues();

        if (dataReturn?.data?.response === 'success') {
            let logisticsInvolvementValues = dataReturn?.data?.data;

            let logisticsInvolvementArray = logisticsInvolvementValues.map((item, idx) => {
                return ({ value: item.descricao, label: item.descricao });
            });

            logisticsInvolvementArray = !showNoOption ? logisticsInvolvementArray.filter(item => item.value !== 'Não') : logisticsInvolvementArray;

            setEnvolveLogisticaOptions(logisticsInvolvementArray);
        } else {
            setEnvolveLogisticaOptions([]);
        }
    }

    const handleSelectForm = (value, name) => {
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    useEffect(() => {
        getLogisticsInvolvement();
    }, [])

    return (
        <div className={`col-sm-${cols[0]} col-md-${cols[1]} col-lg-${cols[2]} col-xl-${cols[3]}`}>
            <label htmlFor={fieldName}>Envolve Logística (Envio ou Retirada de eq.)</label>
            <Select
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                placeholder="Selecione..."
                options={envolveLogisticaOptions}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                isMulti={isMulti}
                isDisabled={isDisabled}
                required={required}
            ></Select>
        </div>
    );
}