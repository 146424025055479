import React from 'react';
import Content from '../../../common/Layout/Content';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import { Link } from 'react-router-dom';
import Table from '../../../common/Layout/Table/Table';
import Card from '../../../common/Layout/Card';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';

export default function RetiradaManagement() {
    const columns = [
        {
            id: 'lote_retirada',
            name: 'Lote',
        },
        {
            id: 'chamado_id_vtv_retirada',
            name: 'Chamado ID_VTV',
        },
        {
            id: 'data_registro_retirada',
            name: 'Data de Registro Maminfo',
        },
        {
            id: 'agendamento_retirada',
            name: 'Agendamento',
        },
        {
            id: 'cliente_retirada',
            name: 'Cliente',
        },
        {
            id: 'tipo_visita_retirada',
            name: 'Tipo de Visita',
        },
        {
            id: 'tecnico_retirada',
            name: 'Técnico',
        },
        {
            id: 'status_retirada',
            name: 'Status',
        },
        {
            id: 'descricao_retirada',
            name: 'Descrição',
        },
        {
            id: 'action_retirada',
            name: 'Ação',
            body: (row) => (
                <Link to={'/chamados/detalhes-chamado/' + row.id}><i className='fas fa-eye'></i></Link>
            ),
        },
    ];

    return (
        <Content headerTitle='Gerenciar Acionamento de Retirada'>
            <SubHeaderSpecific subHeaderConfig='retirada'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className="row">
                    <div className="col-12">
                        <PrimeTable
                            columns={columns}
                            rows={[]}
                            id='gerenciar_retirada'
                        ></PrimeTable>
                    </div>
                </div>
            </Card>
        </Content>
    );
}