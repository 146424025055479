import React from 'react';
import AsyncSelect from 'react-select/async';
import { getTickets } from '../../services/Ticket';

export default function AsyncChamadosSelect({ formData = {}, setFormData = null, cols=[12, 12, 12, 12], fieldName = 'id_chamado', title = 'Chamados', required = false, isDisabled= false, isMulti = false, data_inicial = null, data_final = null, advancedSetFormData = null }) {

    async function getChamados(inputValue) {

        if(inputValue?.length < 4){
            return;
        }

        let params = {
            data_inicial: data_inicial || '2023-01-01',
            num_chamado: inputValue
        }

        let dataReturn = await getTickets(params);

        if(dataReturn?.data?.response === 'success'){
            return dataReturn?.data?.data?.tickets?.map((item, idx) => ({ value: item?.id || '', label: `${item?.num_chamado || ''} - ${item?.status_chamado || ''}`})) || [];
        }
    }

    const handleSelectForm = (value, name) => {
        if(advancedSetFormData !== null){
            advancedSetFormData(value, name);
        }else{
            setFormData(prevState => ({ ...prevState, [name]: value }));
        }
    }

    return (
        <div className={`col-sm-${cols[0]} col-md-${cols[1]} col-lg-${cols[2]} col-xl-${cols[3]}`}>
            <label htmlFor={fieldName}>{title}</label>
            <AsyncSelect
                id={fieldName}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 9999})
                }}
                menuPosition='fixed'
                name={fieldName}
                cacheOptions={true}
                loadOptions={getChamados}
                value={formData?.[fieldName] || ''}
                onChange={(e) => handleSelectForm(e, fieldName)}
                required={required}
                isDisabled={isDisabled}
                isMulti={isMulti}
                isClearable
                noOptionsMessage={() => ('Sem dados')}
                placeholder="Digite no mínimo 4 caracteres"
            ></AsyncSelect>
        </div>

    );
}