import React, { useEffect, useState, useContext } from 'react';
import Content from '../../../common/Layout/Content';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getTicketById, getHistoryByTicketId, generateChildTicketByTicketId, updateDataByTicketId, assignTicket, getEquipmentsByTicketIdAndStatus, assignCreatedTicket } from '../../../services/Ticket';
import FloatSearchButton from '../../../common/Layout/FloatSearchButton';
import { Context } from '../../../App';
import { toast } from 'react-toastify';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import usePermissions from '../../../hooks/usePermissions';
import './TicketDetails.css'
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import FloatChatButton from '../../../common/Chat/FloatChatButton';
import PendingExpenseTable from './PendingExpenseTable';
import AttachmentData from './AttachmentData';
import MapData from './MapData';
import ChamadosCardData from './ChamadosCardData';
import ClienteCardData from './ClienteCardData';
import SuporteAnalistaResponsavelCard from './SuporteAnalistaResponsavelCard';
import SenhaAtendimentoCard from './SenhaAtendimentoCard';
import NovaDataAgendamento from './NovaDataAgendamento';
import NovoNumeroRMACard from './NovoNumeroRMACard';
import FabricanteCard from './FabricanteCard';
import CircuitoCard from './CircuitoCard';
import NumeroChamadoCard from './NumeroChamadoCard';
import AtendimentoCard from './AtendimentoCard';
import StatusCard from './StatusCard';
import ValidarDocumentacaoCard from './ValidarDocumentacaoCard';
import NovoContratoCard from './NovoContratoCard';
import HistoricoBacklogCard from './HistoricoBacklogCard';
import HistoricoStatusCard from './HistoricoStatusCard';
import EquipamentoRetornoCard from './EquipamentoRetornoCard';
import RegistroTrabalhoCard from './RegistroTrabalhoCard';
import DadosClienteCard from './DadosClienteCard';
import If from '../../../common/Layout/If';
import NovoKmCard from './NovoKmCard';
import DadosTecnicoCard from './DadosTecnicoCard';
import DetalhesAcionamentoCard from './DetalhesAcionamentoCard';
import EquipamentoRemessaCard from './EquipamentoRemessaCard/EquipamentoRemessaCard';

export default function TicketDetails() {
    const { checkPermissionGroup } = usePermissions();

    const initialState = {
        id_representante: '',
        id_tecnico: '',
        id_contrato: '',
        id_cliente: '',
        id_usuario: '',
        chamado: '',
        num_chamado: '',
        id_ticket_cliente: '',
        tipo_atividade: '',
        urgencia: '',
        prioridade: '',
        envolve_logistica: '',
        informacao_adicional: '',
        info_adicional_circuito: '',
        fabricante: '',
        fabricantes: '',
        status_chamado: '',
        nome_solicitante: '',
        status_tec_atendimento: '',
        data_solicitacao: '',
        hora_solicitacao: '',
        data_agendamento: '',
        hora_agendamento: '',
        descricao: '',
        numero_rma: '',
        km_tecnico: '',
        data_registro: '',
        data_modificacao: '',
        tecnico_proj_edc: '',
        documentacao_entregue_url_tecnico: '',
        documentacao_entregue_url: '',
        motivo_liberar_pagamento: '',
        tipo_atividade_select: '',
        status_chamado_select: '',
        prioridade_select: '',
        urgencia_select: '',
        documentacao_entregue: '',
        documentacao_entregue_select: '',
        liberar_pagamento: '',
        liberar_pagamento_select: '',
        nota_validacao_documento: '',
        nota_historico_fechamento: '',
        nota_externa_cliente: '',
        nota_chamado: '',
        data_inicio_atendimento: '',
        hora_inicio_atendimento: '',
        data_encerramento: '',
        hora_encerramento: '',
        senha_integradora: '',
        nome_analista_integracao: '',
        tel_contato_analista_integracao: '',
        documento_matricula: '',
        ocasiao_fechamento_select: '',
        motivo_improdutivo_select: '',
        ocasiao_fechamento: '',
        motivo_improdutivo: '',
        justificativa_ocasiao_fechamento: '',
        descricao_fechamento: ''
    }

    const { id } = useParams();
    const location = useLocation();
    const userData = useContext(Context);

    const [originalData, setOriginalData] = useState(initialState);
    const [detailsData, setDetailsData] = useState(initialState);
    const [equipmentsData, setEquipmentsData] = useState([]);

    const [historico, setHistorico] = useState('');
    const [historicoTecnico, setHistoricoTecnico] = useState('');
    const [historicoExternoCliente, setHistoricoExternoCliente] = useState('');
    const [imgArray, setImgArray] = useState([]);
    const [imgThumbArray, setImgThumbArray] = useState([]);

    const [showUpdateBtn, setShowUpdateBtn] = useState(false);
    const [showAlterarClienteBtn, setShowAlterarClienteBtn] = useState(false);
    const [showAlterarTecnicoBtn, setShowAlterarTecnicoBtn] = useState(false);
    const [showAlterarKmBtn, setShowAlterarKmBtn] = useState(false);
    const [showAlterarNumeroChamadoBtn, setShowAlterarNumeroChamadoBtn] = useState(false);
    const [showAlterarCircuitoBtn, setShowAlterarCircuitoBtn] = useState(false);
    const [showFabricanteBtn, setShowFabricanteBtn] = useState(false);
    const [showContratoBtn, setShowContratoBtn] = useState(false);
    const [showNumeroRmaBtn, setShowNumeroRmaBtn] = useState(false);
    const [showNovaDataAgendamentoBtn, setShowNovaDataAgendamentoBtn] = useState(false);
    const [showAddEquipamentoRetorno, setShowAddEquipamentoRetorno] = useState(false);

    const [floatChatOpened, setFloatChatOpened] = useState(false);
    const [channelId, setChannelId] = useState('');

    const [updateAttachmentData, setUpdateAttachmentData] = useState(false);

    const mySwal = withReactContent(Swal);

    const navigate = useNavigate();

    const handleSetUpdateAttachmentData = (value) => {
        setUpdateAttachmentData(value);
    }

    function handleScrollToViewById(idToScroll) {
        let element = document.getElementById(idToScroll);

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const getEquipmentsByIdStatus = async () => {
        let dataReturn = await getEquipmentsByTicketIdAndStatus(id, 'Remessa');
        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            let dadosEquipamentos = dataReturn.data.data;
            let dadosEquipamentosArray = [];

            dadosEquipamentosArray = dadosEquipamentos.map((item, idx) => {
                return ({
                    ...item,
                    id_produto: item?.id_produto || '',
                    id_remessa: item?.shipment?.id || '',
                    id_equipamento_chamado: item?.id || '',
                    tipo_produto_remessa: item?.product?.nome_produto || '',
                    fabricante_remessa: item?.partnumber?.manufacturer?.nome_fabricante || item?.product?.partnumber?.manufacturer?.nome_fabricante || '',
                    part_number_equipamento_remessa: item?.partnumber?.modelo || item?.product?.partnumber?.modelo || '',
                    serial_equipamento_remessa: item?.product?.serial || '',
                    status_transportadora_equipamento_remessa: item?.status_entrega_remessa || '',
                    modal_envio_equipamento_remessa: item?.modal_envio || '',
                    data_postagem_equipamento_remessa: item?.data_postagem_remessa || '',
                    rastreamento_equipamento_remessa: item?.rastreamento || '',
                    status_remessa: item?.status_remessa || '',
                    prazo_entrega_equipamento_remessa: item?.prazo_entrega || ''
                });
            });

            setEquipmentsData(dadosEquipamentosArray);

        } else {
            setEquipmentsData([]);
        }
    }

    async function getTicketDetailById() {
        let dataReturn = await getTicketById(id);
        if (dataReturn?.data?.response === 'success') {
            let detailsData = dataReturn?.data?.data;
            let cliente = detailsData?.customer;
            let representante = detailsData?.partner;
            let tecnico = detailsData?.technician;
            let enderecoPartida = detailsData?.start_address;
            let contrato = detailsData?.contract;
            let integradora = contrato?.integrator;
            let valoresComerciais = detailsData?.partner_commercial_value;
            let valoresEspeciais = detailsData?.special_partner_value;

            let dataAgendamento = detailsData?.data_agendamento.split("/") || '';
            let dataInicioAtendimento = detailsData?.data_inicio_atendimento?.split("/") || '';
            let escopo = '';

            if (valoresComerciais?.contract_value?.escopo !== '') {
                escopo = valoresComerciais?.contract_value?.escopo;
            }

            if (valoresEspeciais?.partner_value?.contract_value?.escopo !== '') {
                escopo = valoresEspeciais?.partner_value?.contract_value?.escopo;
            }

            setOriginalData(prevState => ({
                ...prevState,
                ...detailsData,
                escopo_valores: escopo
            }));

            setDetailsData(prevState => (
                {
                    ...prevState,
                    ...detailsData,
                    escopo_valores: escopo,
                    data_agendamento: dataAgendamento === '' ? '' : dataAgendamento[2] + "-" + dataAgendamento[1].padStart(2, '0') + "-" + dataAgendamento[0].padStart(2, '0'),
                    data_inicio_atendimento: dataInicioAtendimento === '' ? '' : dataInicioAtendimento[2] + "-" + dataInicioAtendimento[1].padStart(2, '0') + "-" + dataInicioAtendimento[0].padStart(2, '0'),
                    tipo_atividade: { value: detailsData.tipo_atividade, label: detailsData.tipo_atividade },
                    status_chamado: { value: detailsData.status_chamado, label: detailsData.status_chamado },
                    urgencia: { value: detailsData.urgencia, label: detailsData.urgencia },
                    prioridade: { value: detailsData.prioridade, label: detailsData.prioridade },
                    documentacao_entregue_select: { value: detailsData.documentacao_entregue, label: detailsData.documentacao_entregue },
                    liberar_pagamento_select: { value: detailsData.liberar_pagamento, label: detailsData.liberar_pagamento },
                    ocasiao_fechamento: { value: detailsData?.ocasiao_fechamento, label: detailsData?.ocasiao_fechamento },
                    motivo_improdutivo_select: { value: detailsData?.motivo_improdutivo, label: detailsData?.motivo_improdutivo },
                    id_contrato: { value: contrato?.id, label: contrato?.contrato },
                    id_integradora: { value: integradora?.id, label: integradora?.nome },
                    hora_inicio_atendimento: detailsData?.hora_inicio_atendimento,
                    data_encerramento: detailsData?.data_encerramento,
                    hora_encerramento: detailsData?.hora_encerramento,
                    senha_integradora: detailsData?.senha_integradora,
                    tel_contato_analista_integracao: detailsData?.tel_contato_analista_integracao,
                    nome_analista_integracao: detailsData?.nome_analista_integracao,
                    documento_matricula: detailsData?.documento_matricula,
                    justificativa_ocasiao_fechamento: detailsData?.justificativa_ocasiao_fechamento,
                    descricao_fechamento: detailsData?.descricao_fechamento,
                    id_cliente: cliente?.id,
                    cep_endereco_cliente: cliente?.cep_cliente,
                    estado_endereco_cliente: cliente?.estado_cliente,
                    cidade_endereco_cliente: cliente?.cidade_cliente,
                    bairro_endereco_cliente: cliente?.bairro_cliente,
                    numero_complemento: cliente?.compl_ref_obs_endereco_cliente,
                    nome_cliente: cliente?.nome_cliente,
                    nome_contato_cliente: cliente?.nome_contato_cliente,
                    telefone_contato_cliente: cliente?.telefone_contato_cliente,
                    email_contato_cliente: cliente?.email_contato_cliente,
                    endereco_cliente: cliente?.endereco_cliente,
                    id_tecnico: { value: tecnico?.id, label: tecnico?.nome_tec },
                    id_representante: { value: representante?.id, label: representante?.nome_representante },
                    id_representante_valor_comercial: { value: valoresComerciais?.id, label: valoresComerciais?.contract_value?.escopo },
                    id_valores_especiais: valoresEspeciais?.id || '',
                    escopo_valores_especiais: valoresEspeciais?.partner_value?.contract_value?.escopo || '',
                    ...enderecoPartida
                }
            ));

        }
    }

    async function setEmail() {

        let dataReturn = await getHistoryByTicketId(id);
        let descricaoHistorico = '';
        if (dataReturn?.data?.response === "success" && dataReturn?.data?.data.length > 0) {
            descricaoHistorico = dataReturn?.data?.data[dataReturn?.data?.data.length - 1]['descricao']; //Inserir replace
            descricaoHistorico = descricaoHistorico.replace(/(\r\n|\n|\r)/g, ' %0D%0A ');
        }

        let body = `Nº Chamado: ${detailsData?.chamado} %0D%0A
Cliente: ${detailsData?.customer?.nome_cliente} %0D%0A
Agendamento: ${detailsData?.data_agendamento} ${detailsData?.hora_agendamento} %0D%0A
Integradora: ${detailsData?.contract?.integrator?.nome_fantasia} %0D%0A
Representante: ${detailsData?.technician?.partner?.nome_representante} %0D%0A
Técnico: ${detailsData?.technician?.nome_tec} %0D%0A %0D%0A`;

        //Condicional Logistica
        if (equipmentsData.length > 0) {
            body += `
Equipamentos: %0D%0A`;
            let equipamentos = equipmentsData.map((item => {
                return `Logística ${item?.tipo_remessa || ''}: %0D%0A
Destinatário:  ${item?.destinatario || ''} %0D%0A
Endereço: ${item?.rua || ''} ${item?.numero || ''} %0D%0A
Bairro: ${item?.bairro || ''} %0D%0A
Cidade: ${item?.cidade || ''} %0D%0A
Estado ${item?.uf || ''} %0D%0A
CEP: ${item?.cep || ''} %0D%0A
OBS: ${item?.complemento || ''} %0D%0A                
Nome do Produto: ${item?.part_number_equipamento_remessa || ''} %0D%0A
Serial: ${item?.serial_equipamento_remessa || ''} %0D%0A
Rastreio: ${item?.rastreamento_equipamento_remessa || ''} %0D%0A
Modal: ${item?.modal_envio_equipamento_remessa || ''} %0D%0A %0D%0A`
            }));

            for (let e of equipamentos) {
                body += e;
            }
        }

        body += `Endereço Cliente: ${detailsData?.customer?.endereco_cliente} %0D%0A
Bairro: ${detailsData?.customer?.bairro_cliente} %0D%0A
Cidade: ${detailsData?.customer?.cidade_cliente} %0D%0A
Estado ${detailsData?.customer?.estado_cliente} %0D%0A
CEP: ${detailsData?.customer?.cep_cliente} %0D%0A
OBS: ${detailsData?.customer?.compl_ref_obs_endereco_cliente} %0D%0A
Descrição: ${detailsData?.descricao} %0D%0A ${descricaoHistorico} `;

        body = body.replace(/\?|&|,/g, '');
        body = body.replace(/"/g, "''");

        window.location.href = `mailto:atendimento@maminfo.com.br,${detailsData?.technician?.email_tec || ''}?subject=Abertura de Chamado Nº ${detailsData?.chamado} - Maminfo&body=${body}`
    }

    async function getHistory() {
        let divisor = '---------------------------------------------------------------------------------------------------------------------------------------------------------\n';
        let historico = "";
        let historicoExternoCliente = "";
        let historicoTecnico = "";
        let dataReturn = await getHistoryByTicketId(id);

        if (dataReturn?.data?.response === "success" && dataReturn?.data?.data.length > 0) {
            let historicoArray = dataReturn?.data?.data;
            for (let h of historicoArray) {
                let historicoParcial = "";
                historicoParcial += divisor;

                if (h.nota_externa_cliente === 1) {
                    historicoParcial += "**** Nota Externa Cliente ****\n";
                }

                historicoParcial += h.data_registro + " - " + h.login + " - " + h.nome + " - " + h.email + " - " + h.telefone + "\n";

                if (h.nota_tecnico === 0) {
                    historicoParcial += "Status: " + h.status + "\n"
                }

                if (h.status === "Fechado") {
                    historicoParcial += "Solução de Fechamento: " + h.descricao + "\n"
                } else {
                    historicoParcial += "Nota: " + h.descricao + "\n"
                }

                historico += historicoParcial;

                if (h.nota_externa_cliente === 1) {
                    historicoExternoCliente += historicoParcial;
                }

                if (h.nota_tecnico === 1) {
                    historicoTecnico += historicoParcial;
                }
            }

            setHistorico(historico);
            historicoExternoCliente = historicoExternoCliente.replace(/\*\*\*\* Nota Externa Cliente \*\*\*\*\n/g, "");
            setHistoricoExternoCliente(historicoExternoCliente);
            setHistoricoTecnico(historicoTecnico);
        }
    }

    async function generateChildTicket() {
        let response = await mySwal.fire({
            titleText: `Chamado Filho!`,
            text: `Você deseja mesmo gerar um chamado filho?`,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonColor: 'red',
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
        });

        if (response.isConfirmed) {
            const toastChildTicket = toast.loading("Gerando chamado filho, aguarde...");

            let dataReturn = await generateChildTicketByTicketId(id);

            if (dataReturn?.data?.response === 'success') {
                let chamado = dataReturn?.data?.data;
                toast.update(toastChildTicket, { render: 'Chamado filho gerado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
                goToChildTicket(chamado);
            } else {
                toast.update(toastChildTicket, { render: 'Ocorreu um erro ao gerar o chamado filho!', type: "warning", isLoading: false, autoClose: 1500 });
            }
        }
    }

    async function goToChildTicket(chamado) {
        if (navigator.clipboard && navigator.clipboard.writeText) {

            navigator.clipboard.writeText(chamado.num_chamado).then(() => {
                console.log('Texto copiado com sucesso!');
            }).catch((e) => {
                const textarea = document.createElement('textarea');
                textarea.value = chamado.num_chamado;
                document.body.appendChild(textarea);

                textarea.select();
                document.execCommand('copy');

                document.body.removeChild(textarea);
            });
        } else {

            console.warn('Clipboard API indisponível, usando método legado');
            const textarea = document.createElement('textarea');
            textarea.value = chamado.num_chamado;
            document.body.appendChild(textarea);

            textarea.select();
            document.execCommand('copy');

            document.body.removeChild(textarea);
        }

        let response = await mySwal.fire({
            titleText: `Chamado Filho Gerado com Sucesso!`,

            html: (
                <>
                    <h2>Ticket: {chamado.num_chamado}</h2>
                    <div style={{ textAlign: 'left' }}>
                        <p>Ticket copiado com sucesso!</p>
                        <p>Deseja sair do chamado atual e acessar os detalhes do chamado filho gerado?</p>
                    </div>
                </>),
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonColor: 'red',
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
        });

        if (response.isConfirmed) {
            navigate(`/chamados/detalhes-chamado/${chamado.id}`)
        }

    }

    async function updateTicket() {
        let formData = {
            ...detailsData,
            status_chamado: detailsData?.status_chamado?.value || '',
            data_agendamento: detailsData?.data_agendamento || '',
            hora_agendamento: detailsData?.hora_agendamento || '',
            id_integradora: detailsData?.id_integradora?.value || '',
            id_contrato: detailsData?.id_contrato?.value || '',
            id_representante: detailsData?.id_representante?.value || '',
            descricao: detailsData?.descricao || '',
            tipo_atividade: detailsData?.tipo_atividade?.value || '',
            km_tecnico: detailsData?.km_tecnico || '',
            num_chamado: detailsData?.num_chamado || '',
            fabricante: detailsData?.fabricantes || '',
            info_adicional_circuito: detailsData?.info_adicional_circuito || '',
            numero_rma: detailsData?.numero_rma || '',

            id_cliente: detailsData?.id_cliente || '',
            nome_cliente: detailsData?.nome_cliente || '',
            nome_contato_cliente: detailsData?.nome_contato_cliente || '',
            telefone_contato_cliente: detailsData?.telefone_contato_cliente || '',
            email_contato_cliente: detailsData?.email_contato_cliente || '',
            cep_endereco_cliente: detailsData?.cep_endereco_cliente || '',
            estado_endereco_cliente: detailsData?.estado_endereco_cliente || '',
            cidade_endereco_cliente: detailsData?.cidade_endereco_cliente || '',
            bairro_endereco_cliente: detailsData?.bairro_endereco_cliente || '',
            logradouro_endereco_cliente: detailsData?.endereco_cliente || '',
            compl_ref_obs_endereco_cliente: detailsData?.numero_complemento || '',

            id_tecnico: detailsData?.id_tecnico?.value || '',
            id_representante_valor_comercial: detailsData?.id_representante_valor_comercial?.value || '',

            logradouro_endereco_partida: detailsData?.logradouro_endereco_partida || '',
            compl_ref_obs_endereco_partida: detailsData?.compl_ref_obs_endereco_partida || '',
            cep_endereco_partida: detailsData?.cep_endereco_partida || '',
            bairro_endereco_partida: detailsData?.bairro_endereco_partida || '',
            estado_endereco_partida: detailsData?.estado_endereco_partida || '',
            cidade_endereco_partida: detailsData?.cidade_endereco_partida || '',

            urgencia: detailsData?.urgencia?.value || '',
            prioridade: detailsData?.prioridade?.value || '',

            data_inicio_atendimento: detailsData?.data_inicio_atendimento || '',
            hora_inicio_atendimento: detailsData?.hora_inicio_atendimento || '',
            data_encerramento: detailsData?.data_encerramento || '',
            hora_encerramento: detailsData?.hora_encerramento || '',
            senha_integradora: detailsData?.senha_integradora || '',
            tel_contato_analista_integracao: detailsData?.tel_contato_analista_integracao || '',
            nome_analista_integracao: detailsData?.nome_analista_integracao || '',
            documento_matricula: detailsData?.documento_matricula || '',
            ocasiao_fechamento: detailsData?.ocasiao_fechamento?.value || '',
            motivo_improdutivo: detailsData?.motivo_improdutivo?.value || '',
            justificativa_ocasiao_fechamento: detailsData?.justificativa_ocasiao_fechamento || '',
            descricao_fechamento: detailsData?.descricao_fechamento || '',
        }

        const toastUpdateTicket = toast.loading('Atualizando dados, aguarde...');

        let dataReturn = await updateDataByTicketId(id, formData);

        if (dataReturn?.data?.response === 'Ticket updated successfully!') {
            toast.update(toastUpdateTicket, { render: 'Dados atualizados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            updateAllData();
            resetBtns();

            if (detailsData?.nota_externa_cliente?.length > 0) {
                await Swal.fire("Você possui uma nota externa não salva!");
            }

            if (detailsData?.nota_chamado?.length > 0) {
                await Swal.fire("Você possui uma nota interna não salva!");
            }
        } else {
            toast.update(toastUpdateTicket, { render: 'Ocorreu um erro ao atualizar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    function updateAllData() {
        handleSetUpdateAttachmentData(true);
        getTicketDetailById();
        getHistory();
    }

    function resetBtns() {
        setShowUpdateBtn(false);
        setShowAlterarClienteBtn(false);
        setShowAlterarTecnicoBtn(false);
        setShowAlterarKmBtn(false);
        setShowAlterarNumeroChamadoBtn(false);
        setShowAlterarCircuitoBtn(false);
        setShowFabricanteBtn(false);
        setShowContratoBtn(false);
        setShowNumeroRmaBtn(false);
        setShowNovaDataAgendamentoBtn(false);
        setShowAddEquipamentoRetorno(false);
    }

    async function assignTicketByEmailClick() {
        
        setEmail();

        let formData = {
            ids_chamado: [detailsData?.id_chamado],
            id_responsavel: userData?.userDataState?.id_usuario || ''
        }

        let dataReturn = await assignCreatedTicket(formData);

        if (dataReturn?.data?.data?.message === 'Tickets atualizados com sucesso!') {
            updateAllData();
        } else {
            toast.warning("Ocorreu um erro ao atualizar o chamado!");
        }
    }

    useEffect(() => {
        updateAllData();
    }, [location]);

    useEffect(() => {
        if (showAlterarNumeroChamadoBtn !== false) {
            handleScrollToViewById("updateNumChamado");
        }
    }, [showAlterarNumeroChamadoBtn]);

    useEffect(() => {
        if (showAlterarCircuitoBtn !== false) {
            handleScrollToViewById("updateCircuito");
        }
    }, [showAlterarCircuitoBtn]);

    useEffect(() => {
        if (showAlterarClienteBtn !== false) {
            handleScrollToViewById("updateCustomerData");
        }
    }, [showAlterarClienteBtn]);

    useEffect(() => {
        if (showAlterarTecnicoBtn !== false) {
            handleScrollToViewById("updateTechnician");
        }
    }, [showAlterarTecnicoBtn]);

    useEffect(() => {
        if (showFabricanteBtn !== false) {
            handleScrollToViewById("updateManufacturer");
        }
    }, [showFabricanteBtn]);

    useEffect(() => {
        if (showNumeroRmaBtn !== false) {
            handleScrollToViewById("updateRmaNumber");
        }
    }, [showNumeroRmaBtn]);

    useEffect(() => {
        if (showContratoBtn !== false) {
            handleScrollToViewById("updateContract");
        }
    }, [showContratoBtn]);

    useEffect(() => {
        if (showNovaDataAgendamentoBtn !== false) {
            handleScrollToViewById("updateSchedule");
        }
    }, [showNovaDataAgendamentoBtn]);

    useEffect(() => {
        if (showAlterarKmBtn) {
            handleScrollToViewById("updateKm");
        }
    }, [showAlterarKmBtn])

    useEffect(() => {
        let statusChamado = detailsData?.status_chamado;

        if (["Fechado", "fechado"].includes(statusChamado)) {
            setShowNovaDataAgendamentoBtn(false);
            handleScrollToViewById("registroTrabalhoFechamento");
        }

        if (["Agendamento Sugerido (CLI)", "Agendamento Desejado (CLI)"].includes(statusChamado)) {
            setShowNovaDataAgendamentoBtn(true);
            handleScrollToViewById("updateSchedule");
        }
    }, [detailsData?.status_chamado]);

    return (
        <div>
            <Content headerTitle="Detalhes" showChat={false}>
                <SubHeaderSpecific subHeaderConfig='mam_chamados'></SubHeaderSpecific>
                <FloatSearchButton></FloatSearchButton>
                <FloatChatButton ticketId={originalData?.id} floatOpened={floatChatOpened} setFloatOpened={setFloatChatOpened} channelId={channelId} setChannelId={setChannelId} listenTo='ticket'></FloatChatButton>
                <If condition={showUpdateBtn}>
                    <button type='button' className='btn btn-primary' style={{ position: 'fixed', top: 225, right: 10, zIndex: 9999 }} onClick={updateTicket}>Salvar</button>
                </If>
                <div className='row mb-2'>
                    <div className="col-12">
                        <If condition={!(["Cancelado", "Fechado", "Aprovação do Responsável"].includes(originalData?.status_chamado) && userData?.userDataState?.permissoes.filter(item => ['administrador', 'acionamento_gestao'].includes(item.group_name)).length === 0)}>
                            <button className="btn btn-secondary float-left m-1" onClick={() => setShowUpdateBtn(prevState => !prevState)}>Alterar / Adicionar Nota</button>
                        </If>
                        <If condition={!(originalData?.status_chamado === "Aprovação do Responsável" || userData.userDataState.cliente === 1)}>
                            <a role='button' className="btn btn-primary float-right m-1" onClick={assignTicketByEmailClick}>Email</a>
                        </If>
                        <button className="btn btn-primary float-right m-1" onClick={generateChildTicket}>Gerar Chamado Filho</button>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-md-3">
                        <ChamadosCardData
                            handleScrollToViewById={handleScrollToViewById}
                            detailsData={detailsData}
                            originalData={originalData}
                            setDetailsData={setDetailsData}
                            setShowFabricanteBtn={setShowFabricanteBtn}
                            setShowAlterarNumeroChamadoBtn={setShowAlterarNumeroChamadoBtn}
                            setShowAlterarCircuitoBtn={setShowAlterarCircuitoBtn}
                            setShowContratoBtn={setShowContratoBtn}
                            setShowNumeroRmaBtn={setShowNumeroRmaBtn}
                            showUpdateBtn={showUpdateBtn}
                        >
                        </ChamadosCardData>
                    </div>
                    <div className='col-md-3'>
                        <ClienteCardData
                            originalData={originalData}
                            setShowAlterarClienteBtn={setShowAlterarClienteBtn}
                            showUpdateBtn={showUpdateBtn}
                        ></ClienteCardData>
                    </div>
                    <div className='col-md-3'>
                        <AtendimentoCard
                            detailsData={detailsData}
                            originalData={originalData}
                            setShowAlterarKmBtn={setShowAlterarKmBtn}
                            setShowAlterarTecnicoBtn={setShowAlterarTecnicoBtn}
                            setShowNovaDataAgendamentoBtn={setShowNovaDataAgendamentoBtn}
                            showUpdateBtn={showUpdateBtn}
                        ></AtendimentoCard>
                    </div>
                    <div className='col-md-3'>
                        <StatusCard
                            detailsData={detailsData}
                            originalData={originalData}
                            showUpdateBtn={showUpdateBtn}
                            setDetailsData={setDetailsData}
                        ></StatusCard>
                    </div>
                    <div className="col-12">
                        <DetalhesAcionamentoCard
                            detailsData={detailsData}
                            setDetailsData={setDetailsData}
                            showUpdateBtn={showUpdateBtn}
                        ></DetalhesAcionamentoCard>
                    </div>
                    <If condition={(userData.userDataState.cliente === 0 && ["Suporte interno (Fila)", "Suporte interno (Atendimento)"].includes(detailsData?.status_chamado?.value))}>
                        <div className="col-12">
                            <SuporteAnalistaResponsavelCard
                                id={id}
                                getHistory={getHistory}
                                originalData={originalData}
                                getTicketDetailById={getTicketDetailById}
                            ></SuporteAnalistaResponsavelCard>
                        </div>
                    </If>
                    <If condition={(userData.userDataState.cliente === 0)}>
                        <div className='col-12'>
                            <MapData id={id} originalData={originalData}></MapData>
                        </div>
                    </If>
                    <If condition={(originalData?.status_chamado === "Fechado" && userData?.userDataState?.cliente === 0 && checkPermissionGroup(['acionamento_operacao', 'analista_suporte', 'gerente_operacoes', 'acionamento_gestao', 'acionamento_supervisao']))}>
                        <div className="col-12">
                            <ValidarDocumentacaoCard detailsData={detailsData} id={id} originalData={originalData} setDetailsData={setDetailsData} updateAllData={updateAllData}></ValidarDocumentacaoCard>
                        </div>
                    </If>
                    <If condition={originalData?.status_chamado === "Fechado"}>
                        <div className="col-12">
                            <HistoricoBacklogCard
                                getHistory={getHistory}
                                originalData={originalData}
                                id={id}
                            ></HistoricoBacklogCard>
                        </div>
                    </If>
                    <If condition={showAlterarClienteBtn}>
                        <div className='col-12' id="updateCustomerData">
                            <DadosClienteCard
                                detailsData={detailsData}
                                setDetailsData={setDetailsData}
                            ></DadosClienteCard>
                        </div>
                    </If>
                    <If condition={showAlterarKmBtn}>
                        <div className="col-12" id="updateKm">
                            <NovoKmCard detailsData={detailsData} setDetailsData={setDetailsData}></NovoKmCard>
                        </div>
                    </If>
                    <If condition={(showAlterarTecnicoBtn)}>
                        <div className="col-12" id="updateTechnician">
                            <DadosTecnicoCard
                                detailsData={detailsData}
                                setDetailsData={setDetailsData}
                                showAlterarTecnicoBtn={showAlterarTecnicoBtn}
                            ></DadosTecnicoCard>
                        </div>
                    </If>
                    <If condition={showAlterarNumeroChamadoBtn}>
                        <div className="col-12" id='updateNumChamado'>
                            <NumeroChamadoCard detailsData={detailsData} setDetailsData={setDetailsData}></NumeroChamadoCard>
                        </div>
                    </If>
                    <If condition={showAlterarCircuitoBtn}>
                        <div className="col-12" id="updateCircuito">
                            <CircuitoCard detailsData={detailsData} setDetailsData={setDetailsData}></CircuitoCard>
                        </div>
                    </If>
                    <If condition={showFabricanteBtn}>
                        <div className="col-12" id="updateManufacturer">
                            <FabricanteCard detailsData={detailsData} setDetailsData={setDetailsData}></FabricanteCard>
                        </div>
                    </If>
                    <If condition={showContratoBtn}>
                        <div className="col-12" id="updateContract">
                            <NovoContratoCard detailsData={detailsData} setDetailsData={setDetailsData}></NovoContratoCard>
                        </div>
                    </If>
                    <If condition={showNumeroRmaBtn}>
                        <div className="col-12" id="updateRmaNumber">
                            <NovoNumeroRMACard detailsData={detailsData} setDetailsData={setDetailsData}></NovoNumeroRMACard>
                        </div>
                    </If>
                    <If condition={showNovaDataAgendamentoBtn}>
                        <div className="col-12" id="updateSchedule">
                            <NovaDataAgendamento detailsData={detailsData} setDetailsData={setDetailsData}></NovaDataAgendamento>
                        </div>
                    </If>
                    <div className='col-12'>
                        <RegistroTrabalhoCard
                            detailsData={detailsData}
                            getHistory={getHistory}
                            historico={historico}
                            historicoExternoCliente={historicoExternoCliente}
                            historicoTecnico={historicoTecnico}
                            originalData={originalData}
                            setChannelId={setChannelId}
                            setDetailsData={setDetailsData}
                            setFloatChatOpened={setFloatChatOpened}
                            id={id}
                            imgArray={imgArray}
                            imgThumbArray={imgThumbArray}
                        ></RegistroTrabalhoCard>
                    </div>
                    <div className="col-12">
                        <SenhaAtendimentoCard getHistory={getHistory} originalData={originalData} id={id}></SenhaAtendimentoCard>
                    </div>
                    <div className='col-12'>
                        <AttachmentData id={id} detailsData={detailsData} originalData={originalData} setImgArray={setImgArray} setImgThumbArray={setImgThumbArray} updateData={updateAttachmentData} setUpdateData={handleSetUpdateAttachmentData}></AttachmentData>
                    </div>
                    <If condition={(["Fechado"].includes(detailsData?.status_chamado?.value) && userData?.userDataState?.cliente === 0)}>
                        <div className="col-12">
                            <EquipamentoRetornoCard
                                id={id}
                                getHistory={getHistory}
                                setShowAddEquipamentoRetorno={setShowAddEquipamentoRetorno}
                                showAddEquipamentoRetorno={showAddEquipamentoRetorno}
                            ></EquipamentoRetornoCard>
                        </div>
                    </If>
                    <If condition={originalData?.tipo_atividade !== "Logística - Retorno"}>
                        <div className="col-12">
                            <EquipamentoRemessaCard
                                detailsData={detailsData}
                                getHistory={getHistory}
                                id={id}
                                showUpdateBtn={showUpdateBtn}
                                equipmentsData={equipmentsData}
                                getEquipmentsByIdStatus={getEquipmentsByIdStatus}
                            ></EquipamentoRemessaCard>
                        </div>
                    </If>
                    <If condition={(originalData?.status_chamado !== "Fechado" && detailsData?.status_chamado?.value === "Fechado")}>
                        <div className="col-12">
                            <HistoricoStatusCard id={id}></HistoricoStatusCard>
                        </div>
                    </If>
                    <div className="col-12">
                        <PendingExpenseTable id_ticket={originalData?.id} data_inicial={originalData?.data_registro}></PendingExpenseTable>
                    </div>
                </div>
            </Content>
        </div >
    );
}