import React, { useState } from 'react';
import Content from '../../common/Layout/Content';
import SubHeaderSpecific from '../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../common/Layout/Card';
import GrupoAcessoSelect from '../../common/Selects/GrupoAcessoSelect';
import IntegradoraSelect from '../../common/Selects/IntegradoraSelect';
import ContratosSelect from '../../common/Selects/ContratosSelect';
import { withMask } from 'use-mask-input';
import { toast } from 'react-toastify';
import { createUser } from '../../services/Users';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

export default function CreateUser() {

    const initialState = {
        grupos: '',
        id_contrato: '',
        integradora: '',
        rat_acesso: 0,
        rat_contrato: '',
        cliente: 0,
        nome: '',
        email: '',
        telefone: '',
        login: '',
        senha: '',
        observacao: '',
        hora_inicial_trabalho: '08:00',
        hora_final_trabalho: '17:00'
    }

    const [formCreateUser, setFormCreateUser] = useState(initialState);
    const [showPwd, setShowPwd] = useState(false);
    const navigate = useNavigate();

    function showPassword() {
        if (showPwd) {
            setShowPwd(false);
        } else {
            setShowPwd(true);
        }
    }

    async function submitForm(e){
        e.preventDefault();

        let formData = {
            ...formCreateUser,
            grupos: formCreateUser?.grupos.map((item, idx)=>{
                return item.value
            }) || [],
            id_contrato: formCreateUser?.id_contrato?.value || ''
        }

        const toastUsers = toast.loading("Adicionando usuário, aguarde...");

        let dataReturn = await createUser(formData);

        if(dataReturn?.data?.response === 'success'){
            toast.update(toastUsers, { render: "Usuário adicionado com sucesso!", type: "success", isLoading: false, autoClose: 1500 });
            await showAfterCreateUserOptions();
        }else if(dataReturn?.data?.message === 'The login has already been taken.'){
            toast.update(toastUsers, { render: "Este usuário já existe!", type: "warning", isLoading: false, autoClose: 1500 });
        }else{
            toast.update(toastUsers, { render: "Ocorreu um erro ao adicionar o usuário!", type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function showAfterCreateUserOptions(){
        let swal = await Swal.fire({
            title: 'O que deseja fazer agora?',
            allowOutsideClick: false,
            allowEnterKey: false,
            allowEscapeKey: false,
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Ir para Gerenciar Usuários',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Cadastrar um novo usuário'
        });

        setFormCreateUser(initialState);

        if(swal.isConfirmed){
            navigate('/usuarios/dados/ativo')
        }
    }

    function setIntegradora(value, name) {
        setFormCreateUser(prevState => ({ ...prevState, [name]: value, id_contrato: '' }));
    }

    return (
        <Content headerTitle='Adicionar Usuário'>
            <SubHeaderSpecific subHeaderConfig='mam_usuarios'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <form onSubmit={submitForm}>
                    <div className="row">
                        <div className="col-12">
                            <h5><i className='fas fa-dot-circle'></i> Grupo de Acesso</h5>
                        </div>
                        <GrupoAcessoSelect formData={formCreateUser} setFormData={setFormCreateUser} cols={[12, 12, 6, 6]} isMulti={true} required={true}></GrupoAcessoSelect>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-12">
                            <h5><i className='fas fa-dot-circle'></i> Integradora / Contrato</h5>
                        </div>
                        <IntegradoraSelect formData={formCreateUser} setFormData={setFormCreateUser} cols={[12, 12, 6, 6]} advancedSetFormData={setIntegradora}></IntegradoraSelect>
                        <ContratosSelect formData={formCreateUser} setFormData={setFormCreateUser} dependsOnIntegradora={true} fieldName='id_contrato' cols={[12, 12, 6, 6]} id_integradora_string={[formCreateUser?.integradora?.value].toString()}></ContratosSelect>
                        <span className='m-1'><b>Obs: Deixe em branco para que o usuário tenha acesso a todas as Integradoras e Contratos</b></span>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-12">
                            <h5><i className='fas fa-dot-circle'></i> Dados do Usuário</h5>
                        </div>
                        <div className="col-md-12 col-lg-4">
                            <label htmlFor="nome">Nome Completo</label>
                            <input type="text" name="nome" id="nome" className="form-control" value={formCreateUser?.nome || ''} onChange={(e) => setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value}))} placeholder='...' required={true} />
                        </div>
                        <div className="col-md-12 col-lg-4">
                            <label htmlFor="telefone">Telefone Celular</label>
                            <input type="tel" name="telefone" id="telefone" ref={withMask(['(99) 9999-9999', '(99) 99999-9999'])} className="form-control" value={formCreateUser?.telefone || ''} onChange={(e) => setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required={true} />
                        </div>
                        <div className="col-md-12 col-lg-4">
                            <label htmlFor="email">Email</label>
                            <input type="email" name="email" id="email" className="form-control" value={formCreateUser?.email || ''} onChange={(e) => setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required={true} />
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <label htmlFor="login">Login</label>
                            <input type="text" name="login" id="login" className="form-control text-lowercase" value={formCreateUser?.login || ''} onChange={(e) => setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value.toLowerCase() }))} placeholder='...' required={true} />
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <label htmlFor="senha">Senha</label>
                            <div className="input-group mb-3">
                                <input type={!showPwd ? "password" : "text"} name="senha" id="senha" minLength={8} className="form-control" placeholder="..." value={formCreateUser?.senha || ''} onChange={(e) => (setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value })))} required={true} />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className={!showPwd ? "fas fa-eye" : "fas fa-eye-slash"} onClick={() => showPassword()} style={{ cursor: "pointer" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <label htmlFor="hora_inicial_trabalho">Horario Inicial de Trabalho</label>
                            <input type="time" name="hora_inicial_trabalho" id="hora_inicial_trabalho" className='form-control' value={formCreateUser?.hora_inicial_trabalho || '08:00'} onChange={(e) => setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required={true}/>
                        </div>
                        <div className="col-md-12 col-lg-6">
                            <label htmlFor="hora_final_trabalho">Horario Final de Trabalho</label>
                            <input type="time" name="hora_final_trabalho" id="hora_final_trabalho" className='form-control' value={formCreateUser?.hora_final_trabalho || '17:00'} onChange={(e) => setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} placeholder='...' required={true}/>
                        </div>
                    </div>
                    <hr></hr>
                    <div className="row">
                        <div className="col-12">
                            <h5><i className='fas fa-dot-circle'></i> Detalhamento </h5>
                        </div>
                        <div className="col-12">
                            <label htmlFor="observacao">Observações / Informações Adicionais</label>
                            <textarea className='form-control' rows={'10'} placeholder='...' id='observacao' value={formCreateUser?.observacao || ''} onChange={(e) => setFormCreateUser(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} name='observacao'></textarea>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-12 d-flex justify-content-end">
                            <button type="submit" className='btn btn-primary'>Cadastrar</button>
                        </div>
                    </div>
                </form>
            </Card>
        </Content>
    );
}