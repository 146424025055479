import React, { useContext, useEffect, useState } from 'react';
import useGenerics from '../../hooks/useGenerics';
import Modal from '../Layout/Modal';
import { createChannel, removeExternalUser, renewExpirationDate, renewPassword, updateChannelDetails, updateChannelMembers, updateChannelTicket } from '../../services/Chat';
import { Context } from '../../App';
import ContactDetails from './ContactDetails';
import Contacts from './Contacts';
import Swal from 'sweetalert2';
import ChannelData from './ChannelData';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import SearchField from './SearchField';
import useChat from '../../hooks/useChat';
import { ChatContext } from './MainChatComponent';
import { getTicketById } from '../../services/Ticket';
import { createNotification } from '../../services/Notifications';
import ExternalContacts from './ExternalContacts';

export default function ChatDetails({ isOpen, setModalOpen }) {

    const { onImageError, openNewTab } = useGenerics();
    const { getChannelImage } = useChat();
    const userData = useContext(Context);
    const { chatState, setChatState } = useContext(ChatContext);
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [externalFilteredMembers, setExternalFilteredMembers] = useState([]);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const [isContactsListOpen, setIsContactsListOpen] = useState(false);
    const [isExternalContactListOpen, setIsExternalContactListOpen] = useState(false);
    const [isChannelDataOpen, setIsChannelDataOpen] = useState(false);
    const [contactDetailsId, setContactDetailsId] = useState('');
    const mySwal = withReactContent(Swal);

    function renderChannelMembers() {
        return (filteredMembers.map((item, idx) => {
            return (
                <button
                    className={`btn btn-link list-group-item list-group-item-action`}
                    aria-current="true"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        showChannelMemberOptionsSwal(item.id, item.name)
                    }
                    }
                    key={item.id}
                >
                    <div className="row">
                        <div className="col-12 text-collapse">
                            <img src={item.profile_picture_url || ''} className="img-circle mr-1" alt={item.id + "_image"} width={40} height={40} onError={onImageError} />
                            <span><b>{item.name}</b></span>
                        </div>
                    </div>
                </button>)
        }));
    }

    function renderExternalChannelMembers() {
        return (externalFilteredMembers.map((item, idx) => {
            let expireIn = new Date(item?.expire_in);
            let isExpired = Date.now() > expireIn;
            let splittedExpireIn = item?.expire_in?.split(" ");
            let expireInData = new Date(splittedExpireIn[0].split("-")).toLocaleDateString("pt-BR");
            return (
                <button
                    className={`btn btn-link list-group-item list-group-item-action ${isExpired ? 'text-danger' : ''}`}
                    aria-current="true"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        showChannelExternalMembersOptionsSwal(item.id, item.name)
                    }
                    }
                    key={item.id}
                >
                    <div className="row">
                        <div className="col-12 text-collapse">
                            <img src={''} className="img-circle mr-1" alt={item.id + "_image"} width={40} height={40} onError={onImageError} />
                            <span><b>{item.name}</b> - {item.email} - {isExpired ? 'Acesso Expirado' : `Expira em: ${expireInData}`}</span>
                        </div>
                    </div>
                </button>
            )
        }));
    }

    async function showAddNewMemberToChatSwal(members) {
        let swal = await mySwal.fire({
            text: 'Deseja adicionar os membros selecionados ao grupo?',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
        });

        if (swal.isConfirmed) {
            addNewMemberToChat(members);
        }
    }

    async function addNewMemberToChat(members) {
        const toastMembers = toast.loading("Adicionando membros, aguarde...");

        let formData = {
            action: 'add',
            user_id: members.map((item, idx) => {
                return item.id
            })
        }

        let dataReturn = await updateChannelMembers(chatState?.channelId, formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastMembers, { render: "Membros adicionados com sucesso!", type: "success", isLoading: false, autoClose: 1500 });
            delete dataReturn?.data?.data?.external_users;

            setChatState((prevState) => ({
                ...prevState,
                channelData: {
                    ...prevState.channelData,
                    ...dataReturn?.data?.data
                },
                members: dataReturn?.data?.data?.users?.map((item, idx) => {
                    if (item.id === userData?.userDataState?.id_usuario) {
                        return { ...item, name: 'Eu' };
                    }
                    return item;
                })
            }))
        } else {
            toast.update(toastMembers, { render: "Ocorreu um erro ao adicionar os dados!", type: "warning", isLoading: false, autoClose: 1500 });
        }

        setIsContactsListOpen(!isContactsListOpen);
    }

    async function creatNeweChannel(id) {
        let formData = {
            type: 'channel',
            users: [userData?.userDataState?.id_usuario, id],
            ticket_id: chatState?.ticketId || null,
            channel_description: '',
            channel_name: ''
        }

        const toastChannel = toast.loading("Criando canal, aguarde...");

        let dataReturn = await createChannel(formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastChannel, { render: "Canal Criado!", type: "success", isLoading: false, autoClose: 1500 });
            setChatState(prevState => ({
                ...prevState,
                channelId: dataReturn?.data?.data?._id
            }))
            setModalOpen(false);
            mySwal.close();
        } else {
            toast.update(toastChannel, { render: "Ocorreu um erro ao criar o canal!", type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function removeMemberFromChat(id, type = 'other') {
        const toastMembers = toast.loading("Removendo membro, aguarde...");

        let formData = {
            action: 'remove',
            user_id: [id]
        }

        let dataReturn = await updateChannelMembers(chatState?.channelId, formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastMembers, { render: "Membro removido com sucesso!", type: "success", isLoading: false, autoClose: 1500 });
            mySwal.close();

            if (type === 'other') {
                delete dataReturn?.data?.data?.external_users;
                setChatState((prevState) => ({
                    ...prevState,
                    channelData: {
                        ...prevState.channelData,
                        ...dataReturn?.data?.data
                    },
                    members: dataReturn?.data?.data?.users?.map((item, idx) => {
                        if (item.id === userData?.userDataState?.id_usuario) {
                            return { ...item, name: 'Eu' };
                        }
                        return item;
                    })
                }))
            } else {
                setChatState(prevState => ({
                    ...prevState,
                    channelId: ''
                }));
            }
        } else {
            toast.update(toastMembers, { render: "Ocorreu um erro ao remover membro!", type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function removeExternalMembersFromChat(id) {
        const toastRemove = toast.loading("Removendo membro, aguarde...");

        let params = {
            user_id: [id]
        };

        let dataReturn = await removeExternalUser(chatState?.channelId, params);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastRemove, { render: "Membro removido com sucesso!", type: "success", isLoading: false, autoClose: 1500 });

           delete dataReturn?.data?.data?.users;
            setChatState((prevState) => ({
                ...prevState,
                channelData: {
                    ...prevState.channelData,
                    ...dataReturn?.data?.data
                },
                externalMembers: dataReturn?.data?.data?.external_users
            }));
            mySwal.close();
        } else {
            toast.update(toastRemove, { render: "Ocorreu um erro ao remover o membro!", type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function changeChatDetails(details) {
        const toastChangeDetails = toast.loading("Atualizando dados, aguarde...");

        let dataReturn = await updateChannelDetails(chatState?.channelId, details);
        let ticketDataReturn;
        if (details?.ticket_id?.value !== chatState?.channelData?.ticket_id) {
            ticketDataReturn = await updateChannelTicket(chatState?.channelId, {
                ticket_id: details?.ticket_id?.value
            })
        }

        if (dataReturn?.data?.response === 'success' || ticketDataReturn?.data?.response === 'success') {
            let updatedData = ticketDataReturn?.data?.response === 'success' ? ticketDataReturn?.data?.data : dataReturn?.data?.data;
            toast.update(toastChangeDetails, { render: "Dados atualizados!", type: "success", isLoading: false, autoClose: 1500 });
            setIsChannelDataOpen(!isChannelDataOpen);
            setChatState(prevState => ({
                ...prevState,
                ...updatedData,
            }))
        } else {
            toast.update(toastChangeDetails, { render: "Ocorreu um erro ao atualizar os dados!", type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function showChannelMemberOptionsSwal(id, name) {
        await mySwal.fire({
            title: 'O que deseja fazer?',
            html: (<>
                {
                    id !== userData?.userDataState?.id_usuario ?
                        (<div className="row">
                            <div className="col-12 mt-1">
                                <button type="button" className='btn btn-primary btn-block' onClick={() => showContactDetails(id)}>Ver Dados do {name}</button>
                            </div>
                            <div className="col-12 mt-1">
                                <button type="button" className='btn btn-primary btn-block' onClick={() => creatNeweChannel(id)}>Criar novo canal com {name}</button>
                            </div>
                            <div className="col-12 mt-1">
                                <button type="button" className='btn btn-danger btn-block' onClick={() => removeMemberFromChat(id)}>Remover {name} do Grupo</button>
                            </div>
                        </div>)
                        :
                        (<div className="row">
                            <div className="col-12 mt-1">
                                <button type="button" className='btn btn-danger btn-block' onClick={() => removeMemberFromChat(id, 'myself')}>Sair do Grupo</button>
                            </div>
                        </div>)
                }
            </>),
            showConfirmButton: false
        })
    }

    async function showChannelExternalMembersOptionsSwal(id, name) {
        await mySwal.fire({
            title: 'O que deseja fazer?',
            html: (<>
                <div className="row">
                    <div className="col-12 mt-1">
                        <button type="button" className="btn btn-primary btn-block" onClick={() => renewPasswordByUserId(id)}>Renovar Senha</button>
                    </div>
                    <div className="col-12 mt-1">
                        <button type="button" className="btn btn-primary btn-block" onClick={() => renewExpireDateByUserId(id)}>Renovar Data de Expiração</button>
                    </div>
                    <div className="col-12 mt-1">
                        <button type="button" className="btn btn-danger btn-block" onClick={() => removeExternalMembersFromChat(id)}>Remover {name} do Grupo</button>
                    </div>
                </div>
            </>),
            showConfirmButton: false
        })
    }

    async function renewPasswordByUserId(id) {
        const toastPassword = toast.loading("Renovando senha, aguarde...");

        let dataReturn = await renewPassword(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastPassword, { render: "Senha renovada com sucesso!", type: "success", isLoading: false, autoClose: 1500 });
            mySwal.close();
        } else {
            toast.update(toastPassword, { render: "Ocorreu um erro ao renovar a senha!", type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function renewExpireDateByUserId(id) {
        const toastExpireDate = toast.loading('Renovando data de expiração, aguarde...');

        let dataReturn = await renewExpirationDate(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastExpireDate, { render: 'Data de expiração renovada com sucesso!', type: 'success', isLoading: false, autoClose: 1500 });
            setChatState(prevState => ({...prevState, updateMode: 'externalMembers', updateChat: true}));
            mySwal.close();
        } else {
            toast.update(toastExpireDate, { render: 'Ocorreu um erro ao renovar a data de expiração!', type: 'warning', isLoading: false, autoClose: 1500 });
        }
    }

    function showContactDetails(id) {
        mySwal.close();
        setContactDetailsId(id);
        setIsContactOpen(prevState => !prevState);
    }

    function renderChannelImage() {
        let channelImg = getChannelImage(chatState?.channelData?.users || [], 100, 49);

        return (
            <div className="d-flex flex-row">
                <div className={`d-flex flex-row ${chatState?.channelData?.users?.length < 3 ? '' : 'flex-wrap'} justify-content-center align-items-center`} style={{
                    border: '1px solid gray',
                    borderRadius: '50px',
                    minWidth: '100px',
                    width: '100px',
                    height: '100px',
                    overflow: 'hidden',
                }}>
                    {channelImg}
                </div>
            </div>)
    }

    function searchMembers(search = '') {
        let filteredMembers = chatState?.members;
        if (search !== '') {
            filteredMembers = chatState?.members?.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
        }
        setFilteredMembers(filteredMembers);
    }

    function searchExternalMembers(search = '') {
        let externalFilteredMembers = chatState?.externalMembers;

        if (search !== '') {
            externalFilteredMembers = chatState?.externalMembers?.filter(item => (item.name.toLowerCase().includes(search.toLowerCase()) || item.email.toLowerCase().includes(search.toLowerCase())));
        }
        setExternalFilteredMembers(externalFilteredMembers);
    }

    async function scalateMemberToChannel(type) {
        const toastMembers = toast.loading("Adicionando membros, aguarde...");
        let ticketId = chatState?.ticketId || chatState?.channelData?.ticket_id;
        let ticketData = await getTicketById(ticketId);

        if (ticketData?.data?.response === 'success') {
            let data = ticketData?.data?.data;

            let membersId = chatState?.members?.map((item, idx) => {
                return item.id;
            })


            if ([undefined, null, ''].includes(data?.[type]?.id)) {
                toast.update(toastMembers, { render: "Responsável não encontrado!", type: "warning", isLoading: false, autoClose: 1500 });
                return;
            }


            if (data?.[type]?.id === userData?.userDataState?.id_usuario) {
                toast.update(toastMembers, { render: "Você não pode adicionar a si mesmo!", type: "warning", isLoading: false, autoClose: 1500 });
                return;
            }

            if (membersId.includes(data?.[type]?.id)) {
                let formData = {
                    type: 'danger',
                    users: [data?.[type]?.id],
                    module_id: data?.id,
                    module: 'ticket',
                    event: 'help'
                }

                await createNotification(formData);
                toast.update(toastMembers, { render: 'Este usuário já está no canal', type: 'success', isLoading: false, autoClose: 1500 });
                return;
            }


            let formData = {
                action: 'add',
                user_id: [data?.[type]?.id]
            }

            let dataReturn = await updateChannelMembers(chatState?.channelId, formData);

            if (dataReturn?.data?.response === 'success') {
                setChatState((prevState) => ({
                    ...prevState,
                    channelData: {
                        ...prevState.channelData,
                        ...dataReturn?.data?.data
                    },
                    members: dataReturn?.data?.data?.users?.map((item, idx) => {
                        if (item.id === userData?.userDataState?.id_usuario) {
                            return { ...item, name: 'Eu' };
                        }
                        return item;
                    })
                }))

                let formData = {
                    type: 'danger',
                    users: [data?.[type]?.id],
                    module_id: data?.id,
                    module: 'ticket',
                    event: 'help'
                }

                await createNotification(formData);
                toast.update(toastMembers, { render: "Membro adicionado com sucesso!", type: "success", isLoading: false, autoClose: 1500 });
                return;
            } else {
                toast.update(toastMembers, { render: "Ocorreu um erro ao adiciona membro!", type: "warning", isLoading: false, autoClose: 1500 });
                return;
            }

        } else {
            toast.update(toastMembers, { render: "Ocorreu um erro ao procurar os dados do chamado!", type: "warning", isLoading: false, autoClose: 1500 });
            return;
        }
    }

    useEffect(() => {
        searchMembers();
    }, [chatState?.members]);

    useEffect(() => {
        searchExternalMembers();
    }, [chatState?.externalMembers]);

    return (
        <Modal
            title={'Dados do Canal'}
            isOpen={isOpen}
            setModalOpen={setModalOpen}
        >
            <ContactDetails
                isOpen={isContactOpen}
                setModalOpen={() => setIsContactOpen(!isContactOpen)}
                contactId={contactDetailsId}
            ></ContactDetails>
            <Contacts
                isOpen={isContactsListOpen}
                setModalOpen={() => setIsContactsListOpen(!isContactsListOpen)}
                updateChannel={true}
                title='Adicionar Membro ao Grupo'
                setMembers={showAddNewMemberToChatSwal}
            ></Contacts>
            <ExternalContacts
                title='Adicionar Participante Externo'
                isOpen={isExternalContactListOpen}
                setModalOpen={() => setIsExternalContactListOpen(!isExternalContactListOpen)}
            ></ExternalContacts>
            <ChannelData
                isOpen={isChannelDataOpen}
                setModalOpen={() => setIsChannelDataOpen(!isChannelDataOpen)}
                title='Alterar Dados do Canal'
                updateChannel={true}
                changeChannelData={changeChatDetails}
            ></ChannelData>
            <div style={{ minWidth: '50vw' }}>
                <div className="card" style={{ backgroundColor: '#F4F6F9' }}>
                    <div className="card-header d-flex justify-content-center">
                        {renderChannelImage()}
                    </div>
                    <div className="card-body text-center">
                        <h6 className='d-inline'>{chatState?.channelData?.worked_channel_name}</h6>
                        <button type="button" className="btn btn-success float-right" onClick={() => setIsChannelDataOpen(!isChannelDataOpen)}><i className='fas fa-pen'></i></button>
                    </div>
                </div>
                <div className="card" style={{ backgroundColor: '#F4F6F9' }}>
                    <div className="card-header text-center">
                        <h6 className='d-inline'>Detalhes</h6>
                        <button type="button" className="btn btn-success float-right" onClick={() => setIsChannelDataOpen(!isChannelDataOpen)}><i className='fas fa-pen'></i></button>
                    </div>
                    <div className="card-body">
                        <p><b>Criado em: </b>{chatState?.channelData?.created_at}</p>
                        <p><b>Atualizado em: </b>{chatState?.channelData?.updated_at}</p>
                        <p><b>Chamado Associado: </b>{chatState?.channelData?.ticket_id ? <button className='btn btn-link' onClick={() => openNewTab(`/chamados/detalhes-chamado/${chatState?.channelData?.ticket_id}`)}>{chatState?.channelData?.ticket_number}</button> : 'Não Associado'}</p>
                        <p><b>Descrição: </b>{chatState?.channelData?.channel_description || 'Sem Descrição'}</p>
                    </div>
                </div>
                <div className="card" style={{ backgroundColor: '#F4F6F9' }}>
                    <div className="card-header text-center">
                        <h6 className='d-inline'>Participantes: {(chatState?.channelData?.users?.length + chatState?.channelData?.external_users?.length) || 0}</h6>
                        <div className="dropdown float-right">
                            <button className="btn btn-success dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false' title='Opções...'><i className="fas fa-plus"></i></button>
                            <div className="dropdown-menu dropdown-menu-right">
                                <button type='button' className={`dropdown-item`} onClick={() => setIsContactsListOpen(!isContactsListOpen)}>Adicionar Participante</button>
                                <button type='button' className={`dropdown-item`} onClick={() => setIsExternalContactListOpen(!isExternalContactListOpen)}>Adicionar Participante Externo</button>
                                <button type='button' className={`dropdown-item ${(chatState?.ticketId === "" && chatState?.channelData?.ticket_id === null) ? "d-none" : "d-block"}`} onClick={() => scalateMemberToChannel('user')}>Escalar Acionamento Neste Chat</button>
                                <button type='button' className={`dropdown-item ${(chatState?.ticketId === "" && chatState?.channelData?.ticket_id === null) ? "d-none" : "d-block"}`} onClick={() => scalateMemberToChannel('support_responsible_user')}>Escalar Suporte Neste Chat</button>
                                <button type='button' className={`dropdown-item ${(chatState?.ticketId === "" && chatState?.channelData?.ticket_id === null) ? "d-none" : "d-block"}`} onClick={() => scalateMemberToChannel('partnership_responsible_user')}>Escalar Parceria Neste Chat</button>
                                <button type='button' className={`dropdown-item ${(chatState?.ticketId === "" && chatState?.channelData?.ticket_id === null) ? "d-none" : "d-block"}`} onClick={() => scalateMemberToChannel('ticket_responsible_user')}>Escalar Resp. Chamado Neste Chat</button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <nav>
                            <div className="nav nav-tabs" id="nav-tab" role='tablist'>
                                <button type="button" className="nav-link active" id="nav-internal-contacts-tab" data-toggle='tab' data-target='#nav-internal' role='tab' aria-controls='nav-internal' aria-selected='true'>Participantes MAMINFO - {chatState?.channelData?.users?.length}</button>
                                <button type="button" className="nav-link" id="nav-external-contacts-tab" data-toggle='tab' data-target='#nav-external' role='tab' aria-controls='nav-external' aria-selected='true'>Participantes Externos - {chatState?.channelData?.external_users?.length}</button>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-internal" role='tabpanel' aria-labelledby='nav-internal-tab'>
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <SearchField placeholder='Pesquise o nome do contato' searchFunction={searchMembers}></SearchField>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-12" key={'internalParticipantsList'}>
                                        <ul className="list-group">
                                            {
                                                renderChannelMembers()
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-external" role='tabpanel' aria-labelledby='nav-external-tab'>
                                <div className="row mt-1">
                                    <div className="col-12">
                                        <SearchField placeholder='Pesquise o nome do contato' searchFunction={searchExternalMembers}></SearchField>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-12" key={'externalParticipantsList'}>
                                        <ul className="list-group">
                                            {
                                                renderExternalChannelMembers()
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}