import React, { useEffect, useState } from 'react';
import CreateLeaves from './CreateLeaves';
import Table from '../../../../../common/Layout/Table/Table';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { cancelLeave, getLeavesByEmployeeId, uploadLeaveAttachment } from '../../../../../services/EmployeeLeave';
import withReactContent from 'sweetalert2-react-content';
import PrimeTable from '../../../../../common/Layout/Table/PrimeTable';

export default function LeavesTable({ id = '', updateData = false }) {
    const [isOpen, setIsOpen] = useState(false);
    const [afastamentoData, setAfastamentoData] = useState([]);
    const mySwal = withReactContent(Swal);

    const afastamentoColumns = [
        {
            id: 'tipo_afastamento_afastamento',
            name: 'Tipo de Afastamento',
        },
        {
            id: 'data_inicio_afastamento',
            name: 'Data de Início',
        },
        {
            id: 'data_fim_afastamento',
            name: 'Data de Término',
        },
        {
            id: 'status_afastamento',
            name: 'Status',
        },
        {
            id: 'action_afastamento',
            name: 'Ação',
            body: (row) => (
                <div class="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" disabled={updateData}>
                        Ações
                    </button>
                    <div className="dropdown-menu">
                        {
                            row.status_afastamento === 'Ativo' &&
                            <button type="button" className='dropdown-item' onClick={() => showCancelLeavesSwal(row.id_afastamento)}><i className='fas fa-trash'></i>Cancelar</button>
                        }
                        <button type="button" className='dropdown-item' onClick={() => showUpdateLeaveAttachmentSwal(row.id_afastamento)}><i className='fas fa-upload'></i>Atualizar Arquivo</button>
                        {row.url_anexo_afastamento !== '' &&
                            <a role='button' href={row.url_anexo_afastamento} className='dropdown-item' download={true} target='_blank'><i className='fas fa-download'></i>Baixar Anexo</a>
                        }
                    </div>
                </div>
            )
        }
    ];

    async function getLeaves() {
        const toastUpdateForm = toast.loading('Pesquisando afastamentos, aguarde...');

        let dataReturn = await getLeavesByEmployeeId(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastUpdateForm, { render: 'Afastamentos encontrados!', type: "success", isLoading: false, autoClose: 1500 });
            let data = dataReturn?.data?.data.map((item, idx) => {
                return ({
                    tipo_afastamento_afastamento: item?.tipo_afastamento,
                    data_inicio_afastamento: item?.data_inicial,
                    data_fim_afastamento: item?.data_final,
                    id_afastamento: item?.id,
                    status_afastamento: item?.status === 1 ? 'Ativo' : 'Inativo',
                    url_anexo_afastamento: item?.url_anexo || ''
                });
            });

            setAfastamentoData(data);
        } else {
            toast.update(toastUpdateForm, { render: 'Ocorreu um erro ao buscar os afastamentos!', type: "warning", isLoading: false, autoClose: 1500 });
            setAfastamentoData([]);
        }
    }

    async function showCancelLeavesSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja mesmo cancelar este afastamento?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            cancelButtonColor: 'red',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            cancelLeaves(id);
        }
    }

    async function cancelLeaves(id) {
        const toastCancel = toast.loading("Cancelando afastamento, aguarde...");

        let dataReturn = await cancelLeave(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastCancel, { render: 'Afastamento cancelado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getLeaves();
        } else {
            toast.update(toastCancel, { render: 'Ocorreu um erro ao cancelar o afastamento!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function showUpdateLeaveAttachmentSwal(id_afastamento) {
        let swal = await mySwal.fire({
            title: 'Anexar arquivo!',
            input: 'file',
            inputAttributes: {
                "accept": ".png, .pdf, .jpg, .jpeg",
            },
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Anexar',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Cancelar'
        });

        if (swal.isConfirmed && swal.value !== null) {
            updateLeaveAttachment(id_afastamento, swal.value);
        }
    }

    async function updateLeaveAttachment(id, file) {
        const toastUpdate = toast.loading("Anexando arquivo, aguarde...");

        let formData = new FormData();

        formData.append('arquivo', file);

        let dataReturn = await uploadLeaveAttachment(id, formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastUpdate, { render: 'Arquivo anexado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getLeaves();
        } else {
            toast.update(toastUpdate, { render: 'Ocorreu um erro ao anexar o arquivo!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(()=>{
        getLeaves();
    },[]);

    return (
        <div className="row">
            <div className="col-12">
                <h5><i className='fas fa-dot-circle'></i> Afastamentos</h5>
                <button type="button" className="btn btn-primary" onClick={()=>setIsOpen(!isOpen)} disabled={updateData}>Cadastrar</button>
            </div>
            <div className="col-12">
                <PrimeTable
                    columns={afastamentoColumns}
                    rows={afastamentoData}
                    id='afastamento'
                ></PrimeTable>
            </div>
            <CreateLeaves idTecnico={id} isOpen={isOpen} setIsOpen={()=>setIsOpen(!isOpen)} setUpdateParent={getLeaves}></CreateLeaves>
        </div>
    );

}