import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '../../../../common/Layout/Modal';
import FabricanteSelect from '../../../../common/Selects/FabricanteSelect';
import ModelosSelect from '../../../../common/Selects/ModelosSelect';

export default function CreateProduct({
    isOpen = false,
    setIsOpen = null,
    updateParent = null,
}) {
    const INITIAL_STATE = {
        id_fabricante: '',
        id_modelo: '',
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function submitForm(e) {
        e.preventDefault();
        for(let data in formData){
            if(formData[data] === ''){
                toast.warning("Preencha todos os campos");
                return;
            }
        }
        updateParent(formData);
        setFormData(INITIAL_STATE);
    }

    function handleFabricante(value, name) {
        setFormData(prev => ({ ...prev, [name]: value, id_modelo: '' }));
    }

    useEffect(() => {
        setFormData(INITIAL_STATE);
    }, [isOpen])

    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={setIsOpen}
            title='Cadastrar Produto'
        >
            <div className="row">
                <FabricanteSelect formData={formData} setFormData={setFormData} cols={[12, 6, 6, 6]} fieldName='id_fabricante' required={true} advancedSetFormData={handleFabricante}></FabricanteSelect>
                <ModelosSelect formData={formData} setFormData={setFormData} cols={[12, 6, 6, 6]} fieldName='id_modelo' id_fabricante={formData?.id_fabricante?.value || ''} required={true} only_available='only_available'></ModelosSelect>
                <div className="col-12 mt-1">
                    <button type="submit" className="btn btn-primary" onClick={submitForm}>Cadastrar</button>
                </div>
            </div>
        </Modal>);
}