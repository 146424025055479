import React, { useContext } from 'react';
import Card from '../../../common/Layout/Card';
import TiposAtividadesOptions from '../../../common/Selects/TiposAtividadesOptions';
import usePermissions from '../../../hooks/usePermissions';
import { Context } from '../../../App';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getContractById } from '../../../services/Contracts';
import CustomMessageData from '../../../common/Layout/CustomMessageData';
import useGenerics from '../../../hooks/useGenerics';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { convertFromRaw, Editor, EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import If from '../../../common/Layout/If';

export default function ChamadosCardData({
    originalData = {},
    detailsData = {},
    setDetailsData = null,
    showUpdateBtn = false,
    setShowFabricanteBtn = null,
    setShowAlterarNumeroChamadoBtn = null,
    setShowAlterarCircuitoBtn = null,
    setShowContratoBtn = null,
    setShowNumeroRmaBtn = null
}) {
    const { checkPermissionGroup } = usePermissions();
    const { isJsonValid, copyToClipboard } = useGenerics();
    const mySwal = withReactContent(Swal);
    const userData = useContext(Context);

    async function showContractPOP() {
        let contractId = originalData?.id_contrato || '';

        const toastContract = toast.loading("Carregando dados, aguarde...");

        if (contractId !== '') {
            let dataReturn = await getContractById(contractId);

            if (dataReturn?.data?.response === 'success') {
                toast.update(toastContract, { render: `Dados encontrados!`, type: "success", isLoading: false, autoClose: 1500 });
                let contractData = dataReturn?.data?.data

                let editorComponent = (<CustomMessageData message="Sem Dados"></CustomMessageData>)

                if (contractData?.resumo_pop !== null && contractData?.resumo_pop !== '') {
                    let dados = contractData?.resumo_pop
                    if (isJsonValid(dados)) {
                        const editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(dados)))

                        editorComponent = (
                            <Editor
                                editorState={editorState}
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class-readonly"
                                toolbarClassName="toolbar-class-readonly"
                                readOnly={true}
                            ></Editor>
                        );
                    }
                }

                await mySwal.fire({
                    titleText: `Resumo Contrato`,
                    width: '90%',
                    confirmButtonText: 'Fechar',
                    confirmButtonColor: 'red',
                    html: (
                        <>
                            <div className='row w-100'>
                                <div className='col-12'>
                                    <h5>Resumo POP</h5>
                                    {
                                        editorComponent
                                    }
                                    {
                                        contractData.arquivo_planta &&
                                        (<a href={`https://view.officeapps.live.com/op/view.aspx?src=${contractData.url_arquivo_planta}`} className='m-1' target='_blank' rel='noreferrer'>Acessar arquivo planta</a>)
                                    }
                                    {
                                        contractData.arquivo_pop &&
                                        (<a href={`https://view.officeapps.live.com/op/view.aspx?src=${contractData.url_arquivo_pop}`} className='m-1' target='_blank' rel='noreferrer'>Acessar arquivo POP Completo</a>)
                                    }
                                </div>
                            </div>
                        </>),
                });
            } else {
                toast.update(toastContract, { render: `Ocorreu um erro ao buscar os dados!`, type: "warning", isLoading: false, autoClose: 1500 });
            }

        } else {
            toast.update(toastContract, { render: `Aguarde os dados do chamado serem carregados!`, type: "warning", isLoading: false, autoClose: 1500 });
        }

    }

    return (<Card title={<span onClick={()=>copyToClipboard(originalData?.num_chamado)}>Chamados: {originalData?.num_chamado}</span>} icon={'fa-laptop'}>
        <div className="row">
            <div className="col-12">
                <p><b>Data da Solicitação: </b>{`${originalData?.data_solicitacao || ''} ${originalData?.hora_solicitacao || ''}`}</p>
                <p><b>Data de Abertura: </b>{originalData?.data_registro}</p>
                <p><b>Tipo de Atividade: </b>{!showUpdateBtn && (originalData?.tipo_atividade)}</p>
                <If condition={showUpdateBtn}>
                    <TiposAtividadesOptions formData={detailsData} setFormData={setDetailsData} fieldName='tipo_atividade'></TiposAtividadesOptions>
                </If>
                <p><b>Integradora: </b>{originalData?.contract?.integrator?.nome}</p>
                <p><b>Senha da Integradora: </b>{originalData?.senha_integradora}</p>
                <p><b>Contrato: </b><Link to={`/cadastros/contratos/detalhes/${originalData?.contract?.id}`}>{originalData?.contract?.contrato}</Link> <button className='btn btn-link' style={{ backgroundColor: 'transparent', color: 'gray', cursor: 'pointer' }} onMouseOver={showContractPOP}><i className='fas fa-question'></i></button></p>
                <p><b>Centro de Custo: </b>{originalData?.contract?.centro_custo}</p>
                <p><b>Informação Adicional: </b>{originalData?.informacao_adicional}</p>
                <p><b>Circuito: </b>{originalData?.info_adicional_circuito}</p>
                <p><b>Número RMA: </b>{originalData?.numero_rma}</p>
                <p><b>Nome do Solicitante: </b>{originalData?.nome_solicitante}</p>
            </div>
            <If condition={showUpdateBtn}>
                <If condition={(checkPermissionGroup(['acionamento_operacao', 'analista_suporte', 'gerente_operacoes', 'acionamento_gestao', 'acionamento_supervisao']) && userData?.userDataState?.cliente === 0)}>
                    <div className="col-6 mb-1">
                        <button className="btn btn-secondary w-100" onClick={() => setShowAlterarNumeroChamadoBtn(prevState => !prevState)}>Alt. Nº Chamado</button>
                    </div>
                </If>
                <If condition={(checkPermissionGroup(['acionamento_operacao', 'analista_suporte', 'gerente_operacoes', 'acionamento_gestao', 'acionamento_supervisao']) && userData?.userDataState?.cliente === 0)}>
                    <div className="col-6 mb-1">
                        <button className="btn btn-secondary w-100" onClick={() => setShowFabricanteBtn(prevState => !prevState)}>Alt. Fabricante</button>
                    </div>
                    <div className="col-6 mb-1">
                        <button className="btn btn-secondary w-100" onClick={() => setShowAlterarCircuitoBtn(prevState => !prevState)}>Alt. Circuito</button>
                    </div>
                    <div className="col-6 mb-1">
                        <button className="btn btn-secondary w-100" onClick={() => setShowContratoBtn(prevState => !prevState)}>Alt. Contrato</button>
                    </div>
                </If>
                <If condition={userData?.userDataState?.cliente === 0}>
                    <div className="col-6">
                        <button className="btn btn-secondary w-100" onClick={() => setShowNumeroRmaBtn(prevState => !prevState)}>Alt. Nº RMA</button>
                    </div>
                </If>
            </If>
        </div>
    </Card>);
}