import React from 'react';
import Card from '../../../common/Layout/Card';

export default function DetalhesAcionamentoCard({detailsData = {}, setDetailsData = null, showUpdateBtn = false}) {
    return (<Card title="Detalhes Acionamento" icon="fa-dot-circle">
        <div className="row">
            <div className="col-12">
                <div className="form-group">
                    <label htmlFor="">Detalhes Acionamento</label>
                    <textarea name="descricao" cols="30" rows="10" className="form-control" placeholder='...' value={detailsData?.descricao || ''} onChange={(e) => setDetailsData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} readOnly={!showUpdateBtn}></textarea>
                </div>
            </div>
        </div>
    </Card>)
}