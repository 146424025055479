import React, { useEffect, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../../common/Layout/Card';
import { toast } from 'react-toastify';
import { getAllPendingDactShipments } from '../../../../services/Shipment';
import PendingDactDetails from './PendingDactDetails';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import If from '../../../../common/Layout/If';
import usePermissions from '../../../../hooks/usePermissions';
import useGenerics from '../../../../hooks/useGenerics';

export default function PendingDact() {
    const [data, setData] = useState([]);
    const [viewMode, setViewMode] = useState('view');
    const [pendingDactId, setPendingDactId] = useState('');
    const [openPendingDactDetails, setOpenPendingDactDetails] = useState(false);
    const [updateLogistic, setUpdateLogistic] = useState(false);

    const [loading, setLoading] = useState(true);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const {checkPermissionGroup} = usePermissions();
    const { openNewTab } = useGenerics();

    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <button type="button" className="dropdown-item" onClick={() => openPendingDactModal(row.id_produto, 'view')}>Ver</button>
                        <If condition={checkPermissionGroup(["logistica_gestao", "logistica_operacao"])}>
                            <button type='button' className="dropdown-item" onClick={() => openPendingDactModal(row.id_produto, 'edit')}>Editar Valores DACT</button>
                        </If>
                    </div>
                </div>
            )
        },
        {
            id: 'nome_produto',
            name: 'Nome do Produto',
        },
        {
            id: 'serial_produto',
            name: 'Serial',
        },
        {
            id: 'tipo_produto',
            name: 'Tipo de Produto',
        },
        {
            id: 'id_localidade_produto',
            name: 'ID da Localidade\\Num. Chamado',
            body: (row) => (<a onClick={() => openNewTab('/chamados/detalhes-chamado/' + row.id_chamado_produto)} href="javascript:void(0)">{row.id_localidade_produto}</a>)
        },
        {
            id: 'contrato_produto',
            name: 'Contrato\\Projeto'
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
        },
        {
            id: 'codigo_rastreio_produto',
            name: 'Código de Rastreio',
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
        },
        {
            id: 'nfe_saida_produto',
            name: 'NFE Saída',
        },
        {
            id: 'data_entrega_produto',
            name: 'Data de Entrega',
        }
    ];

    async function getPendingDactByStatus(page = 1, perPage = 10) {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getAllPendingDactShipments(page, perPage);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });

            let products = dataReturn?.data?.data?.data || [];

            let productData = products.map((item, idx) => ({
                id_produto: item?.id || '',
                tipo_produto: item?.product?.nome_produto || '',
                nome_produto: item?.product?.modelo || '',
                serial_produto: item?.product?.serial || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                nfe_saida_produto: item?.nfe_saida || '',
                codigo_rastreio_produto: item?.rastreamento || '',
                data_entrega_produto: item?.data_recebido || '',
                id_chamado_produto: item?.ticket?.id || '',
                id_localidade_produto: item?.ticket?.num_chamado || '',
                contrato_produto: item?.ticket?.contract?.contrato || ''
            }));
            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setData([]);
        }
        setLoading(false);
    }

    function openPendingDactModal(id, mode) {
        setPendingDactId(id);
        setViewMode(mode);
        setOpenPendingDactDetails(true);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getPendingDactByStatus(newPage + 1, perPage);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getPendingDactByStatus(1, rows);
    }

    useEffect(() => {
        getPendingDactByStatus()
    }, []);

    return (<Content headerTitle='Pendente DACT'>
        <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
        <PendingDactDetails isOpen={openPendingDactDetails} setModalOpen={() => setOpenPendingDactDetails(!openPendingDactDetails)} pendingDactId={pendingDactId} viewMode={viewMode} setUpdateProducts={() => getPendingDactByStatus(page + 1, perPage)} setUpdateLogistic={setUpdateLogistic}></PendingDactDetails>
        <Card title='Cadastro de Produtos - Pendente DACT'>
            <PrimeTable
                columns={columns}
                rows={data}
                serverPagination={true}
                handlePagination={handlePaginationPrime}
                handleRowPerPage={handleRowPerPagePrime}
                count={paginationTotalRows}
                page={page}
                rowsPerPage={perPage}
                loading={loading}
            ></PrimeTable>
        </Card>
    </Content>)
}