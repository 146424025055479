import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { utils, writeFile } from 'xlsx';
import If from '../If';
import CustomMessageData from '../CustomMessageData';

export default function PrimeTable({
    rows = [],
    columns = [],
    serverPagination = false,
    page = 0,
    rowsPerPage = 10,
    count = 0,
    needExport = true,
    handlePagination = null,
    handleRowPerPage = null,
    loading = false,
    customStyles = '',
    id = 'primetable',
    selectableRows = false,
    selectedRows = [],
    handleSelect = null
}) {
    const topRef = useRef();
    const dummyDivRef = useRef();
    const [pageData, setPageData] = useState(serverPagination ? page : 0);
    const [rowPerPageData, setRowPerPageData] = useState(serverPagination ? rowsPerPage : 10);
    const [totalData, setTotalData] = useState(0);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [filters, setFilters] = useState(setFiltersCondition());
    const [data, setData] = useState(rows);
    let filterTimer = null;

    const onPage = (event) => {
        if (event.page !== pageData) {
            handlePageChange(event.page);
        }

        if (event.rows !== rowPerPageData) {
            handleRowsPerPage(event.rows);
        }
    }

    const onSort = (event) => {
        setSortField(event.sortField);
        setSortOrder(event.sortOrder);
        loadSort(event.sortOrder, event.sortField, data)
    }

    const onFilter = (e) => {
        clearTimeout(filterTimer)
        filterTimer = setTimeout(() => {
            setFilters(prev => ({ ...prev, [e.target.name]: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS } }));
        }, 500);
    }

    const applyFilters = (currentFilters) => {
        let filteredData = rows.filter(row => {
            return columns.every(col => {
                const filter = currentFilters[col.id];
                if (filter && filter.value) {
                    const value = typeof row[col.id] === 'object' ? row[col.id]?.props?.children.toString() : row[col.id].toString();
                    return value.toLowerCase().includes(filter.value.toLowerCase());
                }
                return true;
            });
        })

        setData(filteredData);
        setSortField(null);
        setSortOrder(null);

        if (filteredData.length !== rows.length) {
            setPageData(0);
            setTotalData(filteredData.length);
        }

        if (serverPagination && filteredData.length === rows.length) {
            setTotalData(count);
            setPageData(page);
        } else {
            setTotalData(filteredData.length);
            setPageData(0);
        }

    }

    const handlePageChange = (newPage) => {
        if (serverPagination) {
            handlePagination(newPage);
        }
        setPageData(newPage);
    }

    const handleRowsPerPage = (rows) => {
        if (serverPagination) {
            handleRowPerPage(rows);
        }

        setPageData(0);
        setRowPerPageData(rows)
    }

    function setFiltersCondition() {
        return columns.reduce((acc, item) => {
            acc[item.id] = { value: null, matchMode: FilterMatchMode.CONTAINS };
            return acc;
        }, {});
    }

    function renderColumns() {
        return columns.map((item, index) => {
            const isAction = item.id.includes('action');
            return (<Column
                key={item.id}
                field={item.id}
                header={item.name}
                body={item?.body}
                style={{
                    maxHeight: '40px',
                    height: '40px',
                    whiteSpace: isAction ? 'normal' : 'nowrap',
                    maxWidth: isAction ? 'none' : '300px',
                    minWidth: isAction ? 'none' : '150px',
                    width: isAction ? '50px' : '',
                    textOverflow: isAction ? 'visible' : 'ellipsis',
                    overflow: isAction ? 'visible' : 'hidden',
                }}
                headerStyle={{
                    whiteSpace: 'normal',
                    maxWidth: isAction ? 'none' : '300px',
                    minWidth: isAction ? 'none' : '150px',
                    width: isAction ? '50px' : '',
                    wordWrap: 'keep-all',
                    paddingTop: '0',
                    paddingBottom: '0',
                    marginTop: '0',
                    marginBottom: '0',
                    fontWeight: 'bold'
                }}
                filter
                filterElement={
                    <If condition={!isAction && [undefined, null, true].includes(item?.filter)}>
                        <InputText
                            name={item.id}
                            onChange={(e) => onFilter(e)}
                            placeholder={item.name}
                            disabled={item.id.includes('action')}
                            style={{ minWidth: '130px', maxWidth: '270px'}}
                        ></InputText>
                    </If>
                }
                filterMatchMode={FilterMatchMode.CONTAINS}
                filterPlaceholder={item.name}
                showFilterMenu={false}
                sortable={!item.id.includes("action") || [undefined, null, true].includes(item?.sortable) ? true : false}
            ></Column>
            )
        })
    }

    function loadSort(orderP, orderByP, data = []) {
        let sortData = [...data];
        let compareFn;

        if (sortData.length === 0) {
            setData(sortData);
            return;
        }

        if (orderP === 0 && orderByP === null) {
            setData(rows);
            return;
        }

        if (typeof sortData[0][orderByP] === 'number') {
            compareFn = (a, b) => orderP === 1 ? a[orderByP] - b[orderByP] : b[orderByP] - a[orderByP];
        }

        if (typeof sortData[0][orderByP] === 'string') {
            compareFn = (a, b) => orderP === 1 ? a[orderByP].localeCompare(b[orderByP]) : b[orderByP].localeCompare(a[orderByP]);
        }

        if (typeof sortData[0][orderByP] === 'object') {
            compareFn = (a, b) => {
                let aValue = a[orderByP]?.props?.children || '';
                let bValue = b[orderByP]?.props?.children || '';
                return orderP === 1 ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
        }
        sortData.sort(compareFn);

        setData(sortData);
    }

    const handleScroll = (e) => {
        setScrollWidth();
        let tableScroll = document.getElementById(id).querySelector(`[class="p-virtualscroller p-virtualscroller-inline"]`) || document.getElementById(id).querySelector(`[class="p-datatable-wrapper"]`);
        let dummyScroll = topRef.current.scrollLeft;

        tableScroll.scrollLeft = dummyScroll;
    }

    const handleBottomScroll = (scrollValue) => {
        topRef.current.scrollLeft = scrollValue;
    }

    const handleCsvExport = () => {
        const csvData = preProcessData(data);

        const ws = utils.json_to_sheet(csvData);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws);

        let date = new Date();

        writeFile(wb, `Export_CSV_${date.getDate()}_${date.getMonth() + 1}_${date.getFullYear()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}.csv`);
    }

    function preProcessData(data) {
        let processedData = data.map((item, idx) => {
            let processedItem = {};

            Object.keys(item).forEach(key => {
                const value = item[key];
                processedItem[key] = typeof value === 'string' ? value.replace(/(\r\n|\n|\r)/gm, " ") : value;
            });

            return processedItem;
        });

        return processedData;
    }

    const handleXlsxExport = () => {
        const ws = utils.json_to_sheet(data);

        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws);

        let date = new Date();

        writeFile(wb, `Export_XLSX_${date.getDate()}_${date.getMonth() + 1}_${date.getFullYear()}_${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}.xlsx`);
    }

    function setScrollWidth() {
        let table = document.getElementById(id).querySelector('table').clientWidth;
        let dummy = dummyDivRef.current.style.width;

        if (table !== dummy) {
            dummyDivRef.current.style.width = `${table}px`;
        }
    }

    useEffect(() => {
        setData(rows);
        if (serverPagination) {
            setTotalData(count);
            setPageData(page);
        } else {
            setTotalData(rows.length);
            setPageData(0);
        }
    }, [rows]);

    useEffect(() => {
        setScrollWidth();

        let tableContainer = document.getElementById(id).querySelector(`[class="p-virtualscroller p-virtualscroller-inline"]`) || document.getElementById(id).querySelector(`[class="p-datatable-wrapper"]`);

        tableContainer.addEventListener('scroll', () => handleBottomScroll(tableContainer.scrollLeft));

        return () => {
            tableContainer.removeEventListener('scroll', () => handleBottomScroll(tableContainer.scrollLeft));
        }
    }, [data]);

    useEffect(() => {
        applyFilters(filters);
    }, [filters]);

    return (<>
        <If condition={needExport}>
            <div className='row mb-1 mt-1'>
                <div className="col-md-6 col-sm-12">
                    <button type="button" className="btn btn-sm btn-secondary mr-2 ml-2" onClick={handleXlsxExport}>XLSX</button>
                    <button type="button" className="btn btn-sm btn-secondary mr-2" onClick={handleCsvExport}>CSV</button>
                </div>
            </div>
        </If>
        <div style={{ overflowX: 'auto' }} onScroll={handleScroll} ref={topRef}>
            <div style={{ height: '1px' }} ref={dummyDivRef}></div>
        </div>
        <Paginator
            first={pageData * rowPerPageData}
            rows={rowPerPageData}
            rowsPerPageOptions={[10, 50, 100, totalData]}
            totalRecords={totalData}
            onPageChange={onPage}
        ></Paginator>
        <DataTable
            id={id}
            value={serverPagination ? data : data?.slice(pageData * rowPerPageData, pageData * rowPerPageData + rowPerPageData)}
            paginator
            rows={rowPerPageData}
            rowsPerPageOptions={[10, 50, 100, totalData]}
            tableStyle={{ width: '100%' }}
            totalRecords={totalData}
            first={pageData * rowPerPageData}
            onPage={onPage}
            loading={loading}
            scrollable
            scrollHeight="500px"
            virtualScrollerOptions={rows.length > 500 ? { itemSize: 40 } : {}}
            rowClassName={customStyles}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            removableSort
            filterDisplay='row'
            lazy
            reorderableColumns
            selectionMode={selectableRows ? 'checkbox' : undefined}
            selection={selectedRows}
            onSelectionChange={(e) => handleSelect(e.value)}
            emptyMessage={
                <>
                    <If condition={loading}>
                        <CustomMessageData message='Carregando dados, aguarde...'></CustomMessageData>
                    </If>
                    <If condition={rows.length === 0 || data.length === 0}>
                        <CustomMessageData message='Sem Dados'></CustomMessageData>
                    </If>
                </>
            }
        >
            {
                selectableRows && (<Column selectionMode='multiple' headerStyle={{ width: '3rem' }}></Column>)
            }
            {renderColumns()}
        </DataTable>
    </>)
}