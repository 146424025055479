import React, { useContext, useEffect, useState } from 'react';
import Card from '../../../../common/Layout/Card';
import { Context } from '../../../../App';
import If from '../../../../common/Layout/If';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import CreateProduct from './CreateProduct';

export default function EquipamentoRemessaCard({
    showUpdateBtn = false,
    detailsData = {},
    getHistory = null,
    id = '',
    equipmentsData = [],
    getEquipmentsByIdStatus = null
}) {

    const userData = useContext(Context);
    const [isAddEquipmentOpen, setIsAddEquipmentOpen] = useState(false);

    const equipmentsColumns = [
        // {
        //     id: 'action_equipamento_remessa',
        //     name: 'Ação',
        //     cell: (row) => (
        //         showUpdateEquipamentoRemessa &&
        //         (<div className="align-items-center">
        //             <button className='btn btn-secondary' onClick={() => deleteEquipment(row)}><i className='fas fa-trash'></i></button>
        //         </div>)
        //     )
        // },
        {
            id: 'part_number_equipamento_remessa',
            name: 'Nome Produto',
        },
        {
            id: 'serial_equipamento_remessa',
            name: 'Serial',
        },
        {
            id: 'tipo_produto_remessa',
            name: 'Tipo Produto',
        },
        {
            id: 'fabricante_remessa',
            name: 'Fabricante'
        },
        {
            id: 'modal_envio_equipamento_remessa',
            name: 'Modal Envio',
            // body: (row) => (
            //     showUpdateEquipamentoRemessa ?
            //         (<div className='align-items-center'>
            //             <Select
            //                 menuPortalTarget={document.body}
            //                 menuPosition='fixed'
            //                 name='modal_envio_equipamento_remessa_select'
            //                 placeholder="Selecione uma opção..."
            //                 value={modalEnvioOptions.find((e => e.label === row.modal_envio_equipamento_remessa)) || ''}
            //                 onChange={(e) => updateEquipmentData(row, 'modal_envio', e?.value)}
            //                 options={modalEnvioOptions}
            //                 className='w-100'
            //             ></Select>
            //         </div>)
            //         :
            //         (<>
            //             {row.modal_envio_equipamento_remessa}
            //         </>)
            // )
        },
        {
            id: 'data_postagem_equipamento_remessa',
            name: 'Data da Postagem',
        },
        {
            id: 'rastreamento_equipamento_remessa',
            name: 'Rastreamento',
        },
        {
            id: 'status_remessa',
            name: 'Status Remessa'
        },
        {
            id: 'status_transportadora_equipamento_remessa',
            name: 'Status Transportadora',
        },
        {
            id: 'prazo_entrega_equipamento_remessa',
            name: 'Prazo de Entrega',
        }
    ];

    function updateThis(){
        getEquipmentsByIdStatus();
        getHistory();
    }

    useEffect(() => {
        getEquipmentsByIdStatus();
    }, []);

    return (<Card title="Equipamento Remessa" icon="fa-keyboard">
        <CreateProduct
            id_chamado={id}
            isOpen={isAddEquipmentOpen}
            setIsOpen={() => setIsAddEquipmentOpen(!isAddEquipmentOpen)}
            updateParent={updateThis}
        ></CreateProduct>
        <div className="row">
            <If condition={(showUpdateBtn && userData?.userDataState?.cliente === 0 && detailsData?.status_chamado?.value === 'Agendado')}>
                <div className="col-6">
                    <button type="button" className="btn btn-primary" onClick={() => setIsAddEquipmentOpen(!isAddEquipmentOpen)}>Adicionar Equipamento de Remessa/Spare</button>
                </div>
            </If>
        </div>
        <div className='row'>
            <div className="col-12">
                <PrimeTable
                    columns={equipmentsColumns}
                    rows={equipmentsData}
                    id='equipment_prime_table'
                    needExport={false}
                ></PrimeTable>
            </div>
        </div>
    </Card>);
}