import React, { useEffect, useState } from 'react';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import { getShipmentsByStatus } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import useGenerics from '../../../../hooks/useGenerics';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import If from '../../../../common/Layout/If';
import usePermissions from '../../../../hooks/usePermissions';
import PendingTicketsDetails from './PendingTicketsDetails';

export default function PendingTickets() {
    const [data, setData] = useState([]);
    const { checkPermissionGroup } = usePermissions();
    const { openNewTab } = useGenerics();
    const [pendingTicketId, setPendingTicketId] = useState('');
    const [viewMode, setViewMode] = useState('view');
    const [openPendingTicketModal, setOpenPendingTicketModal] = useState(false);
    const [updateLogistic, setUpdateLogistic] = useState(false);

    const [loading, setLoading] = useState(true);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <button type="button" className="dropdown-item" onClick={() => openPendingTicketsDetailsModal(row.id_produto, 'view')}>Ver</button>
                        <If condition={checkPermissionGroup(["logistica_gestao"])}>
                            <button type="button" className="dropdown-item" onClick={() => openPendingTicketsDetailsModal(row.id_produto, 'edit')}>Editar</button>
                        </If>
                    </div>
                </div>
            )
        },
        {
            id: 'nome_produto',
            name: 'Nome do Produto',
        },
        {
            id: 'id_localidade_produto',
            name: 'ID da Localidade\\Num. Chamado',
            body: (row) => (<a onClick={() => openNewTab('/chamados/detalhes-chamado/' + row.id_chamado_produto)} href="javascript:void(0)">{row.id_localidade_produto}</a>)
        },
        {
            id: 'urgencia_produto',
            name: 'Urgência',

        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
        }
    ];

    async function getPendingProducts(page = 1, perPage = 10) {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getShipmentsByStatus('PRODUTO PENDENTE', page, perPage);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });

            let products = dataReturn?.data?.data?.data || [];
            let productData = products.map((item, idx) => ({
                id_produto: item?.id || '',
                fabricante_produto: item?.partnumber?.manufacturer?.nome_fabricante || '',
                nome_produto: item?.partnumber?.modelo || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                id_localidade_produto: item?.ticket?.num_chamado || '',
                id_chamado_produto: item?.ticket?.id || '',
                urgencia_produto: item?.urgencia_remessa || ''
            }));

            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setData([]);
        }
        setLoading(false);
    }

    function openPendingTicketsDetailsModal(id, mode){
        setPendingTicketId(id);
        setOpenPendingTicketModal(true);
        setViewMode(mode);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getPendingProducts(newPage + 1, perPage);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getPendingProducts(1, rows);
    }

    useEffect(() => {
        getPendingProducts();
    }, []);

    return (<Content headerTitle='Chamados Pendentes'>
        <PendingTicketsDetails isOpen={openPendingTicketModal} setIsOpen={()=>setOpenPendingTicketModal(!openPendingTicketModal)} shipmentId={pendingTicketId} mode={viewMode} setUpdateLogistic={setUpdateLogistic} setUpdateShipment={() => getPendingProducts(page + 1, perPage)}></PendingTicketsDetails>
        <SubHeaderSpecific subHeaderConfig='mam_logistica' updateLogistic={updateLogistic} setUpdateLogistic={setUpdateLogistic}></SubHeaderSpecific>
        <Card title='Cadastro de Produtos - Chamados Pendentes'>
            <PrimeTable
                columns={columns}
                rows={data}
                serverPagination={true}
                handlePagination={handlePaginationPrime}
                handleRowPerPage={handleRowPerPagePrime}
                count={paginationTotalRows}
                page={page}
                rowsPerPage={perPage}
                loading={loading}
            ></PrimeTable>
        </Card>
    </Content>);
}