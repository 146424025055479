import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { deleteLendingEquipmentById, getLendingEquipmentsByEmployeeId } from '../../../../../services/Employee';
import CreateLendingEquipment from './CreateLendingEquipment';
import PrimeTable from '../../../../../common/Layout/Table/PrimeTable';

export default function LendingEquipmentTable({updateData = false, id = ''}) {

    const [lendingEquipmentData, setLendingEquipmentData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const itensComodatoColumns = [
        {
            id: 'item_comodato_comodato',
            name: 'Item/Comodato',
        },
        {
            id: 'serial_comodato',
            name: 'Serial'
        },
        {
            id: 'observacao_comodato',
            name: 'Observação',
        },
        {
            id: 'action_comodato',
            name: 'Ação',
            body: (row) => (
                <div className='align-items-center'>
                    <button type="button" className='btn btn-warning' onClick={() => showDeleteLendingEquipmentSwal(row.id_comodato)} disabled={updateData}><i className='fas fa-trash'></i></button>
                </div>
            ),
        }
    ];

    async function showDeleteLendingEquipmentSwal(id) {
        let response = await Swal.fire({
            titleText: 'Deseja realmente remover este equipamento?',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não'
        })

        if (response.isConfirmed) {
            deleteLendingEquipment(id);
        }
    }

    async function deleteLendingEquipment(id) {
        const toastData = toast.loading("Removendo equipamento, aguarde...");

        let dataReturn = await deleteLendingEquipmentById(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastData, { render: 'Equpamento Removido com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getLendingEquipments();
        } else {
            toast.update(toastData, { render: 'Ocorreu um erro ao remover o equipamento!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function getLendingEquipments() {
        const toastData = toast.loading("Carregando equipamentos, aguarde...");

        let dataReturn = await getLendingEquipmentsByEmployeeId(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastData, { render: 'Equipamentos encontrados!', type: "success", isLoading: false, autoClose: 1500 });
            let data = dataReturn?.data?.data.map((item, idx) => {
                return ({
                    id_comodato: item?.id || '',
                    item_comodato_comodato: item?.item_comodato || '',
                    serial_comodato: item?.serial_item_comodato || '',
                    observacao_comodato: item?.obs_item_comodato || ''
                })
            });

            setLendingEquipmentData(data);
        } else {
            toast.update(toastData, { render: 'Ocorreu um erro ao buscar os equipamentos!', type: "success", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(()=>{
        getLendingEquipments();
    },[id]);

    return (
        <div className="row">
            <div className="col-12">
                <h5><i className='fas fa-dot-circle'></i> Itens e Comodatos</h5>
                <button type="button" className="btn btn-primary" onClick={()=>setIsOpen(!isOpen)} disabled={updateData}>Cadastrar</button>
            </div>
            <div className="col-12">
                <PrimeTable
                    columns={itensComodatoColumns}
                    rows={lendingEquipmentData}
                    id='itens_comodato'
                ></PrimeTable>
            </div>
            <CreateLendingEquipment idTecnico={id} isOpen={isOpen} setIsOpen={()=>setIsOpen(!isOpen)} setUpdateParent={getLendingEquipments}></CreateLendingEquipment>
        </div>);
}