import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../App';
import { createChannel, getChannelsByTicketId, getChannelsListByUserId } from '../../services/Chat';
import Contacts from './Contacts';
import ChannelData from './ChannelData';
import { toast } from 'react-toastify';
import SearchField from './SearchField';
import echo from '../../services/LaravelEcho';
import { ChatContext } from './MainChatComponent';
import useChat from '../../hooks/useChat';
import { getTicketById } from '../../services/Ticket';

export default function ChannelsChat({ style = {}, openChat }) {
    const initialState = {
        type: '',
        users: [],
        ticket_id: null,
        channel_description: '',
        channel_name: ''
    }

    const userData = useContext(Context);
    const {chatState, setChatState} = useContext(ChatContext);
    const { getChannelName, getChannelImage } = useChat();
    const [channelArray, setChannelArray] = useState([]);
    const [filteredChannelArray, setFilteredChannelArray] = useState([]);
    const [pendingMessages, setPendingMessages] = useState({ channel: 0, livechat: 0 });
    const [typeChannel, setTypeChannel] = useState("channel");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isChannelDataModalOpen, setIsChannelDataModalOpen] = useState(false);
    const [newChannelFormData, setNewChannelFormData] = useState(initialState);
    const STYLE = {
        ...style
    }

    const createNewChannel = async (formData) => {
        const toastCreateChannel = toast.loading("Criando canal, aguarde...");

        let dataReturn = await createChannel(formData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastCreateChannel, { render: "Canal Criado com Sucesso!", type: "success", isLoading: false, autoClose: 1500 });
            setTypeChannel(dataReturn?.data?.data?.type);
            openChat();
            setChatState(prevState => (
                { 
                    ...prevState,
                    channelId: dataReturn?.data?.data?._id
                }
            ))
        } else {
            toast.update(toastCreateChannel, { render: "Ocorreu um erro ao criar o canal!", type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    const createNewOfficialChannel = async () => {
        let ticketData = await getTicketById(chatState?.ticketId);

        if(ticketData?.data?.response === 'success'){
            let ticket = ticketData?.data?.data;

            if(ticket?.ticket_responsible_user?.id !== userData?.userDataState?.id_usuario){
                return;
            }
            
            let formData = {
                channel_name: 'OFICIAL',
                channel_description: 'Canal Oficial do Chamado',
                type: 'channel',
                ticket_id: chatState?.ticketId,
                users: [userData?.userDataState?.id_usuario]
            }

            if(ticket?.technician?.id_usuario){
                formData.users.push(ticket?.technician?.id_usuario)
            }

            await createNewChannel(formData);
        }else{
            toast.warning("Ocorreu um erro ao buscar os dados do chamado!");
        }
    }

    const getChannels = async () => {
        let channels;
        if(chatState?.ticketId === ''){
            channels = await getChannelsListByUserId();
        }else{
            channels = await getChannelsByTicketId(chatState?.ticketId);
        }

        let countObj = {
            channel: 0,
            livechat: 0
        }

        if (channels?.data?.response === 'success') {
            let channelsData = channels?.data?.data.map((item, idx) => {
                if (item.type === 'channel') {
                    countObj.channel += item.pending_message_count;
                }

                if (item.type === 'livechat') {
                    countObj.livechat += item.pending_message_count;
                }

                return {
                    ...item,
                    channel_name: getChannelName(item)
                };
            })
            setPendingMessages(countObj);
            setChannelArray(channelsData);
            setFilteredChannelArray(channelsData);

            if(channels?.data?.data?.filter(item => (item?.channel_name === 'OFICIAL' && item?.channel_description === 'Canal Oficial do Chamado')).length === 0 && chatState?.ticketId !== ''){
                await createNewOfficialChannel();
                return;
            }
        } else {
            toast.warning("Ocorreu um erro ao procurar os canais!");
        }
    }

    function renderChannelsList() {
        return (filteredChannelArray.filter(item => item.type === typeChannel).map((item, idx) => {

            let usersCount = item.users.length;
            let channelImg = getChannelImage(item.users);

            return (
                <li className={`list-group-item ${chatState?.channelId === item._id ? 'active' : ''}`} aria-current="true" style={{ cursor: 'pointer' }} onClick={() => {
                    openChat()
                    setChatState(prevState => {
                        return {
                        ...prevState,
                        channelId: item._id
                    }})
                    }} key={item._id}>
                    <div className="d-flex flex-row">
                        <div className={`d-flex flex-row ${usersCount < 3 ? '' : 'flex-wrap'} justify-content-center align-items-center`} style={{
                            border: '1px solid gray',
                            borderRadius: '30px',
                            minWidth: '60px',
                            width: '60px',
                            height: '60px',
                            marginRight: '10px',
                            overflow: 'hidden'
                        }}>
                            {channelImg}
                        </div>
                        <div className="text-truncate">
                            <span><b>{item.channel_name}</b> {item.pending_message_count > 0 && <span className="badge badge-warning float-right">{item.pending_message_count}</span>}</span>
                        </div>
                    </div>
                </li>)
        }))
    }

    function setCreateChannelType(type) {
        setIsChannelDataModalOpen(!isChannelDataModalOpen);
        setNewChannelFormData(prevState => ({ ...prevState, type: type }));
    }

    function searchChannels(search) {
        let channelsList = channelArray.filter(item => item.channel_name.toLowerCase().includes(search.toLowerCase()));

        setFilteredChannelArray(channelsList);
    }

    async function startCreateChannel(formData) {

        let params = {
            ...newChannelFormData,
            ...formData,
            ticket_id: formData?.ticket_id?.value || null,
            users: [userData?.userDataState?.id_usuario]
        }

        setNewChannelFormData(prevState => ({
            ...prevState,
            ...params
        }));

        if (newChannelFormData?.type === 'channel') {
            setIsChannelDataModalOpen(!isChannelDataModalOpen);
            setIsModalOpen(!isModalOpen);
        }

        if (newChannelFormData?.type === 'livechat') {
            setIsChannelDataModalOpen(!isChannelDataModalOpen);
            await createNewChannel(params);
        }
    }

    async function appendMembersToChannel(members) {
        if (members?.length === 0) {
            toast.warning("Por favor, selecione pelo menos 1 contato!");
            return;
        }

        let membersId = members.map((item, idx) => {
            return item.id
        })

        let formData = {
            ...newChannelFormData,
            users: [...newChannelFormData.users, ...membersId]
        }

        await createNewChannel(formData);

        setIsModalOpen(!isModalOpen);
    }

    useEffect(() => {
        getChannels();

        const webSocketChannel = echo.channel(`${process.env.REACT_APP_WEBSOCKET_PREFIX}user.${userData?.userDataState?.id_usuario}`);

        webSocketChannel.listen('ChannelUpdate', (e) => {
            if (e.notification === 'channel_update') {
                getChannels();
            }
        })

        return () => {
            webSocketChannel.stopListening(`ChannelUpdate`);
        }
    }, [userData, typeChannel, chatState?.channelId]);

    return (
        <div className='card' style={STYLE}>
            <div className="card-header" style={{ height: '60px' }}>
                <div className="row">
                    <div className="col-4 d-flex justify-content-center">
                        <button type='button' className='btn btn-primary' title='Canais' onClick={() => { setTypeChannel("channel"); setChatState(prevState=>({...prevState, channelId: ''})) }}><i className='fas fa-inbox'></i>{pendingMessages.channel > 0 && <span className="badge badge-warning float-right">{pendingMessages.channel}</span>}</button>
                    </div>
                    <div className="col-4 d-flex justify-content-center">
                        <button type='button' className='btn btn-warning' title='Live Chat' onClick={() => { setTypeChannel("livechat"); setChatState(prevState=>({...prevState, channelId: ''})) }}><i className='fas fa-comments'></i>{pendingMessages.livechat > 0 && <span className="badge badge-warning float-right">{pendingMessages.livechat}</span>}</button>
                    </div>
                    <div className="col-4 d-flex justify-content-center">
                        <div className="dropdown">
                            <button className="btn btn-success btn-block dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false' title='Novo...'><i className='fas fa-plus'></i></button>
                            <div className="dropdown-menu dropdown-menu-right">
                                <button type='button' className='dropdown-item' onClick={() => setCreateChannelType('channel')}>Canal</button>
                                <button type='button' className='dropdown-item' onClick={() => setCreateChannelType('livechat')}>Live Chat</button>
                            </div>
                        </div>
                    </div>
                    <Contacts isOpen={isModalOpen} setModalOpen={() => setIsModalOpen(!isModalOpen)} title='Novo Canal' setMembers={appendMembersToChannel}></Contacts>
                    <ChannelData title={'Novo Canal'} isOpen={isChannelDataModalOpen} setModalOpen={() => setIsChannelDataModalOpen(!isChannelDataModalOpen)} changeChannelData={startCreateChannel} ></ChannelData>
                </div>
            </div>
            <div className="card-header">
                <div className="row">
                    <div className="col-12">
                        <SearchField searchFunction={searchChannels}></SearchField>
                    </div>
                </div>
            </div>
            <div className="card-body" style={{ overflowY: 'auto', marginBottom: '2px' }}>
                <div className='row'>
                    <div className="col-12">
                        <ul className="list-group">
                            {
                                renderChannelsList()
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>)
}