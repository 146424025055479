import React, {useEffect, useState } from 'react';
import Modal from '../../../../common/Layout/Modal';
import { toast } from 'react-toastify';
import { createModel } from '../../../../services/ProductPartnumber';
import FabricanteSelect from '../../../../common/Selects/FabricanteSelect';

export default function CreateModel({ isOpen = false, setIsOpen = null }) {
    const INITIAL_STATE = {
        modelo: '',
        id_fabricante: ''
    };

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function submitForm(e) {
        e.preventDefault();

        const toastProducts = toast.loading('Cadastrando nome do produto, aguarde...');

        let params = {
            ...formData,
            id_fabricante: formData?.id_fabricante?.value || ''
        }

        let dataReturn = await createModel(params);
        
        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Nome do produto cadastrado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setFormData(INITIAL_STATE);
        }else if(dataReturn?.data?.message === 'The modelo has already been taken.'){
            toast.update(toastProducts, { render: 'Este nome do produto já está cadastrado!', type: "warning", isLoading: false, autoClose: 1500 });
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao cadastrar o nome do produto!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(() => {
        if (isOpen) {
            setFormData(INITIAL_STATE);
        }
    }, [isOpen]);

    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={setIsOpen}
            title='Cadastrar Nome do Produto'
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <FabricanteSelect formData={formData} setFormData={setFormData} cols={[12, 6, 6, 6]} fieldName='id_fabricante' title='Fabricante' required={true}></FabricanteSelect>
                    <div className="col-sm-12 col-md-6">
                        <label htmlFor="modelo">Nome do Produto</label>
                        <input type="text" name="modelo" id="modelo" placeholder='Nome do Produto' className="form-control" value={formData?.modelo || ''} onChange={(e) => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))} required />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-1">
                        <button type="submit" className="btn btn-primary">Cadastrar</button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}