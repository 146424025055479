import React, { useEffect, useState } from 'react';
import Modal from '../../../../common/Layout/Modal';
import { getShipmentById, sendForInvoiceIssuance, updatePendingProduct } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import If from '../../../../common/Layout/If';
import AsyncProdutosSelect from '../../../../common/Selects/AsyncProdutosSelect';

export default function PendingTicketsDetails({ isOpen = false, setIsOpen = null, shipmentId = '', mode = 'view', setUpdateShipment = null, setUpdateLogistic = null }) {

    const INITIAL_STATE = {
        nome_produto: '',
        serial: '',
        modelo: '',
        urgencia_remessa: '',
        id_representante: '',
        id_tecnico: '',
        id_chamado: ''
    }

    const [formData, setFormData] = useState(INITIAL_STATE);

    async function getShipment() {
        const toastProducts = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getShipmentById(shipmentId);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let shipmentData = dataReturn?.data?.data;
            setFormData(prevState => ({
                ...prevState,
                ...shipmentData,
            }));

        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setIsOpen(false);
        }
    }

    async function submitForm(e) {
        e.preventDefault();

        let params = {
            ...formData,
            id_produto: formData?.nome_produto?.value || ''
        }

        const toastProducts = toast.loading('Atualizando dados, aguarde...');

        let dataReturn = await updatePendingProduct(shipmentId, params);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados atualizados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            setUpdateShipment();
            setIsOpen(false);
            setUpdateLogistic(true);
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao atualizar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
        }

    }

    useEffect(() => {
        if (isOpen && !['', undefined, null].includes(shipmentId)) {
            setFormData(INITIAL_STATE);
            getShipment();
        }
    }, [isOpen, shipmentId]);

    return (
        <Modal
            isOpen={isOpen}
            setModalOpen={setIsOpen}
            title='Chamado Pendente'
            customStyle={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90vw'
            }}
        >
            <form onSubmit={submitForm}>
                <div className="row">
                    <div className="col-12">
                        <h5>Preencha os dados de produto abaixo para dar continuidade ao chamado!</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <label>Nome do Produto</label>
                        <input type="text" className="form-control" placeholder='Modelo Produto' value={formData?.partnumber?.modelo || ''} readOnly />
                    </div>
                    <AsyncProdutosSelect formData={formData} setFormData={setFormData} fieldName='nome_produto' id_modelo={formData?.partnumber?.id || ''} cols={[12, 6, 6, 6]} required={true} isDisabled={mode === 'view'}></AsyncProdutosSelect>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <label>Urgência da Remessa</label>
                        <input type="text" className="form-control" placeholder='Urgência da Remessa' value={formData?.urgencia_remessa || ''} readOnly />
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <label>Tipo de Remessa</label>
                        <input type="text" className="form-control" placeholder='Tipo de Remessa' value={formData?.tipo_remessa || ''} readOnly />
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <label>ID da Localidade\ID do Chamado</label>
                        <input type="text" className="form-control" placeholder='ID da Localidade\ID do Chamado' value={formData?.ticket?.num_chamado || ''} readOnly />
                    </div>
                    <If condition={formData?.tipo_remessa === 'ENVIO TÉCNICO'}>
                        <div className="col-md-6 col-sm-12">
                            <label>Representante</label>
                            <input type="text" className="form-control" placeholder='Representante' value={formData?.ticket?.partner?.nome_representante || ''} readOnly />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <label>Técnico</label>
                            <input type="text" className="form-control" placeholder='Representante' value={formData?.ticket?.technician?.nome_tec || ''} readOnly />
                        </div>
                    </If>
                </div>
                <If condition={mode === 'edit'}>
                    <div className="row mt-1">
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary">Enviar</button>
                        </div>
                    </div>
                </If>
            </form>
        </Modal>)
}
