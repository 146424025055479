import React, { useEffect, useState } from 'react';
import If from '../../../../../common/Layout/If';
import { getProductHistory } from '../../../../../services/Product';

export default function Timeline({ idProduto = '', updateTimeline = false, setUpdateTimeline = null}) {
    const [timelineData, setTimelineData] = useState([]);

    function handleTimelineActions(id) {
        setTimelineData(prevState => prevState.map(item => item?.id === id ? { ...item, showInfo: !item?.showInfo } : item));
    }

    async function getTimelineData() {
        let dataReturn = await getProductHistory(idProduto);

        if (dataReturn?.data?.response === 'success') {
            let timeline = dataReturn?.data?.data?.map((item, idx) => {

                let detalhesAcao = ''

                for (const [key, value] of Object.entries(JSON.parse(item?.detalhes_acao))) {
                    detalhesAcao += `"${key}": "${value}" | `;
                }

                detalhesAcao = detalhesAcao.slice(0, -2);

                return ({
                    ...item,
                    detalhes_acao: detalhesAcao,
                    showInfo: false
                })
            });
            setTimelineData(timeline);
        } else {
            setTimelineData([]);
        }
    }

    useEffect(()=>{
        getTimelineData();
    }, []);

    useEffect(()=>{
        if(updateTimeline){
            getTimelineData();
            setUpdateTimeline(false);
        }
    }, [updateTimeline])

    return (<>
        <h5>Timeline</h5>
        <div className="timeline">
            <div className="timeline-border"></div>
            <ul className="timeline-items" id="timeline-items">
                {
                    timelineData.map((item, idx) => (
                        <li className="timeline-item" key={item?.id}>
                            <div className="timeline-item-header" onClick={() => handleTimelineActions(item?.id)} style={{ cursor: 'pointer' }}>
                                <h4 className="timeline-item-title">
                                    <strong>Ação: </strong> {item?.acao_realizada || ''} <i className={`fas ${item?.showInfo ? 'fa-arrow-down' : 'fa-arrow-right'}`}></i>
                                </h4>
                                <span style={{ fontSize: '0.8rem' }}>{item?.data_modificacao || ''}</span>
                            </div>
                            <If condition={item?.showInfo}>
                                <div className="timeline-item-body">
                                    <div className="timeline-item-description">
                                        <strong>Detalhes Alterados: </strong>
                                        <p>{item?.detalhes_acao || ''}</p>
                                    </div>
                                    <div className="timeline-item-description">
                                        <strong>Usuário Responsável: </strong>{item?.user?.nome || ''}
                                    </div>
                                </div>
                            </If>
                            <hr></hr>
                        </li>
                    ))}
            </ul>
        </div>
    </>)

}