import lotteries from '../resources/imgs/marcas/logo_oi.png';
import mcdonalds from '../resources/imgs/marcas/logo_mcdonalds.png';
import bradesco from '../resources/imgs/marcas/logo_bradesco.png';
import gts from '../resources/imgs/marcas/gts.png';
import sencinet from '../resources/imgs/marcas/sencinet.png';
import vivo from '../resources/imgs/marcas/vivo.png';
import banco_brasil from '../resources/imgs/marcas/banco_brasil.jpeg';
import tecban from '../resources/imgs/marcas/tecban.png';

export const iconItems = [
    { 
        type: "tech_waiting_installation", 
        icon: "fa-spinner", 
        title: "Aguardando Técnico Instalação", 
        color: "#0aff12", 
        permission_groups: ["administrador", "acionamento_gestao", "acionamento_supervisao"],
        urlParams: "data_inicial=2023-01-01&status_chamado=Aguardando%20Técnico%20Instalação" 
    },
    { 
        type: "tech_waiting_maintenance", 
        icon: "fa-wrench", 
        title: "Aguardando Manutenção", 
        color: "#0aff12", 
        permission_groups: ["administrador", "acionamento_gestao", "acionamento_supervisao"],
        urlParams: "data_inicial=2023-01-01&status_chamado=Aguardando%20Técnico%20Manutenção" 
    },
    { 
        type: "end_of_service", 
        icon: "fa-briefcase", 
        title: "Aguardando Instalação", 
        color: "#0aff12", 
        permission_groups: ["administrador", "acionamento_gestao", "acionamento_supervisao"],
        urlParams: "data_inicial=2023-01-01&status_chamado=Aguardando%20Devolução%20Equipamento" 
    },
    // { type: "delivered_for_action", icon: "fa-truck", title: "Nao sei 1", color: "#0aff12", urlParams: "" },
    { 
        type: "draft_for_action", 
        icon: "fa-exclamation-triangle", 
        title: "Pendente Agendamento", 
        color: "#fff700", 
        permission_groups: ["administrador", "acionamento_gestao", "acionamento_supervisao"],
        urlParams: "data_inicial=2023-01-01&status_chamado=Agendar&status_chamado=Agendamento%20Sugerido%20(CLI)&status_chamado=Agendamento%20Desejado%20(CLI)&status_chamado=Agendamento%20Aceito%20(CLI)&key_ticket_cliente_null=true&remove_contrato=161139&remove_contrato=160814&remove_contrato=44474" 
    },
    { 
        type: "pending_closure", 
        icon: "fa-exclamation-circle", 
        title: "Pendente Fechamento", 
        color: "#ff0000", 
        permission_groups: ["administrador", "acionamento_gestao", "acionamento_supervisao",],
        urlParams: "data_inicial=2023-01-01&status_chamado=Pendente%20Fechamento" 
    },
    { 
        type: "awaiting_closure", 
        icon: "fa-lock", 
        title: "Aguardando Fechamento", 
        color: "#ffa500", 
        permission_groups: ["administrador", "acionamento_gestao", "acionamento_supervisao",],
        urlParams: "data_inicial=2023-01-01&status_chamado=Aguardando%20Fechamento&status_chamado=Aguardando%20fechamento&gts=false" 
    },
    { 
        type: "manager_approval", 
        icon: "fa-file", 
        title: "Aguardando Gestor", 
        color: "#fff700", 
        permission_groups: ["administrador", "acionamento_gestao", "acionamento_supervisao"],
        urlParams: "data_inicial=2023-01-01&status_chamado=Aprovação%20Do%20Responsável" 
    },
    { 
        type: "pending_doc_validation", 
        icon: "fa-scroll", 
        title: "Documento Pendente Validação", 
        color: "#0aff12", 
        permission_groups: ["administrador", "acionamento_gestao", "acionamento_supervisao"],
        urlParams: "data_inicial=2024-05-01&documentacao_entregue=Pendente%20de%20Validação&status_chamado=Fechado" 
    },
];

export const imageItems = [
    { 
        type: "awaiting_closure_gts", 
        image: gts, 
        title: "GTS", 
        permission_groups: ["administrador", "acionamento_gestao", "acionamento_supervisao"],
        urlParams: "data_inicial=2023-01-01&status_chamado=Aguardando%20Fechamento&status_chamado=Aguardando%20fechamento&gts=true" 
    },
    { 
        type: "lotteries", 
        image: lotteries, 
        title: "Oi", 
        permission_groups: ["administrador", "acionamento_gestao", "acionamento_supervisao"],
        urlParams: "data_inicial=2023-01-01&&status_chamado=Agendar&status_chamado=Agendamento%20Desejado%20(CLI)&status_chamado=Agendamento%20Aceito%20(CLI)&status_chamado=Agendamento%20Sugerido%20(CLI)&key_ticket_cliente_null=true" 
    },
    { 
        type: "sencinet", 
        image: sencinet, 
        title: "Sencinet", 
        permission_groups: ["administrador", "acionamento_gestao", "acionamento_supervisao"],
        urlParams: "data_inicial=2023-01-01&&status_chamado=Agendar&status_chamado=Agendamento%20Desejado%20(CLI)&status_chamado=Agendamento%20Aceito%20(CLI)&status_chamado=Agendamento%20Sugerido%20(CLI)&contrato=161139&integradora=160" 
    },
    { 
        type: "bradesco", 
        image: bradesco, 
        title: "Bradesco", 
        permission_groups: ["administrador", "acionamento_gestao", "acionamento_supervisao"],
        urlParams: "data_inicial=2023-01-01&status_chamado=Agendar&status_chamado=Agendamento%20Desejado%20(CLI)&status_chamado=Agendamento%20Aceito%20(CLI)&status_chamado=Agendamento%20Sugerido%20(CLI)&contrato=44474" 
    },
    { 
        type: "mcdonalds", 
        image: mcdonalds, 
        title: "McDonalds", 
        permission_groups: ["administrador", "acionamento_gestao", "acionamento_supervisao"],
        urlParams: "data_inicial=2023-01-01&status_chamado=Agendar&status_chamado=Agendamento%20Desejado%20(CLI)&status_chamado=Agendamento%20Aceito%20(CLI)&status_chamado=Agendamento%20Sugerido%20(CLI)&contrato=160832&contrato=160814" 
    },
    { 
        type: 'telefonica_vivo', 
        image: vivo, 
        title: 'Telefônica Vivo', 
        permission_groups: ["administrador", "acionamento_gestao", "acionamento_supervisao"],
        urlParams: 'data_inicial=2023-01-01&status_chamado=Aberto'
    },
    { 
        type: 'banco_brasil', 
        image: banco_brasil, 
        title: 'Banco do Brasil', 
        permission_groups: ["administrador", "acionamento_gestao", "acionamento_supervisao"],
        urlParams: 'data_inicial=2023-01-01&status_chamado=Agendar&status_chamado=Agendamento%20Desejado%20(CLI)&status_chamado=Agendamento%20Aceito%20(CLI)&status_chamado=Agendamento%20Sugerido%20(CLI)&contrato=161165'
    },
    { 
        type: 'tecban', 
        image: tecban, 
        title: 'TecBan', 
        permission_groups: ["administrador", "acionamento_gestao", "acionamento_supervisao"],
        urlParams: 'data_inicial=2023-01-01&status_chamado=Agendar&status_chamado=Agendamento%20Desejado%20(CLI)&status_chamado=Agendamento%20Aceito%20(CLI)&status_chamado=Agendamento%20Sugerido%20(CLI)&contrato=160843&contrato=160844'
    },
]