import interceptorInstance from "./Interceptor";

export async function createManufacturer(params){
    try{
        let data = await interceptorInstance.post(`manufacturer/create`, params);
        return data;
    }catch(e){
        return e.response;
    }
}

export async function getManufacturers(){
    try{
        let data = await interceptorInstance.get('ticket/get/manufacturers');
        return data;
    }catch(e){
        return e.response;
    }
}