import React, { useEffect, useState } from 'react';
import { deleteContractValuesById, getContractsValuesByContractId } from '../../../../../services/ContractValues';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import ContractValuesDetails from './ContractValuesDetails';
import If from '../../../../../common/Layout/If';
import usePermissions from '../../../../../hooks/usePermissions';
import CreateContractValues from './CreateContractValues';
import PrimeTable from '../../../../../common/Layout/Table/PrimeTable';

export default function ContractValuesTable({ id_contrato = '', disableData = true }) {
    const [mode, setMode] = useState('update');
    const [data, setData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isCreateValuesOpen, setIsCreateValuesOpen] = useState(false);
    const [idValorContrato, setIdValorContrato] = useState('');
    const { checkPermissionGroup } = usePermissions();

    const columns = [
        {
            id: 'action_valores_contrato',
            name: 'Ação',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" disabled={disableData}>
                        Ações
                    </button>
                    <div className="dropdown-menu">
                        <button type="button" className='dropdown-item' onClick={() => openDetailsModal(row?.id_valores_contrato, 'update')}>Editar</button>
                        <If condition={(checkPermissionGroup(["gerente_parcerias", "diretoria"]) && ['reprovado', 'pendente'].includes(row?.status_aprovacao_contrato))}>
                            <button type="button" className="dropdown-item" onClick={() => openDetailsModal(row?.id_valores_contrato, 'approve')}>Aprovar</button>
                        </If>
                        <button type="button" className='dropdown-item' onClick={() => showDeleteContractValueSwal(row?.id_valores_contrato)}>Excluir</button>
                    </div>
                </div>
            )
        },
        {
            id: 'status_aprovacao_contrato',
            name: 'Status Aprovação',
        },
        {
            id: 'escopo_valores_contrato',
            name: 'Escopo',
        },
        {
            id: 'valor_chamado_valores_contrato',
            name: 'R$ Chamado',
        },
        {
            id: 'valor_fora_valores_contrato',
            name: 'Valor Fora Hrs Comercial',
        },
        {
            id: 'chamado_improdutivo_valores_contrato',
            name: 'Chamado Improdutivo',
        },
        {
            id: 'h_a_diurna_valores_contrato',
            name: 'H.A (Diurna) Após 3h31m',
        },
        {
            id: 'h_a_noturna_valores_contrato',
            name: 'H.A (Noturna) Após 3h31m',
        },
        {
            id: 'valor_apos_51_km_valores_contrato',
            name: 'R$ KM Após 51 Km',
        },
        {
            id: 'observacoes_valores_contrato',
            name: 'Observações',
        },
        {
            id: 'motivo_reprovacao_contrato',
            name: 'Motivo Reprovação',
        }
    ];

    async function getContractsValues() {
        let dataReturn = await getContractsValuesByContractId(id_contrato);

        if (dataReturn?.data?.response === 'success') {
            let contractValues = dataReturn?.data?.data?.map((item, idx) => ({
                id_valores_contrato: item?.id || '',
                escopo_valores_contrato: item?.escopo || '',
                valor_chamado_valores_contrato: item?.valor_chamado || '0,00',
                valor_fora_valores_contrato: item?.valor_fora_hr_comercial || '0,00',
                chamado_improdutivo_valores_contrato: item?.valor_chamado_improdutivo || '0,00',
                h_a_diurna_valores_contrato: item?.valor_ha_diurna || '0,00',
                h_a_noturna_valores_contrato: item?.valor_ha_noturna || '0,00',
                valor_apos_51_km_valores_contrato: item?.valor_km_apos_51 || '0,00',
                observacoes_valores_contrato: item?.observacoes || '',
                status_aprovacao_contrato: item?.aprovado || '',
                motivo_reprovacao_contrato: item?.descricao_reprovado || '',
            }));
            setData(contractValues);
        } else {
            setData([]);
        }
    }

    async function showDeleteContractValueSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja mesmo excluir estes valores?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            cancelButtonColor: 'red',
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showConfirmButton: true
        });

        if (swal.isConfirmed) {
            deleteContractValue(id);
        }
    }

    async function deleteContractValue(id) {
        const toastValues = toast.loading('Excluindo dados, aguarde...');

        let dataReturn = await deleteContractValuesById(id);

        if (dataReturn?.status === 204) {
            toast.update(toastValues, { render: 'Dados excluidos com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getContractsValues();
        } else {
            toast.update(toastValues, { render: 'Ocorreu um erro ao excluir os dados!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    function openDetailsModal(id, mode) {
        setIdValorContrato(id);
        setMode(mode);
        setIsOpen(true);
    }

    useEffect(() => {
        if (!['', undefined, null].includes(id_contrato)) {
            getContractsValues();
        }
    }, [id_contrato]);

    return (<>
        <div className="row">
            <div className="col-12">
                <h5><i className='fas fa-dot-circle'></i> Valores Contrato</h5>
            </div>
            <div className="col-12">
                <button type="button" className="btn btn-primary" disabled={disableData} onClick={()=>setIsCreateValuesOpen(!isCreateValuesOpen)}>Cadastrar</button>
            </div>
            <div className="col-12">
                <PrimeTable
                    columns={columns}
                    rows={data}
                    id="contract_values_prime_table"
                ></PrimeTable>
            </div>
        </div>
        <CreateContractValues isOpen={isCreateValuesOpen} setIsOpen={() => setIsCreateValuesOpen(!isCreateValuesOpen)} id_contrato={id_contrato} updateParent={getContractsValues}></CreateContractValues>
        <ContractValuesDetails isOpen={isOpen} setModalOpen={() => setIsOpen(!isOpen)} id_valor_contrato={idValorContrato} updateParent={getContractsValues} mode={mode}></ContractValuesDetails>
    </>)

}