import React, { useEffect, useState } from 'react';
import If from '../../../../common/Layout/If';
import AddGed from './AddGed';
import Table from '../../../../common/Layout/Table/Table';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { deleteGed, getFilesBySubmodule } from '../../../../services/Ged';

export default function GedTable({
    submodule = '',
    viewMode = 'view',
    idProduto = '',
    addTitle = '',
}) {
    const [addGedOpen, setAddGedOpen] = useState(false);
    const [gedTableData, setGedTableData] = useState([]);

    const columns = [
        {
            id: 'id_ged',
            name: 'ID',
            selector: row => row.id_ged,
            sortable: true,
            wrap: true
        },
        {
            id: 'action_ged',
            name: 'Ações',
            button: "true",
            cell: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <If condition={viewMode === 'edit'}>
                            <button className='dropdown-item' onClick={() => showDeleteGed(row.id_ged)}>Deletar</button>
                        </If>
                        <a role='button' href={`${row?.url_ged}`} className='dropdown-item' target='_blank' download={true}>Download</a>
                    </div>
                </div>
            ),
        },
        {
            id: 'titulo_ged',
            name: 'Título',
            selector: row => row.titulo_ged,
            sortable: true,
            wrap: true
        },
        {
            id: 'nome_arquivo_ged',
            name: 'Nome Arquivo',
            selector: row => row.nome_arquivo_ged,
            sortable: true,
            wrap: true
        },
        {
            id: 'data_modificacao_ged',
            name: 'Data Modificação',
            selector: row => row.data_modificacao_ged,
            sortable: true,
            wrap: true
        },
        {
            id: 'responsavel_ged',
            name: 'Responsável',
            selector: row => row.responsavel_ged,
            sortable: true,
            wrap: true
        },
    ];

    async function showDeleteGed(id) {
        let swal = await Swal.fire({
            title: 'Deseja realmente excluir o documento?',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            deleteGedData(id);
        }
    }

    async function deleteGedData(id) {
        const toastProducts = toast.loading("Excluindo documento, aguarde...");

        let dataReturn = await deleteGed(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Documento excluido com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getGedData();
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao excluir o documento!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function getGedData() {
        let dataReturn = await getFilesBySubmodule(submodule, idProduto);

        if (dataReturn?.data?.response === 'success') {
            let data = dataReturn?.data?.data?.map((item, idx) => ({
                id_ged: item?.id || '',
                url_ged: item?.url_arquivo || '',
                titulo_ged: item?.titulo || '',
                nome_arquivo_ged: item?.nome_original || '',
                data_modificacao_ged: item?.atualizado || '',
                responsavel_ged: item?.user?.nome || ''
            }));
            setGedTableData(data);
        }
    }

    useEffect(()=>{
        if(idProduto !== ''){
            getGedData();
        }
    }, [idProduto]);

    return (
        <>
            <If condition={viewMode === 'edit'}>
                <AddGed isOpen={addGedOpen} setModalOpen={() => setAddGedOpen(!addGedOpen)} productId={idProduto} setUpdateData={getGedData} submodule={submodule}></AddGed>
                <div>
                    <h5>{addTitle}</h5>
                    <button type="button" className="btn btn-primary text-center" onClick={() => setAddGedOpen(!addGedOpen)}><i className='fas fa-plus'></i>Adicionar</button>
                </div>
                <hr></hr>
            </If>
            <div>
                <h5>Lista de Documentos</h5>
                <Table columns={columns} data={gedTableData} id='lista_documentos_table'></Table>
            </div>
        </>
    )
}