import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../common/Layout/Card';
import ContratosMultiSelect from '../../../common/Selects/ContratosMultiSelect';
import UsuariosAtivosSelect from '../../../common/Selects/UsuariosAtivosSelect';
import { toast } from 'react-toastify';
import { getGroupDetailsById, removeContractFromSupportGroup, updateSupportGroup } from '../../../services/SupportGroup';
import { useNavigate, useParams } from 'react-router-dom';
import CustomMessageData from '../../../common/Layout/CustomMessageData';
import Table from '../../../common/Layout/Table/Table';
import Swal from 'sweetalert2';
import If from '../../../common/Layout/If';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';

export default function TicketGroupDetails() {
    const { id } = useParams();
    const navigate = useNavigate();

    const initialState = {
        id_usuario: '',
        contratos: '',
        nome: '',
        descricao: ''
    }

    const [formData, setFormData] = useState(initialState);
    const [contractsTableData, setContractTableData] = useState([]);

    const columns = [
        {
            id: 'contratos_grupo_atendimento',
            name: 'Contratos',
        },
        {
            id: 'action_grupo_atendimento',
            name: 'Ação',
            body: (row) => (
                <div>
                    <button type="button" className='btn btn-warning m-1 d-inline' onClick={() => showDeleteContractSwal(row.id_contrato_grupo_atendimento)}><i className='fas fa-trash'></i></button>
                </div>
            ),
        }
    ]

    async function submitForm(e) {
        e.preventDefault();

        let contratos = [];

        if(formData?.contratos?.length > 0){
            contratos = formData?.contratos?.filter(item => item.value !== 'todos').map((item, idx) => {
                return item.value;
            }) || [];
        }

        let params = {
            ...formData,
            contratos: contratos,
            id_usuario: formData?.id_usuario?.value || '',
            nome: formData?.id_usuario?.label || ''
        }

        const toastSubmit = toast.loading("Atualizando dados, aguarde...");

        let dataReturn = await updateSupportGroup(id, params);
        if (dataReturn?.data?.response === 'success') {
            toast.update(toastSubmit, { render: 'Grupo de atendimento atualizado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getGroupDetails();
            setFormData(prevState => ({...prevState, contratos: ''}));
        } else if (dataReturn?.data?.message === 'The id usuario has already been taken.') {
            toast.update(toastSubmit, { render: 'Este usuario já possui um grupo de atendimento!', type: "warning", isLoading: false, autoClose: 1500 });
        } else {
            toast.update(toastSubmit, { render: 'Ocorreu um erro ao atualizar o grupo de atendimento!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    const getGroupDetails = async () => {
        const toastDetails = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getGroupDetailsById(id);

        if (dataReturn?.data?.response === 'success') {
            let dados = dataReturn?.data?.data;
            let contratos = dataReturn?.data?.data?.contracts?.map((item, idx) => {
                return ({
                    id_contrato_grupo_atendimento: item?.id,
                    contratos_grupo_atendimento: item?.contrato
                });
            }) || [];

            toast.update(toastDetails, { render: 'Dados encontrados!', type: "success", isLoading: false, autoClose: 1500 });
            setFormData(prevState => ({
                ...prevState,
                id_usuario: { value: dados?.id_usuario || '', label: dados?.nome_grupo || '' },
                descricao: dados?.descricao || ''
            }));

            if (contratos?.length > 0) {
                setContractTableData(contratos);
            } else {
                setContractTableData([]);
            }

        } else if (dataReturn?.data?.message === 'Group not found!') {
            toast.update(toastDetails, { render: 'Este registro não existe!', type: "warning", isLoading: false, autoClose: 1500 });
            navigate('/chamados/grupos-atendimento/dados');
        } else {
            toast.update(toastDetails, { render: 'Ocorreu um erro ao buscar o grupo de atendimento!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function showDeleteContractSwal(id) {
        let response = await Swal.fire({
            titleText: 'Deseja realmente excluir este contrato?',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não'
        })

        if (response.isConfirmed) {
            deleteContract(id);
        }
    }

    async function deleteContract(idContrato) {

        let formData = {
            contratos: []
        }

        formData.contratos.push(idContrato);

        const toastUsers = toast.loading("Excluindo contrato, aguarde...");

        let dataReturn = await removeContractFromSupportGroup(id, formData);

        if(dataReturn?.data?.response === 'success'){
            toast.update(toastUsers, { render: 'Contrato excluido com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getGroupDetails();
        }else{
            toast.update(toastUsers, { render: 'Ocorreu um erro ao excluir o contrato!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(() => {
        getGroupDetails();
    }, []);

    return (<Content headerTitle='Detalhes do Grupo de Atendimento'>
        <SubHeaderSpecific subHeaderConfig='grupos_atendimento'></SubHeaderSpecific>
        <Card hideCardHeader={true}>
            <form onSubmit={submitForm}>
            <div className="row">
                <div className="col-12">
                    <h5><i className='fas fa-dot-circle'></i> Integradora/Contrato</h5>
                </div>
                <UsuariosAtivosSelect formData={formData} setFormData={setFormData} fieldName='id_usuario' cols={[12, 12, 6, 6]} required={true}></UsuariosAtivosSelect>
                <ContratosMultiSelect formData={formData} setFormData={setFormData} fieldName='contratos' id_integradora={[]} cols={[12, 12, 6, 6]}></ContratosMultiSelect>
            </div>
            <div className="row">
                <div className="col-12">
                    <h5><i className='fas fa-dot-circle'></i> Contratos do Grupo de Contrato</h5>
                </div>
                <div className="col-12">
                    <PrimeTable
                        columns={columns}
                        rows={contractsTableData}
                        id='contratos_table'
                    ></PrimeTable>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <h5><i className='fas fa-dot-circle'></i> Detalhamento</h5>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label htmlFor="descricao">Observações</label>
                        <textarea rows={5} className="form-control" name="descricao" id="descricao" placeholder='...' value={formData.descricao} onChange={e => setFormData(prevState => ({ ...prevState, [e.target.name]: e.target.value }))}></textarea>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <button type="submit" className='btn btn-primary'>Salvar</button>
                </div>
            </div>
            </form>
        </Card>
    </Content>)
}