import React, { useContext, useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import Card from '../../../common/Layout/Card';
import CustomMessageData from '../../../common/Layout/CustomMessageData';
import { getExpensesByStatusTechnician } from '../../../services/EmployeeExpense';
import { toast } from 'react-toastify';
import { getEmployeeById } from '../../../services/Employee';
import ApproveExpensesModal from './ApproveExpensesModal';
import CreateRefund from './CreateRefund';
import useGenerics from '../../../hooks/useGenerics';
import usePermissions from '../../../hooks/usePermissions';
import { Context } from '../../../App';
import If from '../../../common/Layout/If';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';

export default function EmployeeRefundsDetails() {
    const INITIAL_STATE = {
        data_inicial: '',
        data_final: '',
        status: ''
    }

    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [modalParams, setModalParams] = useState({});
    const [modalTitle, setModalTitle] = useState('');
    const [formData, setFormData] = useState(INITIAL_STATE);
    const [technicianData, setTechnicianData] = useState(null);
    const [expensesTableData, setExpensesTableData] = useState(null);
    const { getCurrentDate, checkDateBetween } = useGenerics();
    const { checkPermissionGroup } = usePermissions();
    const userData = useContext(Context);

    async function getTechnicianData({ data_inicial = '', data_final = '' }) {
        let dataReturn = await getEmployeeById(id);

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data !== null) {
            let employeeData = dataReturn?.data?.data;
            setTechnicianData(employeeData);

        } else if (dataReturn?.data?.data === null) {
            toast.warning('Este técnico não existe!');
            navigate("/faturamento/reembolso/dados");
            return;
        }
        getExpenses({ data_inicial: data_inicial, data_final: data_final, status_reembolso: 'pendente' });
        getExpenses({ data_inicial: data_inicial, data_final: data_final, status_reembolso: 'aprovado' });
        getExpenses({ data_inicial: data_inicial, data_final: data_final, status_reembolso: 'rejeitado' });
        getExpenses({ data_inicial: data_inicial, data_final: data_final, status_reembolso: 'cancelado' });
    }

    async function getExpenses(formData) {
        let dataReturn = await getExpensesByStatusTechnician(formData?.data_inicial, formData?.data_final, formData?.status_reembolso, id);

        if (dataReturn?.data?.response === 'success') {
            let data = dataReturn?.data?.data?.map((item, idx) => {
                return ({
                    ['id_chamado_reembolso_' + formData?.status_reembolso]: item?.ticket?.id || '',
                    ['chamado_reembolso_' + formData?.status_reembolso]: item?.ticket?.chamado || '',
                    ['integradora_reembolso_' + formData?.status_reembolso]: item?.ticket?.contract?.integrator?.nome || '',
                    ['contrato_reembolso_' + formData?.status_reembolso]: item?.ticket?.contract?.contrato || '',
                    ['ocasiao_fechamento_reembolso_' + formData?.status_reembolso]: item?.ticket?.ocasiao_fechamento || '',
                    ['centro_custo_reembolso_' + formData?.status_reembolso]: item?.ticket?.contract?.centro_custo || '',
                    ['senha_integradora_reembolso_' + formData?.status_reembolso]: item?.ticket?.senha_integradora || '',
                    ['senha_maminfo_reembolso_' + formData?.status_reembolso]: item?.ticket?.senha_atendimento || '',
                    ['data_atendimento_reembolso_' + formData?.status_reembolso]: item?.ticket?.data_inicio_atendimento || '',
                    ['dia_semana_reembolso_' + formData?.status_reembolso]: item?.ticket?.dia_semana || '',
                    ['hora_inicial_reembolso_' + formData?.status_reembolso]: item?.ticket?.hora_inicio_atendimento || '',
                    ['hora_final_reembolso_' + formData?.status_reembolso]: item?.ticket?.hora_encerramento || '',
                    ['qtde_horas_reembolso_' + formData?.status_reembolso]: item?.ticket?.qtd_horas_chamado || '',
                    ['cliente_reembolso_' + formData?.status_reembolso]: item?.ticket?.customer?.nome_cliente || '',
                    ['endereco_partida_reembolso_' + formData?.status_reembolso]: `${item?.ticket?.start_address?.logradouro_endereco_partida || ''}, ${item?.ticket?.start_address?.bairro_endereco_partida || ''}, ${item?.ticket?.start_address?.compl_ref_obs_endereco_partida || ''}, ${item?.ticket?.start_address?.cidade_endereco_partida || ''} - ${item?.ticket?.start_address?.estado_endereco_partida || ''}, ${item?.ticket?.start_address?.cep_endereco_partida || ''}`,
                    ['endereco_chegada_reembolso_' + formData?.status_reembolso]: `${item?.ticket?.customer?.endereco_cliente || ''}, ${item?.ticket?.customer?.bairro_cliente || ''}, ${item?.ticket?.compl_ref_obs_endereco_cliente || ''}, ${item?.ticket?.customer?.cidade_cliente || ''} - ${item?.ticket?.customer?.estado_cliente || ''}, ${item?.ticket?.customer?.cep_cliente || ''}`,
                    ['cidade_atividade_reembolso_' + formData?.status_reembolso]: `${item?.ticket?.customer?.cidade_cliente || ''} - ${item?.ticket?.customer?.estado_cliente || ''}`,
                    ['km_planejado_reembolso_' + formData?.status_reembolso]: item?.ticket?.km_esperado || '',
                    ['km_reembolso_' + formData?.status_reembolso]: item?.ticket?.km_real || '',
                    ['pedagios_reembolso_' + formData?.status_reembolso]: `R$ ${item?.pedagio || '0.00'}`,
                    ['estacionamento_reembolso_' + formData?.status_reembolso]: `R$ ${item?.estacionamento || '0.00'}`,
                    ['hoteis_reembolso_' + formData?.status_reembolso]: `R$ ${item?.hospedagem || '0.00'}`,
                    ['refeicoes_reembolso_' + formData?.status_reembolso]: `R$ ${item?.refeicao || '0.00'}`,
                    ['materiais_reembolso_' + formData?.status_reembolso]: `R$ ${item?.material || '0.00'}`,
                    ['combustivel_reembolso_' + formData?.status_reembolso]: `R$ ${item?.combustivel || '0.00'}`,
                    ['outros_reembolso_' + formData?.status_reembolso]: `R$ ${item?.outros || '0.00'}`,
                });
            })
            setExpensesTableData(prevState => ({ ...prevState, [formData?.status_reembolso]: data }));
        } else {
            toast.warning('Ocorreu um erro ao procurar os dados!');
        }
    }

    function checkValidateCondition(status) {
        return status !== 'cancelado' && (checkPermissionGroup(['administrador', 'faturamento']) || ([technicianData?.manager?.id, technicianData?.manager?.maminfo_manager?.id, technicianData?.manager?.partner_manager?.id].includes(userData?.userDataState?.id_usuario)))
    }

    function renderExpenseData(title, type) {
        let columns = [
            {
                id: 'action_reembolso_' + type,
                name: 'Ação',
                body: (row) => (
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                        <div className="dropdown-menu">
                            <button type="button" className="dropdown-item" onClick={() => openApproveCostsModal(row?.['id_chamado_reembolso_' + type], row?.['chamado_reembolso_' + type], type, title)}>{checkValidateCondition(type) ? 'Validar' : 'Visualizar'}</button>
                            {
                                (type === 'pendente' && checkDateBetween(formData?.data_inicial, formData?.data_final, getCurrentDate()) && (checkPermissionGroup(['administrador']) || ([technicianData?.manager?.id, technicianData?.manager?.maminfo_manager?.id, technicianData?.manager?.partner_manager?.id].includes(userData?.userDataState?.id_usuario)))) &&
                                (<button type="button" className="dropdown-item" onClick={() => openCreateRefundModal(row?.['id_chamado_reembolso_' + type], row?.['chamado_reembolso_' + type], technicianData?.nome_tec)}>Adicionar</button>)
                            }
                        </div>
                    </div>
                )
            },
            {
                id: 'chamado_reembolso_' + type,
                name: 'Chamado',
            },
            {
                id: 'integradora_reembolso_' + type,
                name: 'Integradora',
            },
            {
                id: 'contrato_reembolso_' + type,
                name: 'Contrato',
            },
            {
                id: 'ocasiao_fechamento_reembolso_' + type,
                name: 'Ocasião Fechamento',
            },
            {
                id: 'centro_custo_reembolso_' + type,
                name: 'Centro de Custo',
            },
            {
                id: 'senha_integradora_reembolso_' + type,
                name: 'Senha Integradora',
            },
            {
                id: 'senha_maminfo_reembolso_' + type,
                name: 'Senha Maminfo',
            },
            {
                id: 'data_atendimento_reembolso_' + type,
                name: 'Data de Reembolso',
            },
            {
                id: 'dia_semana_reembolso_' + type,
                name: 'Dia da Semana',
            },
            {
                id: 'hora_inicial_reembolso_' + type,
                name: 'Hora Inicial do Atendimento',
            },
            {
                id: 'hora_final_reembolso_' + type,
                name: 'Hora Final do Atendimento',
            },
            {
                id: 'qtde_horas_reembolso_' + type,
                name: 'Qtde Horas',
            },
            {
                id: 'cliente_reembolso_' + type,
                name: 'Cliente',
            },
            {
                id: 'endereco_partida_reembolso_' + type,
                name: 'Endereço de Partida',
            },
            {
                id: 'endereco_chegada_reembolso_' + type,
                name: 'Endereço de Chegada',
            },
            {
                id: 'cidade_atividade_reembolso_' + type,
                name: 'Cidade da Atividade',
            },
            {
                id: 'km_planejado_reembolso_' + type,
                name: 'KM Planejado',
            },
            {
                id: 'km_reembolso_' + type,
                name: 'KM',
            },
            {
                id: 'pedagios_reembolso_' + type,
                name: 'Pedágios',
            },
            {
                id: 'estacionamento_reembolso_' + type,
                name: 'Estacionamentos',
            },
            {
                id: 'hoteis_reembolso_' + type,
                name: 'Hotéis (Com autorização)',
            },
            {
                id: 'refeicoes_reembolso_' + type,
                name: 'Refeições (Com autorização)',
            },
            {
                id: 'materiais_reembolso_' + type,
                name: 'Materiais (Com autorização)',
            },
            {
                id: 'combustivel_reembolso_' + type,
                name: 'Combustível',
            },
            {
                id: 'outros_reembolso_' + type,
                name: 'Outros (Com autorização)',
            }
        ];

        return (
            <Card title={title} icon='fa-dot-circle'>
                <If condition={expensesTableData?.[type]?.length > 0}>
                    <PrimeTable
                        columns={columns}
                        rows={expensesTableData?.[type]}
                        id={'reembolso_table_' + type}
                    ></PrimeTable>
                </If>
                <If condition={expensesTableData?.[type]?.length === 0}>
                    <CustomMessageData message='Sem dados'></CustomMessageData>
                </If>
            </Card>
        );
    }

    function openApproveCostsModal(ticketId, ticketNumber, status, title) {
        setModalParams({
            ticketId: ticketId,
            technicianId: id,
            status: status,
            ticketNumber: ticketNumber,
            data_inicial: formData?.data_inicial,
            data_final: formData?.data_final,
            type: checkValidateCondition(status) ? 'validate' : 'view'
        });
        setModalTitle(title);
        setIsModalOpen(prevState => (!prevState));
    }

    function openCreateRefundModal(ticketId = '', ticketNumber = '', technicianName = '', type = 'specific_ticket') {
        setModalParams({
            ticketId: ticketId,
            ticketNumber: ticketNumber,
            technicianId: id,
            technicianName: technicianName,
            type: type
        });
        setIsCreateModalOpen(prevState => (!prevState));
    }

    function updateAllExpenses(){
        ['pendente', 'aprovado', 'rejeitado', 'cancelado'].forEach((item, idx)=>{
            getExpenses({ data_inicial: formData?.data_inicial, data_final: formData?.data_final, status_reembolso: item });
        })
    }

    useEffect(() => {
        let urlData = new URLSearchParams(location.search);

        if (urlData.get('data_inicial') !== null && urlData.get('data_final') !== null && ![undefined, null, ''].includes(id)) {
            setFormData({ data_inicial: urlData.get('data_inicial'), data_final: urlData.get('data_final') });
            getTechnicianData({ data_inicial: urlData.get('data_inicial'), data_final: urlData.get('data_final') });
        }

    }, [id, location]);

    return (<Content headerTitle={`Detalhes - ${technicianData?.nome_tec || ''}`}>
        <SubHeaderSpecific subHeaderConfig='mam_tecnicos'></SubHeaderSpecific>
        <ApproveExpensesModal isOpen={isModalOpen} setModalOpen={() => setIsModalOpen(prevState => (!prevState))} title={modalTitle} setUpdateNewData={updateAllExpenses} params={modalParams}></ApproveExpensesModal>
        <CreateRefund isOpen={isCreateModalOpen} setIsOpen={() => setIsCreateModalOpen(prevState => (!prevState))} params={modalParams} setUpdateNewData={updateAllExpenses}></CreateRefund>
        <Card title='Datas da Semana' icon='fa-dot-circle'>
            <div className='row'>
                <div className="col-sm-12 col-md-4">
                    <strong>Data Inicial: </strong>{formData?.data_inicial}
                </div>
                <div className="col-sm-12 col-md-4">
                    <strong>Data Final: </strong>{formData?.data_final}
                </div>
                <If condition={(checkDateBetween(formData?.data_inicial, formData?.data_final, getCurrentDate()) && (checkPermissionGroup(['administrador']) || ([technicianData?.manager?.id, technicianData?.manager?.maminfo_manager?.id, technicianData?.manager?.partner_manager?.id].includes(userData?.userDataState?.id_usuario))))}>
                    <button type="button" className="btn btn-primary" onClick={() => openCreateRefundModal('', '', technicianData?.nome_tec, 'all_tickets')}>Cadastrar Reembolso</button>
                </If>
            </div>
        </Card>
        <Card title={`Detalhes - ${technicianData?.nome_tec || ''}`} icon='fa-dot-circle'>
            <div className="row">
                <div className='col-12'>
                    <h5>Dados Pessoais</h5>
                </div>
                <div className="col-sm-12 col-md-6">
                    <strong>Nome: </strong>{technicianData?.nome_tec || ''}
                </div>
                <div className="col-sm-12 col-md-6">
                    <strong>Representante: </strong>{technicianData?.partners?.[0]?.nome_representante || 'MAMINFO'}
                </div>
                <div className="col-sm-12 col-md-4">
                    <strong>Tipo Contratação: </strong>{technicianData?.modal_contratacao || ''}
                </div>
                <div className="col-sm-12 col-md-4">
                    <strong>Categoria: </strong>{technicianData?.categoria || ''}
                </div>
                <div className="col-sm-12 col-md-4">
                    <strong>CPF/CNPJ: </strong>{technicianData?.cpf_cnpj || ''}
                </div>
                <div className='col-12'>
                    <hr></hr>
                    <h5>Dados Bancários</h5>
                </div>
                <div className="col-sm-12 col-md-4">
                    <strong>Banco: </strong>{technicianData?.banco || ''}
                </div>
                <div className="col-sm-12 col-md-4">
                    <strong>Agencia: </strong>{technicianData?.agencia_banco || ''}
                </div>
                <div className="col-sm-12 col-md-4">
                    <strong>Tipo de Conta: </strong>{technicianData?.tipo_conta_banco || ''}
                </div>
                <div className="col-sm-12 col-md-4">
                    <strong>Número: </strong>{technicianData?.num_conta_banco || ''}
                </div>
                <div className="col-sm-12 col-md-4">
                    <strong>Titular: </strong>{technicianData?.titular_banco2 || ''}
                </div>
                <div className="col-sm-12 col-md-4">
                    <strong>CPF Titular: </strong>{technicianData?.cpf_titular2 || ''}
                </div>
            </div>
        </Card>
        {
            renderExpenseData('Reembolsos Pendentes', 'pendente')
        }
        {
            renderExpenseData('Reembolsos Aprovados', 'aprovado')
        }
        {
            renderExpenseData('Reembolsos Rejeitados', 'rejeitado')
        }
        {
            renderExpenseData('Reembolsos Cancelados', 'cancelado')
        }
    </Content>);
}