import React, { useEffect, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import { changeShipmentStatus, getShipmentsByStatus } from '../../../../services/Shipment';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import ShipmentDetails from './ShipmentDetails';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import If from '../../../../common/Layout/If';
import usePermissions from '../../../../hooks/usePermissions';
import useGenerics from '../../../../hooks/useGenerics';

export default function ProductsToSend() {

    const [data, setData] = useState([]);
    const [productId, setProductId] = useState('');
    const [shipmentDetailsMode, setShipmentDetailsMode] = useState('view');
    const [shipmentDetailsModal, setShipmentDetailsModal] = useState(false);
    const [updateLogistic, setUpdateLogistic] = useState(false);

    const [loading, setLoading] = useState(true);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const { checkPermissionGroup } = usePermissions();
    const { openNewTab } = useGenerics();

    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <button type="button" className="dropdown-item" onClick={() => openShipmentDetailsModal(row.id_produto, 'view')}>Ver</button>
                        <If condition={checkPermissionGroup(["logistica_gestao", "logistica_operacao"])}>
                            <button type='button' className="dropdown-item" onClick={() => openShipmentDetailsModal(row.id_produto, 'edit')}>Editar</button>
                            <If condition={checkPermissionGroup(["logistica_gestao"])}>
                                <button type='button' className="dropdown-item" onClick={() => showCancelShipmentSwal(row.id_produto)}>Cancelar Solicitação</button>
                            </If>
                        </If>
                    </div>
                </div>
            )
        },
        {
            id: 'nome_produto',
            name: 'Nome do Produto',
        },
        {
            id: 'serial_produto',
            name: 'Serial',
        },
        {
            id: 'id_localidade_produto',
            name: 'ID da Localidade\\Num. Chamado',
            body: (row) => (<a onClick={() => openNewTab('/chamados/detalhes-chamado/' + row.id_chamado_produto)} href="javascript:void(0)">{row.id_localidade_produto}</a>)
        },
        {
            id: 'contrato_produto',
            name: 'Contrato\\Projeto'
        },
        {
            id: 'tipo_produto',
            name: 'Tipo do Produto',
        },
        {
            id: 'urgencia_produto',
            name: 'Urgência',
        },
        {
            id: 'modal_envio_produto',
            name: 'Modal de Envio',
        },
        {
            id: 'tipo_remessa_produto',
            name: 'Tipo de Remessa',
        },
        {
            id: 'status_remessa_produto',
            name: 'Status Remessa',
        }
    ];

    async function getProductsToSendByStatus(page = 1, perPage = 10) {
        setLoading(true);
        const toastProducts = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getShipmentsByStatus('AGUARDANDO ENVIO', page, perPage);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });

            let products = dataReturn?.data?.data?.data || [];
            console.log(products);
            let productData = products.map((item, idx) => ({
                id_produto: item?.id || '',
                tipo_produto: item?.product?.nome_produto || '',
                nome_produto: item?.product?.modelo || '',
                serial_produto: item?.product?.serial || '',
                urgencia_produto: item?.urgencia_remessa || '',
                modal_envio_produto: item?.modal_envio || '',
                tipo_remessa_produto: item?.tipo_remessa || '',
                status_remessa_produto: item?.status_remessa || '',
                id_chamado_produto: item?.ticket?.id || '',
                id_localidade_produto: item?.ticket?.num_chamado || '',
                contrato_produto: item?.ticket?.contract?.contrato || ''
            }));
            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setData(productData);
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setData([]);
        }
        setLoading(false);
    }

    function openShipmentDetailsModal(id, mode) {
        setProductId(id);
        setShipmentDetailsMode(mode);
        setShipmentDetailsModal(true);
    }

    async function showCancelShipmentSwal(id) {
        let swal = await Swal.fire({
            title: 'Deseja realmente cancelar a remessa?',
            text: 'O produto voltará para o status anterior ao estoque.',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim'
        });

        if (swal.isConfirmed) {
            cancelShipment(id);
        }
    }

    async function cancelShipment(id) {
        const toastProducts = toast.loading("Cancelando emissão, aguarde...");

        let dataReturn = await changeShipmentStatus(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Emissão cancelada com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getProductsToSendByStatus();
            setUpdateLogistic(true);
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao cancelar a emissão!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getProductsToSendByStatus(newPage + 1, perPage);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getProductsToSendByStatus(1, rows);
    }

    useEffect(() => {
        getProductsToSendByStatus();
    }, []);

    return (
        <Content headerTitle="Peças a Enviar">
            <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
            <ShipmentDetails isOpen={shipmentDetailsModal} setIsOpen={() => setShipmentDetailsModal(!shipmentDetailsModal)} shipmentId={productId} mode={shipmentDetailsMode} setUpdateShipment={() => getProductsToSendByStatus(page + 1, perPage)} setUpdateLogistic={setUpdateLogistic}></ShipmentDetails>
            <Card title='Cadastro de Produtos - Envio de Peças'>
                <PrimeTable
                    columns={columns}
                    rows={data}
                    serverPagination={true}
                    handlePagination={handlePaginationPrime}
                    handleRowPerPage={handleRowPerPagePrime}
                    count={paginationTotalRows}
                    page={page}
                    rowsPerPage={perPage}
                    loading={loading}
                ></PrimeTable>
            </Card>
        </Content>
    )
}