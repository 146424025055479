import React, { useEffect, useState } from 'react';
import Content from '../../../../common/Layout/Content';
import Card from '../../../../common/Layout/Card';
import { deleteProduct, duplicateProduct, getAllProducts } from '../../../../services/Product';
import { toast } from 'react-toastify';
import SubHeaderSpecific from '../../../../common/Layout/SubHeader/SubHeaderSpecific';
import CreateProduct from './CreateProduct';
import ProductDetails from './ProductDetails/ProductDetails';
import Swal from 'sweetalert2';
import BulkUpdate from './BulkUpdate';
import CreateShipment from './CreateShipment';
import If from '../../../../common/Layout/If';
import PrimeTable from '../../../../common/Layout/Table/PrimeTable';
import usePermissions from '../../../../hooks/usePermissions';
import CreateModel from './CreateModel';
import CreateManufacturer from './CreateManufacturer';

export default function ShowProducts() {
    const [loading, setLoading] = useState(true);
    const [productsData, setProductsData] = useState([]);
    const [paginationTotalRows, setPaginationTotalRows] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [selectedRows, setSelectedRows] = useState([]);

    const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
    const [isUpdateProductModalOpen, setIsUpdateProductModalOpen] = useState(false);
    const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false);
    const [isCreateModelOpen, setIsCreateModelOpen] = useState(false);
    const [isCreateShipmentModalOpen, setIsCreateShipmentModalOpen] = useState(false);
    const [isCreateManufacturerOpen, setIsCreateManufacturerOpen] = useState(false);
    const [productId, setProductId] = useState('');
    const [viewMode, setViewMode] = useState('view');
    const [updateLogistic, setUpdateLogistic] = useState(false);
    const { checkPermissionGroup } = usePermissions();
    const columns = [
        {
            id: 'action_produto',
            name: 'Ações',
            body: (row) => (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações</button>
                    <div className="dropdown-menu">
                        <button type="button" className="dropdown-item" onClick={() => openProductModal(row.id_produto, 'view')}>Ver Produto</button>
                        <If condition={checkPermissionGroup(["logistica_gestao", "logistica_operacao"])}>
                            <button type='button' className="dropdown-item" onClick={() => showDuplicateProductSwal(row.id_produto)}>Duplicar</button>
                            <button type='button' className="dropdown-item" onClick={() => openProductModal(row.id_produto, 'edit')}>Editar</button>
                            <If condition={['spare_tecnico', 'em_estoque'].includes(row.chave_opcao_status_produto)}>
                                <button type='button' className="dropdown-item" onClick={() => openCreateShipmentModal(row.id_produto)}>Criar Remessa</button>
                            </If>
                        </If>
                        <If condition={checkPermissionGroup(["logistica_gestao"])}>
                            <button type='button' className="dropdown-item" onClick={() => showDeleteProductSwal(row.id_produto)}>Deletar</button>
                        </If>
                    </div>
                </div>)
        },
        {
            id: 'status_produto',
            name: 'Status Produto',
        },
        {
            id: 'nome_produto',
            name: 'Nome Produto',
        },
        {
            id: 'serial_produto',
            name: 'Serial',

        },
        {
            id: 'mac_produto',
            name: 'MAC',

        },
        {
            id: 'imei_produto',
            name: 'IMEI',

        },
        {
            id: 'patrimonio_produto',
            name: 'Patrimônio',
        },
        {
            id: 'tipo_produto',
            name: 'Tipo de Produto'
        },
        {
            id: 'codigo_sap_produto',
            name: 'Codigo SAP',

        },
        {
            id: 'invoice_produto',
            name: 'Invoice'
        },
        {
            id: 'nfe_entrada_produto',
            name: 'NFe Entrada',

        },
        {
            id: 'data_nfe_entrada_produto',
            name: 'Data NFe Entrada',

        },
        {
            id: 'peso_equipamento_produto',
            name: 'Peso Equipamento',

        },
        {
            id: 'preco_equipamento_produto',
            name: 'Preço Equipamento',

        },
        {
            id: 'kit_spare_produto',
            name: 'Kit Spare',

        },
        {
            id: 'fabricante_produto',
            name: 'Fabricante',

        },
        {
            id: 'integradora_produto',
            name: 'Integradora',

        },
        {
            id: 'contrato_produto',
            name: 'Contrato',

        },
        {
            id: 'valorado_produto',
            name: 'Valorado',

        },
        {
            id: 'local_produto',
            name: 'Local',

        },
        {
            id: 'corredor_produto',
            name: 'Corredor',

        },
        {
            id: 'estante_produto',
            name: 'Estante',

        },
        {
            id: 'tecnico_produto',
            name: 'Técnico',

        },
        {
            id: 'tipo_tecnico_produto',
            name: 'Tipo do Técnico',

        },
        {
            id: 'email_produto',
            name: 'Email',
        }
    ];

    async function duplicateProductById(id) {

        const toastDuplicate = toast.loading("Duplicando dados, aguarde...");

        let dataReturn = await duplicateProduct(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastDuplicate, { render: 'Produto duplicado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getProducts();
            openProductModal(dataReturn?.data?.data?.new_product?.id, 'edit');
        } else {
            toast.update(toastDuplicate, { render: 'Ocorreu um erro ao duplicar o produto!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function showDuplicateProductSwal(id) {
        let data = await Swal.fire({
            title: 'Deseja mesmo duplicar este produto?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            cancelButtonColor: 'red',
            showConfirmButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: 'green'
        });

        if (data.isConfirmed) {
            duplicateProductById(id);
        }
    }

    async function showDeleteProductSwal(id) {
        let data = await Swal.fire({
            title: 'Deseja mesmo excluir este produto?',
            showCancelButton: true,
            cancelButtonText: 'Não',
            cancelButtonColor: 'red',
            showConfirmButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: 'green'
        });

        if (data.isConfirmed) {
            deleteProductById(id);
        }
    }

    async function deleteProductById(id) {
        const toastDuplicate = toast.loading("Excluindo dados, aguarde...");

        let dataReturn = await deleteProduct(id);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastDuplicate, { render: 'Produto excluido com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            getProducts();
        } else {
            toast.update(toastDuplicate, { render: 'Ocorreu um erro ao excluido o produto!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    async function getProducts(page = 1, perPage = 10) {
        setLoading(true);
        const toastProducts = toast.loading('Carregando dados, aguarde...');

        let dataReturn = await getAllProducts(page, perPage);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastProducts, { render: 'Dados carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let products = dataReturn?.data?.data?.data.map((item, idx) => {
                let tipoTecnico = "";

                if (item.technician?.id_representante !== null) {
                    tipoTecnico = item.technician?.id_representante === 1 ? 'CLT' : 'Parceiro';
                }

                return (
                    {
                        id_produto: item.id,
                        status_produto: item.status?.valor_opcao || '',
                        tipo_produto: item.nome_produto,
                        nome_produto: item.modelo || '',
                        serial_produto: item.serial,
                        mac_produto: item.mac || '',
                        imei_produto: item.imei || '',
                        patrimonio_produto: item.patrimonio || '',
                        codigo_sap_produto: item.codigo_sap || '',
                        nfe_entrada_produto: item.nfe_entrada || '',
                        data_nfe_entrada_produto: item.data_nfe_entrada || '',
                        peso_equipamento_produto: item.peso_equipamento || '',
                        preco_equipamento_produto: item.preco_equipamento || '',
                        kit_spare_produto: item.kit_spare || '',
                        fabricante_produto: item?.partnumber?.manufacturer?.nome_fabricante || '',
                        integradora_produto: item.integrator?.nome || '',
                        contrato_produto: item.contract?.contrato || '',
                        valorado_produto: item.valorado || '',
                        local_produto: item.local || '',
                        corredor_produto: item.hallway?.valor_opcao || '',
                        estante_produto: item.stand?.valor_opcao || '',
                        tecnico_produto: item.technician?.nome_tec || '',
                        tipo_tecnico_produto: tipoTecnico,
                        email_produto: item.technician?.email_tec || '',
                        chave_opcao_status_produto: item?.status?.chave_opcao || '',
                        invoice_produto: item?.invoice || ''
                    }
                );
            });
            setPaginationTotalRows(dataReturn?.data?.data?.total);
            setProductsData(products);
        } else {
            toast.update(toastProducts, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setProductsData([]);
        }
        setLoading(false);
    }

    function openProductModal(id, mode) {
        setProductId(id);
        setViewMode(mode);
        setIsUpdateProductModalOpen(true);
    }

    function openCreateShipmentModal(id) {
        setProductId(id);
        setIsCreateShipmentModalOpen(true);
    }

    const handlePaginationPrime = (newPage) => {
        setPage(newPage);
        getProducts(newPage + 1, perPage);
    }

    function handleRowPerPagePrime(rows) {
        setPerPage(rows);
        setPage(0);
        getProducts(1, rows);
    }

    useEffect(() => {
        getProducts();
    }, []);

    return (
        <Content headerTitle="Gerenciamento de Produtos">
            <SubHeaderSpecific subHeaderConfig='mam_logistica' setUpdateLogistic={setUpdateLogistic} updateLogistic={updateLogistic}></SubHeaderSpecific>
            <CreateProduct isOpen={isAddProductModalOpen} setModalOpen={() => setIsAddProductModalOpen(!isAddProductModalOpen)} setUpdateProducts={() => getProducts(page + 1, perPage)}></CreateProduct>
            <ProductDetails isOpen={isUpdateProductModalOpen} setModalOpen={() => setIsUpdateProductModalOpen(!isUpdateProductModalOpen)} productId={productId} viewMode={viewMode} setUpdateProducts={() => getProducts(page + 1, perPage)}></ProductDetails>
            <BulkUpdate isOpen={isBulkUpdateModalOpen} setModalOpen={() => setIsBulkUpdateModalOpen(!isBulkUpdateModalOpen)} setUpdateProducts={() => getProducts(page + 1, perPage)} handleClearRows={() => setSelectedRows([])} ids={[...selectedRows.map((item) => item.id_produto)]}></BulkUpdate>
            <CreateShipment isOpen={isCreateShipmentModalOpen} setIsOpen={() => setIsCreateShipmentModalOpen(!isCreateShipmentModalOpen)} productId={productId} setUpdateProducts={() => getProducts(page + 1, perPage)} setUpdateLogistic={setUpdateLogistic}></CreateShipment>
            <CreateModel isOpen={isCreateModelOpen} setIsOpen={() => setIsCreateModelOpen(!isCreateModelOpen)}></CreateModel>
            <CreateManufacturer isOpen={isCreateManufacturerOpen} setIsOpen={() => setIsCreateManufacturerOpen(!isCreateManufacturerOpen)}></CreateManufacturer>
            <Card title="Todos os Produtos">
                <div className='row'>
                    <div className='col-12 d-flex justify-content-start flex-wrap'>
                        <If condition={checkPermissionGroup(["logistica_gestao", "logistica_operacao"])}>
                            <div className="dropdown d-inline m-1">
                                <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'><i className='fas fa-plus'></i> Cadastros</button>
                                <div className="dropdown-menu">
                                    <If condition={checkPermissionGroup(["logistica_gestao"])}>
                                        <button type="button" className="dropdown-item" onClick={() => setIsCreateManufacturerOpen(!isCreateManufacturerOpen)}>Adicionar Fabricante</button>
                                        <button type="button" className="dropdown-item" onClick={() => setIsCreateModelOpen(!isCreateModelOpen)}>Adicionar Nome do Produto</button>
                                    </If>
                                    <button className='btn dropdown-item' onClick={() => setIsAddProductModalOpen(!isAddProductModalOpen)}>Adicionar Produto</button>
                                </div>
                            </div>
                            <If condition={selectedRows?.length > 0}>
                                <div className="dropdown d-inline m-1">
                                    <button className="btn btn-primary dropdown-toggle" type='button' data-toggle='dropdown' aria-expanded='false'>Ações em Massa</button>
                                    <div className="dropdown-menu">
                                        <button type='button' className="dropdown-item" onClick={() => setIsBulkUpdateModalOpen(!isBulkUpdateModalOpen)}>Editar Selecionados</button>
                                    </div>
                                </div>
                            </If>
                        </If>
                    </div>
                    <div className="col-12">
                        <PrimeTable
                            columns={columns}
                            rows={productsData}
                            serverPagination={true}
                            handlePagination={handlePaginationPrime}
                            handleRowPerPage={handleRowPerPagePrime}
                            count={paginationTotalRows}
                            page={page}
                            rowsPerPage={perPage}
                            loading={loading}
                            selectableRows={checkPermissionGroup(["logistica_gestao", "logistica_operacao"]) ? true : false}
                            selectedRows={checkPermissionGroup(["logistica_gestao", "logistica_operacao"]) ? selectedRows : null}
                            handleSelect={checkPermissionGroup(["logistica_gestao", "logistica_operacao"]) ? setSelectedRows : null}
                        ></PrimeTable>
                    </div>
                </div>
            </Card>
        </Content>
    )
}