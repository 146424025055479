import React, { useEffect, useState } from 'react';
import If from './If';

export default function Card({ hideCardHeader = false, collapseId = '', icon = '', title = '', children }) {

    const [collapse, setCollapse] = useState(collapseId ? true : false);

    const handleCollapse = () => {
        if(collapseId !== ''){
            setCollapse(!collapse)
        }
    }

    return (
        <div className="card">
            <If condition={!hideCardHeader}>
                <div className={`card-header`}
                    onClick={handleCollapse}
                    style={collapseId !== '' ? { cursor: 'pointer' } : undefined}>
                    <h5>{icon && (<i className={`fas ${icon}`}></i>)} {title}</h5>
                </div>
            </If>
            <If condition={!collapse}>
                <div className={`card-body`}>
                    {children}
                </div>
            </If>
        </div>
    );
}