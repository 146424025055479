import React, { useEffect, useState } from 'react';
import Content from '../../../common/Layout/Content';
import Card from '../../../common/Layout/Card';
import { Link, useParams } from 'react-router-dom';
import { changeContractStatus, getContractsByStatus } from '../../../services/Contracts';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import SubHeaderSpecific from '../../../common/Layout/SubHeader/SubHeaderSpecific';
import PrimeTable from '../../../common/Layout/Table/PrimeTable';

export default function ShowContracts() {

    const { status } = useParams();
    const [contractsData, setContracsData] = useState([]);
    const columns = [
        {
            id: 'action_contrato',
            name: 'Ver',
        },
        {
            id: 'contrato_contrato',
            name: 'Contrato',
        },
        {
            id: 'integradora_contrato',
            name: 'Integradora',
        },
        {
            id: 'tipo_contrato',
            name: 'Tipo do Contrato',
        },
        {
            id: 'email_contrato',
            name: 'Email',
        },
        {
            id: 'escopo_contrato',
            name: 'Escopo',
        },
        {
            id: 'responsavel_equipamento_contrato',
            name: 'Responsável Equipamento',
        },
        {
            id: 'status_contrato',
            name: 'Status',
        },
        {
            id: 'action_activate_deactivate_contrato',
            name: 'Ação',
        },
    ];

    async function getContracts(status = 1) {

        const toastContractsData = toast.loading("Carregando dados, aguarde...");

        let dataReturn = await getContractsByStatus(status);

        if (dataReturn?.data?.response === 'success' && dataReturn?.data?.data.length > 0) {
            toast.update(toastContractsData, { render: 'Dados Carregados com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let data = dataReturn?.data?.data.map((item, idx) => {
                let status_contrato = item?.ativo === 1 ? 'Ativo' : 'Inativo';
                return ({
                    id_contrato: item?.id || '',
                    contrato_contrato: item?.contrato || '',
                    integradora_contrato: item?.integrator?.nome || '',
                    tipo_contrato: item?.tipo || '',
                    escopo_contrato: item?.escopo_atendimento || '',
                    email_contrato: item?.email || '',
                    responsavel_equipamento_contrato: item?.responsavel_equipamento || '',
                    status_contrato: status_contrato,
                    action_contrato: (<Link to={'/cadastros/contratos/detalhes/' + item?.id}><i className='fas fa-eye'></i></Link>),
                    action_activate_deactivate_contrato: (<button type="button" className='btn btn-primary' onClick={() => { showChangeContractStatusSwal(item?.id, status_contrato) }}>{status_contrato === 'Ativo' ? 'Inativar' : 'Ativar'}</button>)
                })
            })
            setContracsData(data);
        } else {
            toast.update(toastContractsData, { render: 'Ocorreu um erro ao carregar os dados!', type: "warning", isLoading: false, autoClose: 1500 });
            setContracsData([]);
        }
    }

    async function showChangeContractStatusSwal(id, status) {
        let response = await Swal.fire({
            titleText: 'Deseja realmente alterar o status deste contrato?',
            showConfirmButton: true,
            confirmButtonColor: 'green',
            confirmButtonText: 'Sim',
            showCancelButton: true,
            cancelButtonColor: 'red',
            cancelButtonText: 'Não'
        })

        if (response.isConfirmed) {
            changeContractByStatus(id, status);
        }
    }

    async function changeContractByStatus(id, status) {

        const toastChangeStatus = toast.loading("Alterando status, aguarde...");

        let changeStatusData = status === 'Ativo' ? 0 : 1;

        let dataReturn = await changeContractStatus(id, changeStatusData);

        if (dataReturn?.data?.response === 'success') {
            toast.update(toastChangeStatus, { render: 'Status alterado com sucesso!', type: "success", isLoading: false, autoClose: 1500 });
            let statusData = status === 'Ativo' ? 1 : 0;
            getContracts(statusData);
        } else {
            toast.update(toastChangeStatus, { render: 'Ocorreu um erro ao alterar o status!', type: "warning", isLoading: false, autoClose: 1500 });
        }
    }

    useEffect(() => {
        let statusData = 1;

        if (status === 'inativo') {
            statusData = 0
        }

        getContracts(statusData);
    }, [status])

    return (
        <Content headerTitle="Contratos">
            <SubHeaderSpecific subHeaderConfig='mam_cadastros'></SubHeaderSpecific>
            <Card hideCardHeader={true}>
                <div className='row'>
                    <div className='col-12'>
                        <Link to="/cadastros/contratos/cadastrar" role='button' className='btn btn-primary m-1'>Cadastrar Contrato</Link>
                        <Link to="/cadastros/contratos/dados" role='button' className='btn btn-success m-1'>Contratos Ativos</Link>
                        <Link to="/cadastros/contratos/dados/inativo" role='button' className='btn btn-warning m-1'>Contratos Inativos</Link>
                    </div>
                </div>
                <PrimeTable
                    rows={contractsData}
                    columns={columns}
                ></PrimeTable>
            </Card>
        </Content>
    )
}